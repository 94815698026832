const model = {}

module.exports = model

model.saveTempMsg = async(trx, data) => {
    let idm = await trx('groupline_msg_temp').insert(data)
    return idm;
}

model.getTempMessage = async(trx, data) => {
    let result = await trx(`groupline_msg_temp`).where(data)
    return result
}
model.delTempMessage = async(trx, data) => {
        let result = await trx(`groupline_msg_temp`).del().where(data)
        return result
    }
    // masterLine
model.getGroupLine = async(trx, data) => {
    let row = await trx(`master_group_line`).where(data)
    return row
}
model.saveGroupLine = async(trx, data) => {
    let saved = await trx(`master_group_line`).insert(data)
    return saved;
}

model.updateGroupLine = async(trx, data) => {
    return await trx(`master_group_line`).where({ id: data.id })
        .update({ group_name: data.group_name, member: data.member })
}
model.groupUpdateActive = async(trx, data) => {
    return await trx('master_group_line').update({ is_active: data.is_active })
        .where({ group_id: data.group_id })
}
model.updateBotJoinGroups = async(trx, data) => {
    return await trx('master_group_line').update({ group_name: data.group_name, is_active: 'Y', member: data.group_member })
        .where({ group_id: data.group_id })
}
model.saveAnnounceLine = async(trx, data) => {
    let savedAnnounce = await trx(`announce_line`).insert(data)
    return savedAnnounce
}
model.getAnnounceLine = async(trx, data) => {
    let row = await trx(`announce_line`).max(data)
    return row
}
model.getAnnounceLineMaxData = async(trx, data) => {
        let row = await trx.raw(`
    select id,orderMessage,Send_at 
    from announce_line 
    where id = (select max(id) from announce_line)
    `)
        return row[0]
    }
    // 4/6/2021
model.getAnnounce_Line_list = async(trx, data) => {
    let row = await trx(`announce_line`).where(data)
    return row
}

model.delAnnounce_Line = async(trx, data) => {
    let deleted = await trx('announce_line').where(data).del()
    return deleted
}
model.getAnnounce_Line_Group = async(trx, data) => {
    let row = await trx('announce_line_group').where(data)
    return row
}
model.delAnnounce_Line_Group = async(trx, data) => {
    let deleted = await trx('announce_line_group').where(data).del()
    return deleted
}
model.updateMemberGroupInAnnounce = async(trx, data) => {
    let updated = await trx('announce_line_group').update({ member_count: data.member_count }).where({ group_id: data.group_id })
    return updated
}
model.getAnnounce_Line_approve = async(trx, data) => {
    let row = await trx('announce_line_approve').where(data)
    return row
}
model.delAnnounce_Line_approve = async(trx, data) => {
    let deleted = await trx('announce_line_approve').where(data).del()
    return deleted
}
model.getAnnounce_Line_msg = async(trx, data) => {
    let row = await trx(`announce_line_msg`).where(data)
    return row
}
model.delAnnounce_Line_msg = async(trx, data) => {
        let deleted = await trx(`announce_line_msg`).where(data).del()
        return deleted
    }
model.getAnnounceLineAll = async(trx) => {
    let row = await trx.raw(`
    SELECT al.id
    ,al.orderMessage
    ,al.Send_at 
    ,al.group_count
    ,al.user_id
    ,al.group_id
--     ,sl.line_name
--     ,ifnull(d.name,'-') department_name
-- 		,(select s.line_name from staff_line s where s.user_id = al.user_id AND s.group_id = al.group_id)
    ,SUM(ag.member_count) member_count, ifnull(a.member_read,0) member_read
    FROM announce_line al
    INNER JOIN announce_line_group ag ON ag.announce_line_id = al.id
-- 		inner join master_group_line mgl on mgl.id = al.group_id
--     INNER JOIN staff_line sl ON sl.user_id = al.user_id 
-- 		AND sl.group_id = al.group_id
--     LEFT  JOIN department d ON d.id = sl.department_id
    left JOIN (
    SELECT COUNT(id) member_read ,announce_line_id
    FROM announce_line_approve
    WHERE STATUS = 'Read' 
    GROUP BY announce_line_id ) a ON a.announce_line_id = ag.announce_line_id
    GROUP BY al.id ORDER BY al.id DESC
    `)
    return row
}

model.saveAnnounceLineGroup = async(trx, data) => {
    let saved = await trx(`announce_line_group`).insert(data)
    return saved
}


model.getAnnounceLineMsg = async(trx, data) => {
    let row = await trx('announce_line_msg').select(`announce_line_id`, `message`, `type`).orderBy('id')
    return row
}
model.saveAnnounceLineMsg = async(trx, data) => {
    let row = await trx(`announce_line_msg`).insert(data)
    return row
}

model.getApprove = async(trx, data) => {
    let rows = await trx(`announce_line_approve`).where(trx.raw(`announce_line_id = ${data.announce_line_id} AND group_id = ${data.group_id} AND user_id = ${data.user_id}`))
    return rows
}
model.saveApprove = async(trx, data) => {
    let save = await trx('announce_line_approve').insert(data)
    return save
}
model.updateApprove = async(trx, data) => {
    console.log(data.id)
    let updated = await trx(`announce_line_approve`).where(trx.raw(`announce_line_id = ${data.id} AND group_id = ${data.group_id} AND user_id = ${data.user_id}`)).update(`status`, `Read`)
    return updated
}
model.updateApproveAllGroups = async(trx, data) => {
    let updated = await trx('announce_line_approve')
        .update('status', 'Read')
        .where({announce_line_id: data.announce_line_id})
        .whereIn('user_id',data.user_id)
    return updated
}
model.getStaffApprove = async(trx, data) => {
    let row = await trx('announce_line_approve').where(data)
    return row
}

model.updateStaffLine = async(trx, data) => {
    let updated = await trx(`staff_line`).update({ line_name: data.line_name, picture: data.picture })
        .where(trx.raw(`group_id = '${data.group_id}' AND user_id = '${data.user_id}'`))
    return updated
}
model.updateStaffLineDepartment = async(trx, data) => {
    let updated = await trx('staff_line').update({ staff_id: data.staff_id, department_id: data.department_id })
        .where({ user_id: data.user_id })
    return updated
}
model.getStaffLine = async(trx, data) => {
    let rows = await trx(`staff_line`).where(data)
    return rows
}
model.deleteMasterLineStaff = async (trx, data) => {
    let deleted = await trx('master_staff_line').del().where(data)
    return deleted
}
model.SaveStaffLine = async(trx, data) => {
    let saved = await trx(`staff_line`).insert(data)
    return saved
}
model.delStaffLine = async(trx, data) => {
    let deleted = await trx(`staff_line`).delete().where(data)
    return deleted
}
model.getUserRead = async(trx, data) => {

    let getUserRead = await trx.raw(`
    SELECT 
    lg.announce_line_id
    ,lg.group_id
    ,mg.group_name
    ,member_count  membercount  
     ,IFNULL (a.member_read,0) AS member_read
    FROM announce_line_group AS lg
    INNER JOIN master_group_line AS mg ON mg.id = lg.group_id
    left JOIN (
     SELECT COUNT(id) member_read,announce_line_id,group_id,status 
     FROM announce_line_approve
     WHERE STATUS = 'Read'
     GROUP BY announce_line_id,group_id ) 
     AS a ON a.announce_line_id  = lg.announce_line_id 
     AND a.group_id = lg.group_id
     WHERE lg.announce_line_id = ${data} AND mg.is_admin = 'N' AND mg.is_active = 'Y'
     GROUP BY lg.group_id  , mg.group_name;
    `)
    return getUserRead
}

model.getListStaffApprove = async(trx, data) => {
    let rows = await trx.raw(`
    SELECT 
    mgl.group_name
    ,sl.line_name
    ,sl.picture
    ,IFNULL(DATE_FORMAT(aa.system_date,'%d-%m-%Y %H:%i'),0) as system_date
    ,aa.status
    FROM announce_line a
    INNER JOIN announce_line_group ag on a.id = ag.announce_line_id
    INNER JOIN staff_line sl on ag.group_id = sl.group_id
    inner join master_group_line mgl ON mgl.id = ag.group_id
    left JOIN announce_line_approve aa on (sl.id = aa.user_id and a.id = aa.announce_line_id)
    WHERE a.id = '${data.id_announce}' and ag.group_id = '${data.group_id}'
    AND sl.in_group is NOT NULL AND sl.in_group in  ('Y')
    ORDER BY a.id,ag.group_id,sl.group_id ;
    `)
    return rows
}
model.getAllListStaffApprove = async(trx, data) => {
    let rows = await trx.raw(`
    SELECT 
    mgl.group_name
    ,sl.line_name
    ,sl.picture
    ,IFNULL(DATE_FORMAT(aa.system_date,'%d-%m-%Y %H:%i'),0) as system_date
    ,aa.status
    FROM announce_line a
    INNER JOIN announce_line_group ag on a.id = ag.announce_line_id
    INNER JOIN staff_line sl on ag.group_id = sl.group_id
    inner join master_group_line mgl ON mgl.id = ag.group_id
    inner JOIN announce_line_approve aa on (sl.id = aa.user_id and a.id = aa.announce_line_id)
    WHERE a.id = '${data.id_announce}'
    AND sl.in_group is NOT NULL AND sl.in_group in  ('Y') 
    ORDER BY a.id,ag.group_id,sl.group_id ;
    `)
    return rows
}
model.genCode = async(trx, data) => {
    let row = await trx.raw(
        `SELECT   
         ifnull( MAX(LPAD(CONVERT(SUBSTRING(orderMessage,10),UNSIGNED INTEGER) + 1,3,0 )),0) as code
         FROM announce_line `
    )
    return row
}

model.getowner = async(trx, data) => {
    let row = await trx.raw(
        `SELECT 
        s.*
        FROM staff_line AS s
        INNER JOIN master_group_line AS ml ON s.group_id = ml.id
        WHERE  s.user_id = '${data.user_id}'
        AND s.group_id = '${data.group_id}'
        AND ml.is_admin = 'Y' AND ml.is_active = 'Y'
        `
    )
    return row
}

model.saveAnnounceLineGroups = async(trx, data) => {
    let saved = await trx.raw(`
        INSERT INTO announce_line_group(announce_line_id,group_id,member_count)
        SELECT ${data.id},id,member
        FROM master_group_line
        WHERE master_group_line.is_admin = 'N'
        AND  master_group_line.is_active = 'Y' 
        AND  master_group_line.id in (${data.groups});
    `)

    return saved
}

model.getLineRoom = async(trx, data) => {
    let row = await trx('line_room').where(data)
    return row
}
model.saveLineRoom = async(trx, data) => {
    let save = await trx('line_room').insert(data)
    return save
}
model.updateLineRoom = async(trx, data) => {
    let updated = await trx('line_room').update('user_profile', data.user_profile).where('room_id', data.room_id).andWhere('user_id', data.user_id)
    return updated
}
model.delLineRoom = async(trx, data) => {
    let row = await trx('line_room').where(data).delete()
    return row
}
model.getLineRoomMsg = async(trx, data) => {
    let row = await trx('line_room_msg').where(data)
    return row
}
model.saveLineRoomMsg = async(trx, data) => {
    let save = await trx('line_room_msg').insert(data)
    return save
}

model.saveLogEvent = async(trx, data) => {
    let save = await trx('line_log_msg').insert(data)
    return save
}
model.listReportDaily = async(trx, data) => {
        let row = await trx.raw(`
    SELECT date_format(rm.system_date,'%d-%m-%Y') msg_date, ifnull(replace(JSON_EXTRACT(user_profile, "$.displayName"),'"',''),'User left room') user_send,COUNT(distinct rm.line_message, rm.room_id) cn_send
    FROM line_room_msg rm
    INNER JOIN line_room lm ON lm.room_id = rm.room_id AND lm.user_id = rm.user_id
    left JOIN master_staff_line msl ON lm .user_id = msl.user_id
    left JOIN master_team_line mtl  ON mtl.id = msl.team_id
    WHERE rm.line_message REGEXP '[0][1-9,-?]{9}' > 0
    ${data.team}
    AND rm.system_date BETWEEN '${data.data1}' AND '${data.data2}'
    GROUP BY date_format(rm.system_date,'%d-%m-%Y'), rm.user_id
    ORDER BY rm.system_date, user_send
    `)
        return row
    }
    //  edit 6/7/2021
model.listReportDailyDetail = async(trx, data) => {
    let row = await trx.raw(`
    SELECT DATE_FORMAT(rm.system_date,'%d-%m-%Y') msg_date
    , replace(JSON_EXTRACT(l.user_profile, "$.displayName"),'"','') user_write
    , line_message 
    , IFNULL((SELECT GROUP_CONCAT(replace(JSON_EXTRACT(l2.user_profile, "$.displayName"),'"','') 
    ORDER BY replace(JSON_EXTRACT(l2.user_profile, "$.displayName"),'"','') SEPARATOR ', ') user_read
    FROM line_room l2 
    WHERE l2.room_id = rm.room_id AND l2.user_id <> rm.user_id),'User left room') user_read 
    FROM line_room_msg rm 
    INNER JOIN line_room l ON rm.room_id = l.room_id AND rm.user_id = l.user_id
    left JOIN master_staff_line msl ON l.user_id = msl.user_id 
    left JOIN master_team_line mtl  ON mtl.id = msl.team_id
    WHERE rm.line_message REGEXP '[0][1-9,-?]{9}' > 0
    ${data.team}
    AND rm.system_date BETWEEN '${data.data1}' AND '${data.data2}'
    ORDER BY rm.system_date
    `)
    return row
}
model.getReportUserRead = async(trx, data) => {
    let row = await trx.raw(`
    SELECT date_format(rm.system_date,'%d-%m-%Y') msg_date,ifnull(replace(JSON_EXTRACT(user_profile, "$.displayName"),'"',''),'User left room') user_read,COUNT(distinct rm.line_message, rm.room_id) cn_read
    FROM line_room_msg rm
    INNER JOIN line_room l ON l.room_id = rm.room_id AND l.user_id <> rm.user_id
    left JOIN master_staff_line msl ON l.user_id = msl.user_id 
    left JOIN master_team_line mtl  ON mtl.id = msl.team_id
    WHERE rm.line_message REGEXP '[0][1-9,-?]{9}' > 0
    ${data.team}
    AND rm.system_date BETWEEN '${data.data1}' AND '${data.data2}'
    GROUP BY date_format(rm.system_date,'%d-%m-%Y'), replace(JSON_EXTRACT(l.user_profile, "$.displayName"),'"','')
    ORDER BY rm.system_date
    `)
    return row
}
model.getAnnounceGroup = async(trx, data) => {
    let row = await trx.raw(`
    SELECT
    al.id
    ,al.orderMessage
    ,al.Send_at 
    ,alg.group_id
    FROM announce_line  al
    INNER JOIN announce_line_group alg ON alg.announce_line_id = al.id
    WHERE alg.group_id = ${data.group_id}
    order by al.Send_at DESC`)
    return row
}

model.genCodeForm = async(trx, data) => {
    let code = trx.raw(`
    SELECT ifnull( MAX(LPAD(CONVERT(SUBSTRING(code_form,6),UNSIGNED INTEGER) + 1,3,0 )),0) code
    FROM master_line_form
    `)
    return code
}
model.getMasterLineForm = async(trx, data) => {
    let row = await trx('master_line_form').where(data)
    return row
}
model.delMasterLineForm = async(trx, data) => {
    let deleted = await trx('master_line_form').del().where(data)
    return deleted
}
model.updateActiveLineForm = async(trx, data) => {
    let update = await trx('master_line_form')
        .update({ isActive: data.isActive })
        .where({ id: data.id })
    return update
}
model.getFormPermission = async(trx, data) => {
    let rows = await trx.raw(`
    SELECT 
    mlf.id
    ,mlf.name_form
    FROM master_line_form mlf
    INNER JOIN  permission_line_form plf ON mlf.id = plf.master_line_form_id
    INNER JOIN  master_group_line mgl ON mgl.id = plf.master_group_line_id
    WHERE mgl.id = '${data.id}'  AND mlf.isActive = 'Y'   AND plf.is_active = 'Y'
    `)
    return rows[0]
}
model.updateMasterLineForm = async(trx, data) => {
    let row = await trx('master_line_form').update({
        name_form: data.name_form,
        description: data.description,
        isActive: data.isActive,
        re_use: data.re_use,
        approve_department_id: data.approve_department_id
    }).where({ id: data.id })
    return row
}
model.getListQuestionForm = async(trx, data) => {
    let row = await trx('master_line_form m')
        .select('m.id', 'm.name_form', 'l.id', 'l.question', 'l.type_ans', 'l.require_ans')
        .innerJoin('line_form_question l', 'm.id', 'l.master_line_form_id')
        .where(data)
    return row
}
model.getListQuestion = async(trx, data) => {
    let row = await trx('line_form_question').where(data)
    return row
}
model.updateQuestion = async(trx, data) => {
    let updated = await trx('line_form_question').update({
        question: data.question,
        type_ans: data.type_ans,
        require_ans: data.require_ans,
        list_type: data.list_type,
        department_id: data.department_id
    }).where({ id: data.id })
    return updated
}
model.saveLineQuestion = async(trx, data) => {
    let saved = await trx('line_form_question').insert(data)
    return saved
}
model.delLineQuestion = async(trx, data) => {
    let deleted = await trx('line_form_question').del()
        .where({ id: data })
    return deleted
}
model.saveMasterLineForm = async(trx, data) => {
    let saved = await trx('master_line_form').insert(data)
    return saved
}

model.getListLineAnswer = async(trx, data) => {
    let rows = await trx('line_form_answer').where(data)
    return rows
}
model.saveLineAnswer = async(trx, data) => {
    let saved = await trx('line_form_answer').insert(data)
    return saved
}
model.updateLineAnswer = async(trx, data) => {
    let updated = await trx('line_form_answer')
        .update({ answer: data.answer, group_id: data.group_id, staff_line_id: data.staff_line_id })
        .where({ master_line_form_id: data.id, question_id: data.question_id })
    return updated
}
model.delLineAnswer = async(trx, data) => {
    let deleted = await trx('line_form_answer').del().where(data)
    return deleted
}
model.getApproveLineAnswer = async(trx, data) => {
    let rows = await trx('line_form_approve').where(data)
    return rows
}
model.delApproveLineAnswer = async(trx, data) => {
    let deleted = await trx('line_form_approve').del().where(data)
    return deleted
}
model.saveApproveLineAnswer = async(trx, data) => {
    let saved = await trx('line_form_approve').insert(data)
    return saved
}
model.updateStatusApproveForm = async(trx, data) => {
    let updated = await trx('line_form_approve')
        .update({ status: data.status, approve_by: data.approve_by })
        .where(trx.raw(`master_line_form_id ='${data.master_line_form_id}' and group_id ='${data.group_id}' and staff_line_id = ${data.staff_line_id}`))
    return updated
}
model.updateApproveForm = async(trx, data) => {
    let updated = await trx('line_form_approve')
        .update({ group_id: data.group_id, staff_line_id: data.staff_line_id, status: data.status, approve_by: data.approve_by })
        .where({ master_line_form_id: data.form_id })
    return updated
}
model.getWaitApproveLineForm = async(trx, data) => {
    let rows = await trx.raw(`
     SELECT
     m.id form_id
    ,mg.id line_group_id
    ,sl.id staff_id
    ,f_app.system_date
    ,m.name_form
    ,m.description
    ,mg.group_name
    ,sl.line_name
    ,m.re_use
    ,IFNULL(d.name,'ทุกแผนก') department_name
    ,case 
    	when  f_app.status = 'Wait' then 'รออนุมัติ'
    	when  f_app.status = 'Approve' then 'อนุมัติแล้ว'
    	when  f_app.status = 'NotApprove' then 'ไม่อนุมัติ'
        when  f_app.status = 'WaitQ' then 'รอคำตอบ'
    	else
    	    'Unknown status'
    	END flag
    FROM line_form_approve f_app 
    INNER JOIN master_line_form m ON m.id = f_app.master_line_form_id
    LEFT JOIN department d ON d.id = m.approve_department_id
    INNER JOIN master_group_line mg ON mg.id = f_app.group_id
    INNER  JOIN staff_line sl ON sl.id = f_app.staff_line_id  AND sl.group_id = f_app.group_id
    ${data}
    ORDER BY m.id 
    `)
    return rows[0]
}
model.getListAllQuestionAndAnswer = async(trx, data) => {
    let rows = await trx.raw(`
    SELECT
    fq.question
    ,fq.type_ans
    ,fa.answer
    ,ifnull(d.name,'ทุกแผนก') d_name
    ,mlf.re_use
    FROM line_form_question  fq
    INNER JOIN master_line_form mlf ON mlf.id = fq.master_line_form_id
    INNER JOIN line_form_answer fa ON fq.id = fa.question_id
    INNER JOIN line_form_approve fapp ON fapp.group_id = fa.group_id  AND fapp.staff_line_id = fa.staff_line_id AND fapp.master_line_form_id = fa.master_line_form_id
    left JOIN	department d ON fq.department_id = d.id
    WHERE  fa.master_line_form_id = '${data.master_line_form_id}' 
     AND fa.group_id = '${data.group_id}' 
     AND fa.staff_line_id = '${data.staff_line_id}' 
    `)
    return rows[0]
}

model.saveStaffIdApprove = async(trx, data) => {
    let saved = await trx.raw(`
        INSERT INTO announce_line_approve (announce_line_id, group_id, user_id)
        SELECT 
        alg.announce_line_id
        ,sl.group_id
        ,sl.id
        FROM announce_line_group alg
        INNER JOIN announce_line al ON al.id = alg.announce_line_id
        INNER JOIN staff_line sl ON sl.group_id = alg.group_id
        WHERE sl.id = '${data.staff_id}' AND sl.group_id = '${data.group_id}'
    `)
    return saved
}
model.savePermissionForm = async(trx, data) => {
    let saved = await trx('permission_line_form').insert(data)
    return saved
}
model.getPermissionForm = async(trx, data) => {
    let row = await trx('permission_line_form').where(data)
    return row
}
model.updatePermissionForm = async(trx, data) => {
    let updated = await trx('permission_line_form')
        .update({ is_active: data.is_active })
        .where({ id: data.id })
    return updated
}
model.getListPermissionForm = async(trx, data) => {
    let row = await trx.raw(`
    SELECT 
    mgl.id
    ,mgl.group_name
    ,plf.is_active
    FROM master_group_line mgl 
    inner join permission_line_form plf ON mgl.id = plf.master_group_line_id 
    WHERE plf.master_line_form_id = '${data.master_line_form_id}' 
    AND mgl.is_admin = 'N'  AND mgl.is_active = 'Y'
    `)
    return row[0]
}
model.getDepartmentForm = async(trx, data) => {
    let rows = await trx('department')
        .select('id', 'name')
        .where(data)
    return rows
}
model.getPermissionQuestion = async(trx, data) => {
    let rows = await trx.raw(`
    SELECT
    lfq.id q_id
    ,ifnull(mdl.id,0)  id
   ,ifnull(mdl.name,'ทุกแผนก') name
   ,lfq.question
   ,lfq.type_ans
   ,lfq.require_ans
   ,lfa.answer
   ,lfq.list_type
    FROM line_form_question lfq
    left JOIN department mdl on mdl.id = lfq.department_id
    INNER JOIN master_line_form mlf ON mlf.id = lfq.master_line_form_id
    left JOIN line_form_answer lfa ON lfa.question_id = lfq.id
    WHERE mlf.id ='${data.id}'
    `)
    return rows[0]
}
model.checkDeparment = async(trx, data) => {
    let row = await trx.raw(`
    SELECT 
    sl.line_name
    ,ifnull(dm.id,0) dm_id
    FROM staff_line sl 
    left JOIN department dm ON sl.department_id = dm.id
    WHERE sl.user_id = '${data.user_id}' AND sl.group_id = '${data.group_id}'
    `)
    return row[0]
}
model.getStaff = async(trx, data) => {
    let row = await trx('staff').where(data)
    return row
}
model.getStaffDepartment = async(trx, data) => {
    let row = await trx('staff')
        .select('staff.id as staff_id', 'department.id ', 'department.name')
        .innerJoin('department', 'department.id', 'staff.department_id')
        .where(data)
    return row
}
model.getListDepartments = async(trx, data) => {
    let rows = await trx('department').where(data)
    return rows
}
model.getMasterLineStaff = async(trx, data) => {
    let row = await trx('master_staff_line')
        .where(data)
    return row
}
model.saveMasterLineStaff = async(trx, data) => {
    let save = await trx('master_staff_line').insert(data)
    return save
}
model.updateMasterLineStaffNormal = async(trx, where,data) => {
    return  await trx('master_staff_line').update(data).where(where)
}
model.updateMasterLineStaff = async(trx, data) => {
    let update = await trx('master_staff_line')
        .update({
            is_active: data.is_active,
            is_admin: data.is_admin,
            scs_department_id: data.scs_department_id,
            role_id: data.role_id,
            team_id: data.team_id,
            update_by: data.update_by
        })
        .where({ id: data.master_id })
    return update
}
model.getMasterRoleScreen = async(trx, data) => {
    let rows = await trx('master_role_line_screen').where(data)
    return rows
}
model.saveMasterRoleScreen = async(trx, data) => {
    let saved = await trx('master_role_line_screen').insert(data)
    return saved
}
model.updateMasterRoleScreen = async(trx, data) => {
    let updated = await trx('master_role_line_screen')
        .update({
            role_name: data.role_name,
            role_description: data.role_description,
            role_status: data.role_status,
        })
        .where({ id: data.id })
    return updated
}
model.deleteMasterRoleScreen = async(trx, data) => {
    let deleted = await trx('master_role_line_screen').del().where(data)
    return deleted
}
model.getRolePermissionMenu = async(trx, data) => {
    let rows = await trx('role_line_screen').where(data)
    return rows
}
model.saveRolePermissionMenu = async(trx, data) => {
    let save = await trx('role_line_screen').insert(data)
    return save
}
model.deleteRolePermissionMenu = async(trx, data) => {
    let deleted = await trx('role_line_screen').del().where(data)
    return deleted
}
model.getLineScreen = async(trx, data) => {
    let rows = await trx('line_screen').where(data)
    return rows
}
model.getMenu = async(trx, data) => {
    let rows = await trx('line_screen', 'role_line_screen.role_id')
        .innerJoin('role_line_screen', 'role_line_screen.screen_id', 'line_screen.id')
        .where(data)
    return rows
}
model.getlistMasterStaffLine = async(trx, data) => {
    let rows = await trx.raw(`
        SELECT
        msl.id master_id
        ,s.display_name
        ,ifnull(msl.mail_line,'-') mail_line
        ,msl.system_date
        ,msl.update_date
        ,msl.update_by
        ,s.user staff_id
        ,msl.staff_line_name
        ,msl.is_active
        ,mrls.id role_id
        ,mrls.role_name
        ,ifnull(mtl.id,0) team_id
        ,IFNULL(mtl.team_name,'ยังไม่ได้กำหนดทีม Line') team_name
        ,d.name department_name
        ,d.id  department_id
        FROM master_staff_line msl
        INNER JOIN master_role_line_screen mrls ON mrls.id = msl.role_id
        left JOIN master_team_line mtl ON mtl.id = msl.team_id
        INNER JOIN staff s ON s.id = msl.scs_staff_id
        left JOIN department d ON d.id = s.department_id
        
    `)
    return rows[0]
}
model.getTeamLine = async(trx, data) => {
    let rows = await trx('master_team_line').where(data)
    return rows
}