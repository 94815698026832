import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { FlatList,StyleSheet, Text, View, ActivityIndicator,TouchableHighlight,ScrollView, SafeAreaView,TextInput,Dimensions} from 'react-native';
import {Input,ListItem,SearchBar,Badge, Tooltip,Image, Button, Card} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import Modal from "modal-enhanced-react-native-web"
import  config from '../../../Api/config'
import { Animated } from 'react-native'
import styless from '../../lib/style'
const l = require('../../../Api/lib/log')
const axios = require('axios')
const {width,height} = Dimensions.get('window')
const processString = require('react-process-string')

export default class ReportGroupDetail extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                 user_id : props.route.params.user_id,
                 group_id : props.route.params.group_id,
                 list_data : props.route.params.data,
                 modalVisible :null ,
                 line : props.route.params.line,
                 img : '',
                 load: false
                
            }
        }
        handleAlert = (msg) =>{
            alert(msg)
        }
        Appprove = (id)=>{
            this.setState({load: true})
             
            let user_id =  this.state.user_id
            let group_id = this.state.group_id
            axios.post( config.uri.api + 'api/report/approve',{params: {id: id, group_id: group_id, user_id: user_id,line : this.state.line}})
            .then((res)=>{
                this.setState({load: false})
                if (!res.data || !res.data.status) return  alert("เกิดข้อผิดพลาดในการบันทึกข้อมูล...")
                if (res.data.status === 'error') return  alert("รับทราบประกาศไม่สำเร็จ...")
                alert(res.data.data || "รับทราบประกาศเรียบร้อย ✔️✔️ ")
                return this.props.navigation.pop()
            })
            .catch((err)=>{
                this.setState({load: false})
                console.log(err.message)
                alert("เกิดข้อผิดพลาดในการรับทราบประกาศ โปรดลองอีกครั้ง...")
            })
            // setTimeout(() => {               
            //     this.setState({load: false})
            //     alert(" ระบบได้เพิ่มปรับสถานะตามคิวให้แล้ว [ ใช้เวลาไม่เกิน 5-6 นาที ] จากนั้นให้ทำการตรวจสอบสถานะอีกรอบค่ะ ✔️✔️...")
            //     this.props.navigation.pop() 
            // }, 3000);
        }
        responseMessage = (response) => {
            this.handleAlert(response.data)
            return this.props.navigation.pop()
        }
        Dialog = (picture) =>{
           this.setState({
               modalVisible: true,
               img : picture
           })
        }
        itemImg = ({item}) =>{
        
            return (
                // <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center',borderWidth:3}}>
                         <Image
                            onPress={()=>this.Dialog(item.message)} 
                            source={{ uri:  item.message}}
                            style={{ resizeMode:'center',width: 300,height:300,margin:10 }}
                            placeholder={<ActivityIndicator/>}
                            />
                //   </View>
               
               )
        }
        checkUrl = (body) => {
            let configRegexp = [{
                regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
                fn: (key, result) => <span key={key}>
                                         <a target="_blank" href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}>{result[1]}://{result[2]}.{result[3]}{result[4]}</a>{result[5]}
                                     </span>
            }, {
                regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
                fn: (key, result) => <span key={key}>
                                         <a target="_blank" href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
                                     </span>
            }]
            let new_data = processString(configRegexp)(body)
            return (
                <View>
                    <Text style= {{textAlign:width <= 500 ? 'center' : 'left'}}>{new_data}</Text>
                </View>
            )
            
        }
        listData = () =>{     
             
            return (
                this.state.list_data.map((item,index)=>{
                    let status = item.approve.map(row=>{
                        return row.status
                    })
                    let topic = item.text
                    let body  = item.body
                    let img = item.img
                    return (
                        <SafeAreaView key={item.id}  styles={width <= 500 ? styles.report_group_detail_Mobile : styles.report_group_detail_web}>
                            <Card  >
                                <Card.Title style={{fontWeight:200,margin:10,padding:10,justifyContent: width <= 500 ? 'center' : 'flex-start',alignItems: width <= 500 ? 'center' : 'left'}}>
                                    <Text >
                                        กลุ่ม {width <= 500 ? item.group_name.substr(0,20) : item.group_name}
                                    </Text>
                                </Card.Title>
                            </Card>
                            <Card key={item.id}>
                                <Card.Title style={{fontWeight:200,margin:10,padding:10,textAlign:width <= 500 ? 'center' : 'left'}}>{topic.length === 0 ? '' : topic}</Card.Title>
                                <Card.Divider/>
                                <View style={{margin:20,justifyContent:'center'}}>
                                    {
                                        this.checkUrl(body)
                                    }
                                </View>
                                <View  style={{margin:20,justifyContent:'center',alignItems: width <= 500 ? 'center' : 'left'}}>
                                    <Text>จำนวนรูปทั้งหมด {img.length || '-'} รูป</Text>
                                </View>
                                <View  style={{flexDirection:'row',justifyContent:'center',alignItems: width <= 500 ? 'center' : 'left'}}>
                                    {/* {img.length > 1 ? <Text>เลื่อนซ้าย-ขวา เพื่อดูรูปถัดไป</Text>  : ''} */}
                                            <Animated.FlatList 
                                            data={img}
                                            renderItem ={this.itemImg}
                                            horizontal 
                                            pagingEnabled  
                                            // contentContainerStyle={{justifyContent:'center',alignItems:'center'}}                                    
                                            showsHorizontalScrollIndicator={true}
                                            keyExtractor={(item,index)=>{index.toString()}}
                                        />
                                </View>
                                { item.img && item.img ?  <Card.Divider/> : '' } 
                                <View style={{justifyContent:'center',alignItems:'center'}}>
                                <Button  
                                    icon={ <Icon name="check-circle" size={15} color={status[0] === 'Wait' ? '#33CC66' : 'gray'}/>}
                                    type="outline"
                                    style={{display:'flex',justifyContent: 'center'}}
                                    buttonStyle={{borderColor:'#33CC66',width:130}}
                                    titleStyle={{color:'#33CC66',padding:10}}
                                    disabled={status[0] === 'Read' || this.state.load === true}   
                                    title={this.state.load === true ? 'รอสักครู่...' : 'รับทราบ'} 
                                    onPress={()=> this.Appprove(item.id)}>
                                </Button>
                                
                                </View>
                            </Card>
                            
                       
                    </SafeAreaView>
                    )
                })
            )
        //   return (
        //     <FlatList
        //     data={this.state.list_data}   
        //     renderItem={this.ItemView}
        //     keyExtractor={(index)=> index.toString()}
        //     />
        //   )
          
        }
        render() {
          
            return (
                <SafeAreaView>
                     
                    <Modal  isVisible={this.state.modalVisible } >
                    <SafeAreaView style={styles.modalContent}>                     
                        <Image
                                onPress = {()=> window.open(this.state.img,'_blank') }
                                style={{width:300, height:300}}
                                source={this.state.img}
                                resizeMode='center'
                        />
                    <SafeAreaView style={{padding:10,display:'flex',flexDirection:'column'}}></SafeAreaView>
                    <Button  style={{width:100,marginTop:10}}  title="ตกลง"  onPress={() => this.setState({modalVisible : false})} ></Button>  
                    </SafeAreaView>
                    </Modal>
                    {this.listData()} 
                </SafeAreaView>
           
        )
        }
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      top: '10%',
      width: '100%',
      backgroundColor: 'transparent',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'visible',
      height: '100%'
    },
    data:{
        margin:20,
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 'auto'
    },
    report_group_detail_web : {
        margin:20,
        backgroundColor: 'transparent',
        alignItems: 'center',
        alignContent:'center',
        justifyContent: 'center',
        height: 'auto'
    },
    report_group_detail_Mobile : {
        margin:20,
        backgroundColor: 'transparent',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: 'auto'
    },
    flex_row : {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin:10,
      fontSize:50,
     
    },
    flex_column : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding:10,
        alignItems: 'center',
        marginLeft: 5,
        marginRight: 5,
    },
    layout_img : {
        borderWidth:0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center',
        margin:20,
    },
    modalContent: {
        backgroundColor: "transparent",
        padding: 22,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        borderColor: "rgba(0, 0, 0, 0.1)"
    },
});