
import React,{useEffect,useState} from 'react';
import { StyleSheet, Text, View, ActivityIndicator,Dimensions,SafeAreaView } from 'react-native';
import {Input,Button, Badge, Image} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import  config from '../../../Api/config'
import styles from '../../lib/style';
import {Picker} from '@react-native-picker/picker';
import Home from './home'
const axios = require('axios')
const {width, height} = Dimensions.get('window')


function CreateStaff (props){
    const {navigation,line} = props
    const [Line,getLine] = useState(line)
    const [loading,setLoading] = useState(true)
    const [department,setDepartment] = useState({staff_id: 0,department_id: 0, department_name:'',is_admin: 'N'})
    const [status,setStatus] = useState('')
    const [Message,setMessage] = useState('')
    const [selectedDepartment,setSelectedDepartment] = useState({})
    const [staffName,setStaffName] = useState('')
    const [password,setPassword] = useState('')
    const handleStaff = (user) =>{
        setStaffName(user)
    }
    const handlePassword = (password) =>{
        setPassword(password)
    }
    const checkDepartmentlist = () =>{
        
        axios.post(config.uri.api +'api/login/checkDepartment',{params:{user: staffName,pass: password}})
        .then(res=>{
            let name = res.data.data.length <= 0 ? 'ไม่พบแผนก' :  res.data.data[0].name
            let id   = res.data.data.length <= 0 ? 0 : res.data.data[0].id
            let staff_id = res.data.data.length <= 0 ? 0 : res.data.data[0].staff_id
            let is_admin = res.data.data.length <= 0 ? 'N' :  res.data.data[0].is_admin
            let data = {staff_id: staff_id, department_id: id,department_name: name,is_admin: is_admin}
            setDepartment(data)
        }).catch(err=>{console.log('err checkDepartmentlist' + err.message)});
    }
   
    const Confirm  = () => {
        let data = [...Line]
        let listDepartments = {department: department}
        data.push(listDepartments)
        axios.post(config.uri.api + 'api/login/Confirm',{params:{data: data}}).then(res=>{
           return handleResponse(res.data)
        }).catch(err=>{
            alert(err.message)
        })
    }
    const NavigatorNext = (data) =>{
        navigation.navigate('home',{screen: 'home',params:{data: data}})
   }
    const handleResponse = (response) => {
        if (response.status === 'error') {return alert(response.data)}
        if (response.status === 'success') {return NavigatorNext(response.data)}
        return null
    }
    if (status === 'error') {
        return (
            <SafeAreaView style={styles.container}>
                 <Text>เกิดข้อผิดพลาด</Text>
                <Text>{Message.toString()}</Text>
            </SafeAreaView>
        )
    }
    return (
        <SafeAreaView style={flex.view_data}>
            <View style={flex.flex_column}> 
                    <View style={styles.topic}>
                        <Text style={{color:'#fff'}}>
                            ระบบลงทะเบียนครั้งแรก
                        </Text>
                    </View>
                    <View style={styles.main}>
                    {
                        Line && Line.map((row,index)=>{
                            return (
                            <View key={index}>
                                <Input
                                    label={'ชื่อไลน์'}
                                    leftIcon ={
                                        <Icon 
                                            name="user"
                                            size={18}
                                            color={'#66CC99'}
                                        />
                                    } 
                                    disabled={true}
                                    value={row.line.displayName}
                                />
                                <Input
                                 label={'อีเมลไลน์'}
                                leftIcon ={
                                        <Icon 
                                            name="envelope"
                                            size={18}
                                            color={'#66CC99'}
                                        />
                                    } 
                                    disabled={true}
                                    value={row.line.email}
                                />
                            </View> 
                            )
                        })
                    }
                    </View>
                    <View style={styles.main}>
                        <Text style={{margin:10,fontSize:15,color:'red'}}>โปรดระบุ ผู้ใช้งาน รหัสผ่าน เพื่อระบุแผนกที่คุณสังกัด.**</Text>
                        <Input
                        style={styles.input_des}
                        label={'รหัสผู้ใช้งาน'} 
                        placeholder={'ระบุรหัสผู้ใช้งาน'}
                        value={staffName}
                        onChangeText={(value)=>{handleStaff(value)}}
                        />
                        <Input
                        style={styles.input_des}
                        label={'รหัสผ่าน'}
                        secureTextEntry={true} 
                        placeholder={'ระบุรหัสผ่าน'}
                        value={password}
                        onChangeText={(value)=>{handlePassword(value)}}
                        />
                        <Input
                        style={styles.input_des}
                        disabled ={true} 
                        label ={'แผนกของคุณ'}
                        value ={department.department_name}
                        />
                        <Button
                          style={{width: width <= 500 ? 'auto' : '10%',marginLeft:10}}
                          disabled={staffName.length <=0 || password.length <= 0} 
                          title={'ตรวจสอบแผนก'}
                          onPress={()=>checkDepartmentlist()}
                        />
                    </View>       
            </View>
            <View>
                <View style={styles.flex_btn}>
                      <Button
                       icon={
                        <Icon
                            style={styles.icon}
                            name="check-circle"
                            color='#fff'
                            size={15}
                        />}
                       buttonStyle={{backgroundColor:'green'}}
                       disabled={department.department_id === 0} 
                        style={styles.btn_action}
                        title={"ยืนยัน"}
                        onPress={()=>{Confirm()}}
                      />
                </View>
            </View>
            <View>
               
            </View>
            
         </SafeAreaView>
    )
} 

const flex = StyleSheet.create({
    flex_column : {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        marginLeft: 5,
        marginRight: 5
    },
    view_data : {
        backgroundColor:'transperent',
        margin:10,
        padding:10,
        borderWidth: 0,
        borderRadius:15
    },
})

export default CreateStaff