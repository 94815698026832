import axios from 'axios';
import React from 'react';
import { View,ScrollView,SafeAreaView,ActivityIndicator,Text,FlatList,Dimensions } from 'react-native'
import { Avatar,Card,Button,ListItem,Badge,SearchBar,Image,CheckBox,Input,SpeedDial } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../lib/style'
import {Picker} from '@react-native-picker/picker';
import liff from '@line/liff'
import _liff from '../../lib/config-line-liff'
import  config from '../../../Api/config'
import Modal from "modal-enhanced-react-native-web"
import model from '../../../Api/model/handledb'
import * as DocumentPicker from 'expo-document-picker'
const EnableFile = ['pdf','jpeg','jpg','png','gif']
const  {width,height} = Dimensions.get('window')
export default class saveForm extends React.Component{
    constructor(props){   
        super(props)
        this.state={
            listForm : [{name: '', remark: '', base64: '', type: '', flag: ''}],
            listFormBefor : [],
            line : {
                name : '',
                userId : '',
                Picture : '', 
            },
            checkDeparment : false,
            group_id : isNaN(props.route.params.group_id) === true ? '' : props.route.params.group_id ,
            id : isNaN(props.route.params.id) === true ? '' : props.route.params.id,
            loading : false,
            checkbox  : '',
            head : [],
            answer : [],
            ansOriginal : [],
            status : '',
            msg: '',
            department: [],
            modalVisible : false,
            staff : '',
            flag_del : [],
            pass: '',
            action_button : false
        }
        this.msg = React.createRef()
        
    }
   
    getLineLiff = async () =>{
        await  liff.init({liffId:_liff.line_liff.save_form}).catch((e)=>{console.log(e)})
            if (this.state.id === '' || this.state.group_id === '' ){
                this.setState({loading: true})
                console.log(`error No parameter`)
                return // return window.location.assign("https://developers.line.biz")
            }

            if (liff.isLoggedIn()) {
                let getProfile = await liff.getProfile()
                 this.setState({
                    line : {
                        name : getProfile.displayName,
                        userId : getProfile.userId,
                        Picture : getProfile.pictureUrl, 
                    }
                 })
                 console.log('=== authen Line success ===')
              }else{
                console.log('redirect_uri')
                liff.login({redirectUri: `${config.uri.web}save_form/liff1/${this.state.group_id}/${this.state.id}`})
              }
    }
    checkDepartment = async () =>{
        console.log('checkDepartment')
        axios.post(config.uri.api + 'api/form/checkDeparment',{params:{group_id: this.state.group_id, id : this.state.id,line: this.state.line}})
        .then(res=>{
            this.setState({loading: true,department: res.data.data})
            this.getDepartment()
        }).catch(e=>{
            console.log(e.message)
            return
        })
    }
    getDepartment = async () =>{
        let d = this.state.department
        if (d.length > 0 && d[0].dm_id === 0){
            this.setState({checkDeparment: true,modalVisible:true})
        }else{
            let data = await this.fetchData()
            
        }
    }
    fetchData = async ()=>{
        axios.post(  config.uri.api +'api/form/getformsave',{params:{group_id: this.state.group_id, id: this.state.id,line: this.state.line}})
        .then(res=>{    
            
             this.setState({loading:true, status: res.data.status, listForm: res.data.data, listFormBefor: JSON.parse(JSON.stringify(res.data.data)), head: res.data.head})
             this.setAnswer()
        }).catch(e=>{
            console.log(e.message)
            this.setState({loading:true,listForm:e.message})
        })
    }
    syncData = async () =>{
        let liff        = await this.getLineLiff()
        let checkDPT    = await this.checkDepartment()
    }

    uploadFile = async (id,fileIndex) =>{
        console.log('id : ',id,fileIndex)
        let upload = await DocumentPicker.getDocumentAsync({})
        let listForm = this.state.listForm
        let answer = this.state.answer
        if (EnableFile.includes(upload.file.name.split(/[.]/)[1].toLowerCase()) !== true){
            return alert(`แนบเอกสารเฉพาะนามสกุลไฟล์ [ ${EnableFile.toString()} ] เท่านั้น`)
        }
        console.log('listForm  ',listForm)
        listForm = listForm.map((list,list_index)=>{
            if (list.q_id === id){
                // console.log("list_file : ",list.file)
                list.file.map((file,f_index)=>{
                    // console.log("f_index : ",f_index)
                    if (fileIndex === f_index){
                        file.name = upload.file.name.split(/[.]/)[0]
                        file.base64 = upload.uri,
                        file.type = upload.file.name.split(/[.]/)[1]
                        file.flag = 'new'
                    }
                    return file
                })
            }
            
            return list
        })
        console.log('answer  : ',answer)
        console.log('listForm change ',listForm)
        this.setState({listForm: listForm})
    }
    componentDidMount(){
        this.syncData()
    }
   
    mainHandleAnswer = async () =>{
        return this.state.department.length > 0 && this.state.department[0].dm_id <= 0 ? this.setState({modalVisible: true}) : this.checkFormReuse(this.state.head[0].re_use)
    //    await this.checkDepartmentAnswer(this.state.department[0].dm_id)
    //    await this.checkFormReuse(this.state.head[0].re_use)
    }
    checkFormReuse = async (form_reuse) => {
        return form_reuse === 'true' ?  this.checkValidCheckBox(form_reuse) : this.checkAnswerPermission(form_reuse)
    }
    checkValidCheckBox = async (form_reuse) => {
        let radioFalse = []
        let radioTrue = []
        this.state.answer && this.state.answer.map(a => {
            if (a.type_ans === 'radio') {
                a.list_type.map(item => {
                    if (!item.status) {
                        radioFalse.push({id: item.id,title: item.title,status: item.status})
                    }else{
                        radioTrue.push({id: item.id,title: item.title,status: item.status})
                    }
                })
            }
        })
        return radioFalse > radioTrue ?  alert('พบช่องติ๊กเลือกยังไม่ระบุ')  : this.checkAnswerRequired(form_reuse) 
        // return (checkRadio.length > 0 ? alert('โปรดติ๊กคำตอบให้ครบทุกข้อ') : this.checkAnswerRequired(form_reuse) )
    }
    checkAnswerRequired = async (form_reuse) => {
        let checkrequire = this.state.answer.filter(row=>{return (row.type_ans !== 'uploadFile' && row.require_ans === 'true')  && row.answer === ''})
        return (checkrequire.length > 0 ? alert('โปรดตอบคำถาม [จำเป็น] ให้ครบทุกข้อ.') : this.saveAnswer(form_reuse) )
    }
     
    checkDepartmentAnswer = async (departmentId) =>{
        return departmentId === 0 ? this.setState({modalVisible: true}) : ''
    }
    checkAnswerPermission = async (form_reuse) =>{
        let radioFalse = []
        let radioTrue = []
        let checkRadio =  this.state.answer.filter(row=>{return row.type_ans === 'radio' && row.flagowner === true})
        let answer = this.state.answer.filter(row => {return row.type_ans !== 'radio' && row.flagowner === true && (!row.answer)})
        console.log('answer : ',answer)
        console.log('checkRadio : ',checkRadio.length)
        if (checkRadio.length > 0) {
            checkRadio.map((check) => {
                check.answer.map(answer => {
                    if (!answer.status) {
                        radioFalse.push({id: answer.question_id, title: answer.title, status: answer.status})
                    }else{
                        radioTrue.push({id: answer.question_id, title: answer.title, status: answer.status})
                    }
                })
            })
        }
        if (answer.length > 0) return alert('พบคำถามที่คุณยังไม่ได้ระบุคำตอบ')
        if (radioFalse.length > radioTrue.length) return alert('พบช่องติ๊กเลือกของคุณยังไม่ระบุ')
        
        return this.saveAnswer(form_reuse)
        // let checkanswer = this.state.answer.filter(row=>{
        //     return row.permission === true && ( row.answer === null || row.answer === '')  
        // })
        // return checkanswer.length > 0 ? alert('โปรดตอบคำถามตามสิทธิ์ให้ครบทุกข้อ.') : this.saveAnswer(form_reuse)
        // return this.saveAnswer(form_reuse)
    }
    saveAnswer = async (form_type) =>{
        console.log('answer:',this.state.answer);
        let ansList = JSON.parse(JSON.stringify(this.state.answer))
        let formList = JSON.parse(JSON.stringify(this.state.listForm))
        let newAns = []
        let i = 0
        // console.log("ansList", ansList);
        // console.log("listForm", formList);
        for(let row of ansList) {
            if(row.type_ans == 'text' && row.answer != '' && row.answer != this.state.ansOriginal[i].answer){
                console.log('row', row);
                newAns.push(row)
            } else if (row.type_ans == 'radio' && row.list_type && (row.list_type[0].status || row.list_type[1].status) && JSON.stringify(row.list_type) != JSON.stringify(this.state.ansOriginal[i].answer)){
                console.log('row', row);
                newAns.push(row)
            } else if (row.type_ans == 'uploadFile' && row.file){
                // let fileList = formList.find(obj => obj.q_id == row.question_id)
                // for(let newfile of fileList){
                for(let newfile of row.file){
                    if(newfile.base64 != ''){
                        console.log('row', row);
                        newAns.push(row)
                    }
                }
            }
            i++
        }
        // console.log('listForm:', this.state.listForm);
        // console.log('form_id:', this.state.id);
        // console.log('group_id:', this.state.group_id);
        // console.log('line:', this.state.line);
        console.log('newAns:', newAns);
        axios.post( config.uri.api + 'api/form/saveAnswer',
        {
         params:{
             answer:newAns,
            //  listForm: this.state.listForm,
             form_id : this.state.id,
             group_id : this.state.group_id,
             line: this.state.line
         }})
     .then(res=>{
        if (res.data.status === "error") return alert(res.data.data)
        return alert(res.data.data)
     }).catch(e=>{
         console.log(e.message)
     })
    }
    
    setAnswer =  async ()=>{
        let answer = this.state.answer
        let listForm = this.state.listForm
        if (listForm.length > 0 && Array.isArray(listForm)) {
            listForm && listForm.map(row => {
                if (row.type_ans === 'radio') {
                    answer.push({question_id: row.q_id,answer: (!row.answer ? (row.list_type || [{id:1,title:'มี',status:false},{id:2, title: 'ไม่มี',state: false}]) : row.answer), type_ans: row.type_ans,require_ans: row.require_ans,permission: row.permission,list_type: row.list_type})
                }else if (row.type_ans === 'uploadFile') {
                    answer.push({question_id: row.q_id, answer: (!row.answer ? (row.file || '') : row.answer),type_ans: row.type_ans,require_ans: row.require_ans,permission: row.permission,file: row.file, list_type: (row.list_type || []) })
                }else{
                    answer.push({question_id: row.q_id, answer: (!row.answer ? '' : row.answer),type_ans: row.type_ans,require_ans: row.require_ans,permission: row.permission,list_type: (row.list_type || [])})
                }
            })
        }
        this.setState({ansOriginal: JSON.parse(JSON.stringify(answer))})
    }
    confirmlistModalUpload = async (id) =>{
        let listForm = this.state.listForm
        // console.log("listForm : ",listForm, id)
        let formUpload =  listForm.filter((form,f_index) => {return form.q_id === id})
        let numlist = await this.checkFileupload(formUpload)
        if (numlist.length > 0){
            return alert('โปรดแนบเอกสารให้ครบทุกรายการ')
        }
        listForm = listForm.map((row,i)=>{
            if (row.q_id === id){
                row.showModal = false
            }
            return row
        })
        console.log("this.state.answer",this.state.answer);
        console.log("listForm",listForm);
        this.setState({listForm: listForm, listFormBefor: listForm})
    }
    closelistModalUpload = async (id) =>{
        let listForm = JSON.parse(JSON.stringify(this.state.listFormBefor))
        // // console.log("listForm : ",listForm, id)
        // let formUpload =  listForm.filter((form,f_index) => {return form.q_id === id})
        // let numlist = await this.checkFileupload(formUpload)
        // if (numlist.length > 0){
        //     return alert('โปรดแนบเอกสารให้ครบทุกรายการ')
        // }
        listForm = listForm.map((row,i)=>{
            if (row.q_id === id){
                row.showModal = false
            }
            return row
        })
        console.log('closelistModalUpload', listForm);
        this.setState({listForm: listForm})
    }
    checkFileupload = async (formUpload) =>{
        // console.log("formUpload : ",formUpload)
        return  formUpload[0] && formUpload[0].file.filter(file=>{return file.name.length <= 0 || file.name === ''}) || []
    }
    showlistModalUpload = (idQuestion)=>{
        let listForm = this.state.listForm
        listForm = listForm.map((row,index)=>{
            if (row.q_id === idQuestion){
                row.showModal = true
            }
            return row
        })
        this.setState({listForm: listForm})
    }
    handleRemark = (text,fileIndex,idQuestion) =>{
        let listForm = this.state.listForm
        let answer = this.state.answer
        listForm = listForm.map((list,l_index)=>{
            if (list.id === idQuestion){
                list.file.map((row,index)=>{
                    if (fileIndex === index){
                        row.remark = text
                    }
                    return row
                })
            }
            return list
        })
        this.setState({listForm: listForm})
    }
    addRowupload = (idQuestion)=>{
        let uploadFile = {name: '',base64:'',type:'',flag:'',remark:''}
        let listForm = this.state.listForm
        listForm = listForm.map((list,l_index)=>{
            if (list.q_id === idQuestion) {
                list.file.push(uploadFile)
            }
            return list
        })
        let answer = this.state.answer
        this.setState({listForm: listForm,answer: answer})
        console.log('listForm: ',listForm);
    }
    deleteRowupload = (id,fileindex) =>{
      
        let row = this.state.listForm.filter((list,index)=>{return list.q_id === id})
        // console.log("row : ",row)
        if ( row[0] && row[0].file.length <= 1 ){
            return alert('รายการแนบเอกสารต้องมีอย่างน้อย 1 รายการ')
        }
        row = row.map((row)=>{
            row.file.map((file,index)=>{
               if (fileindex === index) {
                    row.file.splice(index,1)
               }
               return file
            })
            return row
        })
        let newlistForm = this.state.listForm.map((list,i)=>{
            row.map((row,ii)=>{
               if (list.id === row.id){
                   list.file = row.file
               }
                return row
            })
            return list
        })
        let answer = this.state.answer
        this.setState({listForm: newlistForm,answer: answer})

    }
    showAnswer =  (option,list_type,answer,permission,file,showModal,idQuestion) =>{
       
       if (option === 'text'){
                return (
                    <View>
                        {permission === false &&
                            <Text style={styles.ansText}>{answer}</Text>
                        }
                        {permission === true &&
                            <Input disabled={permission === false} ref={this.msg} style={styles.inputhead} placeholder={answer} onChange={(e)=>this.handleAns(e,idQuestion)} />
                        }
                    </View>
                )
       }else if(option  === 'radio'){
                return (
                    <View style={{margin:'5px',flexDirection: 'row'}}>
                                {
                                    list_type && list_type.map((row,rIndex) => {
                                        return (
                                            <CheckBox 
                                                key ={rIndex}
                                                disabled={permission === false}  
                                                ref={this.msg} title={row.title} 
                                                onPress={(e)=> this.handleCheckBox(e,idQuestion,row.id,rIndex)} 
                                                checked={row.status } 
                                            />
                                        )
                                    })
                                } 
                            </View>
                    // <View style={styles.row}>
                    //     <CheckBox disabled={permission === false}  ref={this.msg} title='มี' onPress={(e)=> this.handleCheckBox('มี',i,e)} checked={radio1 === true } />
                    //     <CheckBox disabled={permission === false}  ref={this.msg}  title='ไม่มี' onPress={(e)=> this.handleCheckBox('ไม่มี',i,e)} checked={radio2 === true } />
                    // </View>
                )
       }else if (option === 'uploadFile'){
              return (
                <View style={styles.row}>
                    <View>
                        <Button
                        icon={
                            <Icon
                                style={styles.icon} 
                                name={'upload'}
                                size={20}
                                color={'#fff'}
                            />
                        }
                        disabled={permission === false}  ref={this.msg} 
                        title={'อัปโหลดเอกสาร'}
                        onPress={()=>{this.showlistModalUpload(idQuestion)}}
                        />
                        {
                            file && file.map((file,index)=>{
                                console.log('answer:', answer);
                                if(answer?.length > 0 || file.flag == 'new'){

                                    return (                               
                                      <View >
                                        <Text 
                                        style={{color:'white',padding:5,display: permission === false ? 'none' : 'block',margin:5,borderRadius:5,backgroundColor:file.flag !== 'new' ? 'purple' : 'green'}}
                                        onPress={()=>{
                                            if(answer?.length > 0 && file.flag != 'new') {
                                                console.log('JSON.parse:', JSON.parse(answer)?[index]:'');
                                                let objAns = JSON.parse(answer)[index]
                                                window.open(`${config.uri.api}api/getfile?uri=${objAns.uri}`)
                                            }
                                        }}
                                        >
                                        {/* {`${'('+(index+1)+')'} ${width <= 500 ? file.name.substr(0,10) +  '.' + file.type : file.name + '.' + file.type}`} */}
                                        {`${''+(index+1)+' )'} ${file.name}.${file.type}`}
                                        </Text>
                                      </View>
                                    )
                                } else {
                                    return(<div></div>)
                                }
                            })
                        }
                        <Modal isVisible={showModal} style={styles.modalUpload}>
                        <View style={styles.flex_btn}>
                                        <Icon 
                                            name={'window-close'}
                                            size={30}
                                            color='red'
                                            onPress = {() =>this.closelistModalUpload(idQuestion)}
                                        />
                        </View>
                        <View style={styles.row}>
                        <Text style={{justifyContent:'center',alignItems:'center',alignContent:'center'}}><Icon  style={styles.icon} name='plus-circle' size={30} color={'green'} onPress={()=>{this.addRowupload(idQuestion)}}  />เพิ่มรายการ</Text>                    
                        </View>
                        <ScrollView style={{marginBottom:5, marginTop:5}}>
                        <View>
                        {
                            file && file.map((file,index)=>{
                                let fileName = file.name.length <= 0 ? '' : `${file.name}.${file.type}`
                                return (
                                        <View style={{textAlign:'left',margin:10,justifyContent: 'flex-start',width:'auto', backgroundColor:'#e8e8eb', borderRadius:10}}>
                                        
                                        <Text style={{margin:10, fontSize:18}}>
                                            รายการแนบเอกสาร #{index+1} [ {fileName} 
                                            {file.flag && file.flag ? <Badge containerStyle={{marginLeft:10}} status='success' value={file.flag}  /> : '' }
                                            {fileName.length <= 0 ? '' : <Icon style={styles.icon} name='trash' color='red' size={18} onPress={()=>{this.handleDeleteFile(index)}} />} ]
                                            <Text style={{justifyContent:'center',marginLeft:10}}><Icon  style={styles.icon} name='minus-circle' color={'red'} size={18} onPress={()=>{this.deleteRowupload(idQuestion,index)}}  />ลบรายการนี้</Text>
                                         </Text>
                                        <Input 
                                            style={{margin:10,width:'auto'}}
                                            label={`#${index+1} หมายเหตุ `}
                                            value={file.remark}
                                            onChangeText={(value)=>{this.handleRemark(value,index,idQuestion)}}
                                        />
                                        {(file.name == '' || file.flag == 'new') && 
                                            <Button
                                                style={{margin:10,width:width <= 500 ? 'auto' : '15%'}}
                                                title={`#${index+1} แนบเอกสาร`}
                                                icon={
                                                    <Icon
                                                        style={styles.icon} 
                                                        name={'upload'}
                                                        size={20}
                                                        color={'#fff'}
                                                    />
                                                }
                                                onPress={()=>this.uploadFile(idQuestion,index)}
                                            />
                                        }
                                        {(file.name != '' && file.flag != 'new') && 
                                            <Button
                                                style={{margin:10,width:width <= 500 ? 'auto' : '15%'}}
                                                title={fileName}
                                                icon={
                                                    <Icon
                                                        style={styles.icon} 
                                                        name={'file'}
                                                        size={20}
                                                        color={'#fff'}
                                                    />
                                                }
                                                onPress={()=>{
                                                    if(answer?.length > 0){
                                                        let objAns = JSON.parse(answer)[index]
                                                        window.open(`${config.uri.api}api/getfile?uri=${objAns.uri}`)
                                                    }
                                                }}
                                            />
                                        }
                                        </View>
                                     
                                )   
                            }) 
                        }  
                        </View>
                         </ScrollView>
                        <View style={styles.flex_btn}>
                            <Button
                                buttonStyle={{backgroundColor:'green'}}
                                
                                title={'ตกลง'}
                                onPress={()=>this.confirmlistModalUpload(idQuestion)}
                            />
                         </View> 
                         
                        </Modal>  
                        
                    </View>                            
                </View>
              )            
       }    
    }
    handleDeleteFile = (indexFile) =>{
        
        let listForm = this.state.listForm
        console.log("indexFile : ",indexFile)
        console.log("listForm : ",listForm)
        listForm = listForm.map((list)=>{
            list.file &&  list.file.map((file,index)=>{
                if (indexFile === index){
                    file.name = ''
                    file.base64 = ''
                    file.type = ''
                    file.flag = ''
                    file.remark = ''
                }
                return file
            })
            return list
        })
        this.setState({listForm: listForm})
        
    }
    handleAns = (e,idQuestion) =>{
        let answer = this.state.answer
        console.log(answer);
        answer =  answer.map((row,index)=>{
          if (row.question_id === idQuestion)
          row.answer = e.nativeEvent.text.trim()
          return row
      })
      this.setState({ answer: answer })
    }
    handleCheckBox = (e,idQuestion,idRadio,indexRadio)=>{
        console.log('idQuestion: ',idQuestion)
       let listForm = this.state.listForm
       listForm = listForm.map((row,index)=>{
            if (row.q_id === idQuestion && row.type_ans === 'radio') {
                if (indexRadio === 0) {
                    row.list_type[0].status = true
                    row.list_type[1].status = false
                }else{
                    row.list_type[0].status = false
                    row.list_type[1].status = true
                } 
            } 
           return row
       })
       let answer = JSON.parse(JSON.stringify(this.state.answer))
       console.log('answer', answer);
       answer = answer.map((a,index_a)=>{
            console.log('a', a);
            if (a.question_id === idQuestion && a.type_ans === 'radio') {   
                    if (indexRadio === 0) {
                        a.list_type[0].status  = true
                        a.list_type[1].status  = false
                        a.answer[0].status     = true
                        a.answer[1].status     = false
                    }else{
                        a.list_type[0].status  = false
                        a.list_type[1].status  = true
                        a.answer[0].status     = false
                        a.answer[1].status     = true
                    } 
            }
            return a
       })
       console.log('list Form : ',listForm,answer)
       this.setState({answer: answer})
    }
    headForm = ()=>{
     return (
        this.state.head && this.state.head.map((row,i)=>{
            return (
                <View key={i} style={styles.header}>
                    <Text style={styles.textHeader}>{row.name_form}</Text>
                    <Text  style={styles.textDesc}>{row.description}</Text>
                </View>
            )
        })
     )
        
    }
    messagePermission = (Departmet_name,permission) => {
        let message = ''
        let statusForm = (this.state.head[0].reuse === 'true' ? true : false)
        if (statusForm) return message = (permission === true ? '' : `สำหรับแผนก${Departmet_name}`)
        return message = (permission === true ? `มีสิทธิ์ตอบคำถามข้อนี้ (แผนก${Departmet_name})` : `สำหรับแผนก${Departmet_name}`)
    }
    listForm = ()=>{
        console.log('this.state.listForm', this.state.listForm);
      return (
       this.state.listForm &&  this.state.listForm.map((item,i)=>{
            return (
                <SafeAreaView style={styles.main} key={item.q_id} >
                    <View>
                        <Text style={{display:this.state.head[0].re_use === 'true' ? 'none' : 'block',color:item.permission === true ? 'green' : 'red',fontSize:15,margin:10}}>{this.messagePermission(item.name,item.permission)}</Text>
                        
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.Question}>คำถาม  : {item.question} </Text>
                        <View>
                        <Badge containerStyle={{alignItems: 'center',display:item.require_ans === 'true' ? 'block' : 'none'}} value={item.require_ans === 'true' ? 'จำเป็น' : null} status={item.require_ans === 'true' ? 'error' : 'error'}/>
                        </View>
                    </View>
                    {
                       this.showAnswer(item.type_ans,item.list_type,item.answer,item.permission,item.file,item.showModal,item.q_id)
                    }
                </SafeAreaView>
             )
        })
      ) 
        
    }
    confirmDeparment = () =>{
        
        axios.post( config.uri.api + 'api/form/updateDepartment',{params:{user: this.state.staff,pass: this.state.pass,line: this.state.line,group_id: this.state.group_id}})
        .then(res=>{
             if(res.data.status === 'error'){
                this.setState({msg: res.data.data})
             }else{
                this.setState({msg: res.data.data,loading:false,modalVisible: false,checkDeparment:false})
                this.fetchData()
             }
        }).catch(err=>{
            console.log(err.message)
            return err
        })
    }
    render (){
        if (this.state.loading === false){
            return (
                <SafeAreaView style={styles.container}>
                <Text>กำลังโหลดฟอร์ม</Text>
                <ActivityIndicator size={'large'} color={'#33FF66'} />
                </SafeAreaView>
            )
          
        }else if (this.state.checkDeparment === true) {
            return (
                <SafeAreaView>
                    <Modal   isVisible={this.state.modalVisible } >
                        <View style={styles.modalContent}>
                            <Text style={styles.textHeader}>ไม่พบแผนกที่คุณสังกัด ??</Text>
                            <Text style={styles.textDesc}>โปรดกรอกไอดี staff ของคุณ.</Text>
                            <Text style={{color:'red'}}>{this.state.msg}</Text>     
                            <Input
                            style={{paddingLeft:10,width:'auto'}}
                            onChangeText = {(value) => this.setState({staff: value})}
                            placeholder='STAFF ID'
                            value={this.state.staff}
                            />
                             <Input
                             secureTextEntry={true}
                            style={{paddingLeft:10,width:'auto'}}
                            onChangeText = {(value) => this.setState({pass: value})}
                            placeholder='password'
                            value={this.state.pass}
                            />          
                            <View style={styles.flex_btn}>
                            <Button disabled={this.state.staff.length <= 0 || this.state.pass.length <= 0} style={{width:100,margin:10}} buttonStyle={{backgroundColor: 'blue'}}  title={this.state.action_button === false ? 'ตกลง' : 'กำลังส่งคำตอบ...'}  onPress={() => this.confirmDeparment()} ></Button> 
                            </View> 
                        </View>
                    </Modal >
                </SafeAreaView>
            )
        }else  if (this.state.status !== 'success'){
            return (
                <View style={styles.container}>
                <Text>{this.state.listForm.toString()}</Text>
                </View>
            )
        }else{
            return (
                <SafeAreaView>
                    {this.headForm()}
                    { this.listForm() }
                    <View style={styles.flex_btn}>
                    <Button 
                           style={styles.btn_action}
                           containerStyle={{display:this.state.status !== 'success' ? "none" : "block"}}
                           buttonStyle={{backgroundColor:'#336699'}}
                           icon={
                           <Icon
                               style={styles.icon}
                               name="save"
                               color='#fff'
                               size={15}
                           />}
                           title={ 'บันทึก'}
                           onPress={()=> this.mainHandleAnswer()}
                        />
                    </View> 
                </SafeAreaView>
              
             )     
        }
    }

}