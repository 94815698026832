import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text, ActivityIndicator,TouchableHighlight,Dimensions } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Input,Button, Badge, Image, Divider, Avatar} from 'react-native-elements'
import {
    DrawerContentScrollView,
    DrawerItem
} from '@react-navigation/drawer'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../../lib/style'
import axios from 'axios'
import config from '../../../../Api/config'
const dayjs = require('dayjs')
const {width, height} = Dimensions.get('window')
const ListGroupPermisson =  (props) => {
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState()
    useEffect(() => {
        getListGroupPermissions()
        const unsubscribe = props.navigation.addListener('focus', () => {
            getListGroupPermissions()
        });
          return unsubscribe;
    },[props.navigation])
    const getListGroupPermissions = () => {
        axios.get(config.uri.api + 'api/setting/getlistGroupPermisson')
        .then((res) => {
            setData(res.data.data)
            setLoading(true)
        })
        .catch((err) => {console.log(err.message)})
    }
    const detail_groupPermission =  (master_id,role_name) => {
        console.log(role_name)
        props.navigation.navigate('detail_GroupPermission',{master_id:master_id,role_name: role_name})
    }
    const AddPermission = () => {
        props.navigation.navigate('AddGroupPermission')
    }
    if (!loading){
        return (
            <SafeAreaView style={styles.container}>
                <Text>กำลังโหลดข้อมูลกลุ่มสิทธิ์ ...</Text>
                <ActivityIndicator size={'large'} color={'green'} />
            </SafeAreaView>
        )
    }
    return (
        <SafeAreaView >
              <View style={{margin:10}}>
                  <Button
                     buttonStyle = {{backgroundColor:'#339966'}}
                     style={{width:width <= 500 ? 'auto' : '10%'}}
                     icon={
                         <Icon
                            style={styles.icon} 
                            name={'save'}
                            size={18}
                            color={'#fff'}
                         />
                     }
                     onPress = { () =>{AddPermission()} } 
                     title={'เพิ่มกลุ่มสิทธิ์'}
                  />
              </View>   
               {
                  data &&  data.map((data,index) => {
                       return (
                          
                           <View key={index} style={styles.main}>
                             <TouchableHighlight key={index} onPress={()=>{detail_groupPermission(data.id,data.role_name)}}>
                               <View style={{flexDirection:'row',margin:10,padding:10}}>
                                    <View styles={{margin:10,padding:10}}>
                                        <Text >ชื่อกลุ่มสิทธิ์</Text>
                                        <Text >รายละเอียด</Text>
                                        <Text >สถานะ</Text>
                                    </View>
                                    <View styles={{margin:10,padding:10}}>
                                        <Text style={{marginLeft:10}}>{data.role_name || '-'}</Text>
                                        <Text style={{marginLeft:10}}>{data.role_description || '-'}</Text>
                                        <Text style={{marginLeft:10}}>
                                            {
                                             data.role_status !== 'active' ? <Icon name={'times-circle'} size={18} color={'red'} /> : 
                                             <Icon name={'check-circle'} size={18} color={'green'} />
                                            }
                                        </Text>
                                    </View>  
                               </View>
                               </TouchableHighlight>                             
                           </View>
                           
                       )
                   })
               }
        </SafeAreaView>
    )
}


export default ListGroupPermisson