
// const config = require('../config')
// const line = require(`@line/bot-sdk`)
// const client = new line.Client(config.line.siamchai_bot_v3) // demo
const LINE_MESSAGING_API = "https://api.line.me/v2/bot/message";
const LINE_CONTENT_API = "https://api-data.line.me/v2/bot/message";
const LINE_GROUP_API = 'https://api.line.me/v2/bot/group';
const LINE_ROOM_API = 'https://api.line.me/v2/bot/room'
const config = require ('../config')
const request = require('request-promise')
const l = require('./log')

module.exports = {
    pushtext,
    reply,
    replyFlex,
    replyFlexWithQuickReply,
    broadcast,
    getImages,
    templatQuickReply,
    groupSummary,
    groupCount,
    getMemberGroup,
    lineNotify,
    pushflex,
    memberProfileRoom,
    lineNotifyPic,
    CarouselReplyWithQuickReply

}

// line notify
async function lineNotify(token, msg) {
  return await request.post({
      headers : {
        'Content-Type' : 'application/x-www-form-urlencoded',
        Authorization : `Bearer ${token}`
      },
      uri: 'https://notify-api.line.me/api/notify',
      form:{
        message:msg
      }
  }).then(res => {l.saveLog('line notify success',res)})
  .catch(err => { l.saveLog('line notify',err.message)})
}
async function lineNotifyPic(token, url) {
  return await request.post({
      headers : {
        'Content-Type' : 'application/x-www-form-urlencoded',
        Authorization : `Bearer ${token}`
      },
      uri: 'https://notify-api.line.me/api/notify',
      form:{
        message: ' ',
        imageThumbnail : url,
        imageFullsize : url,
        // imageFile: url
      }
  }).then((res)=> console.log(res) )
  .catch((e)=> console.log(e))
}
// Get groupSummary
async function groupSummary(groupId,bot_token){
  let LINE_HEADER = {
    'Content-Type' : 'application/json',
     Authorization:`Bearer ${bot_token}` 
  }
  let groups = JSON.parse( await request.get({
    headers: LINE_HEADER,
    uri: `${LINE_GROUP_API}/${groupId}/summary`,
  }) )
  return  groups
  
//  let group = client.getGroupSummary(groupId)
//  .then()
//  .catch(e => {
//   console.error(e);
//  })
//  return group
}
// Get member count in group
async function groupCount(groupId,bot_token){
  let LINE_HEADER = {
    'Content-Type' : 'application/json',
     Authorization:`Bearer ${bot_token}` 
  }
  let count  = JSON.parse( await request.get({
    headers: LINE_HEADER,
    uri: `${LINE_GROUP_API}/${groupId}/members/count`,
  }) )
  return  count
    // let count =  client.getGroupMembersCount(groupId)
    // .then()
    // .catch((e)=>{
    //   console.error(e)
    // })  
    // return count
}
async function getMemberGroup(groupId, userId,bot_token){
  let LINE_HEADER = {
    'Content-Type' : 'application/json',
     Authorization:`Bearer ${bot_token}` 
    }
  let member_group =  JSON.parse(await request.get({
    uri: `${LINE_GROUP_API}/${groupId}/member/${userId}`,
    headers: LINE_HEADER,
    }) )
  return  member_group
  // let userid = client.getGroupMemberProfile(groupId, userId)
  // .then().catch((e)=>{
  //   console.error(e)
  // })
  // return  userid
}

// Push Message
function pushtext (userId, msg, quickItems,bot_token) {
  let LINE_HEADER = {
    'Content-Type' : 'application/json',
     Authorization:`Bearer ${bot_token}` 
    }
    return request.post({
      headers: LINE_HEADER,
      uri: `${LINE_MESSAGING_API}/push`,
      body: JSON.stringify({
        to: userId,
        messages: [{ type: "text", text: msg, quickReply: quickItems }]
      })
    })
  }
  
  function pushflex(group,message,idAnnounce,bot_token){
    let LINE_HEADER = {
      'Content-Type' : 'application/json',
       Authorization:`Bearer ${bot_token}` 
      }

      return request.post({
        headers: LINE_HEADER,
        url:`${LINE_MESSAGING_API}/push`,
        body: JSON.stringify({
          to: group,
          messages: [{
                  
                  type: "flex",
                  altText : ` ประกาศจากส่วนกลาง Siamchai (${idAnnounce})`,
                  contents : {
                      type : "flex",
                      type : "bubble",
                      size: "kilo",
                      direction: "ltr",
                      hero: {
                        type: "image",
                        url : "https://i.imgur.com/N2eTOn1.png",
                        gravity: "center",
                        size : "full",
                        aspectRatio : "14:4",
                        aspectMode : "cover",
                        backgroundColor : "#991B66"
                      },
                      body:{
                        type: "box",
                        layout: "vertical",
                        spacing : "none",
                        contents: message
                      },
                      footer:{
                        type: "box",
                        layout: "vertical",
                        spacing : "sm",
                        contents : [{
                          type: "text",
                          text: `ประกาศที่ ${idAnnounce}`,
                          align: "center"
                        },
                        {
                           type: "button",
                           style: "primary",
                           height: "sm",
                           action:{
                               type: "postback",
                               label: 'รับทราบ',
                               data: idAnnounce, 
                           }
                        }]
                      }
                  },
                  quickReply: {
                    items: [{
                      type: "action",
                      action : {
                         type: "postback",
                         label: "รับทราบ",
                         data: idAnnounce,
                      }
                    }]
                  }
          }]
        })
      })
  }

  // Reply Message
  function reply (token, payload,quickItems,bot_token) {
  
   let LINE_HEADER = {
   'Content-Type' : 'application/json',
    Authorization:`Bearer ${bot_token}` 
   }
   if (quickItems !== '') {
      return request.post({
          uri: `${LINE_MESSAGING_API}/reply`,
          headers: LINE_HEADER,
          body: JSON.stringify({
          replyToken: token,
          messages: [{ type: "text", text: payload, quickReply: quickItems }]
          })
      })
   }else{
        return request.post({
        uri: `${LINE_MESSAGING_API}/reply`,
        headers: LINE_HEADER,
        body: JSON.stringify({
        replyToken: token,
        messages: [{ type: "text", text: payload}]
        })
      })
   }
   
  }

  function replyFlex(token,id,bot_token,type) {
    let uri ;
    let text ;
    switch(type){
      case 'Form_announce' :
          uri = `${config.uri.web}announce_line/liff1/${id}`
          text = 'ประกาศเฉพาะกลุ่ม'
      break 
      case 'FormAnnounceGroup' :
        uri = `${config.uri.web}report_group/liff1/${id}`
        text = 'ดูประกาศทั้งหมด'
      break 
    }
    let LINE_HEADER = {
      'Content-Type' : 'application/json',
       Authorization:`Bearer ${bot_token}` 
      }
    return request.post({
      headers: LINE_HEADER,
      url:`${LINE_MESSAGING_API}/reply`,
      body: JSON.stringify({
      replyToken : token,
        messages: [{
                
                type: "flex",
                altText :  text,
                contents : {
                    type : "flex",
                    type : "bubble",
                    size: "kilo",
                    direction: "ltr",
                    hero: {
                      type: "image",
                      url : config.uri.api + "images/siamchai_banner.png",
                      gravity: "center",
                      size : "full",
                      aspectRatio : "14:4",
                      aspectMode : "cover",
                      backgroundColor : "#991B66"
                    },
                    body:{
                      type: "box",
                      layout: "vertical",
                      spacing : "none",
                      contents: [
                        {
                          "type": "button",
                          "style": "link",
                          "height": "sm",
                          "action": {
                            "type": "uri",
                            "label": text,
                            "uri" : uri
                          },
                          "style": "primary",
                          "color": "#c46aa6"
                        }
                      ]
                    }
                }
        }]
      })
    })
  }
  function replyFlexWithQuickReply(token,bot_token,bodyFlex,bodyQuickReply,label){
    let LINE_HEADER = {
      'Content-Type' : 'application/json',
       Authorization:`Bearer ${bot_token}` 
      }
    
      return request.post({
        headers: LINE_HEADER,
        url:`${LINE_MESSAGING_API}/reply`,
        body: JSON.stringify({
        replyToken : token,
          messages: [{
                  
                  type: "flex",
                  altText :  label,
                  contents : {
                      type : "bubble",
                      size: "kilo",
                      direction: "ltr",
                      "hero": {
                        "type": "box",
                        "layout": "vertical",
                        "contents": [
                          {
                            "type": "text",
                            "text": "เลือกแบบฟอร์มที่ต้องการ",
                            "align": "center",
                            "size": "20px",
                            "color": "#ffffff",
                            "weight": "bold",
                            "gravity": "center",
                            "position": "relative",
                            "decoration": "none"
                          }
                        ],
                        height: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor : "#991B66"
                      },
                      body:{
                        alignItems: "flex-start",
                        type: "box",
                        layout: "vertical",
                        spacing : "none",
                        contents: bodyFlex
                      }
                  },
                   quickReply:bodyQuickReply 
          }],
        })
      })
  }
  function CarouselReplyWithQuickReply(token, bot_token, arrayBubble, bodyQuickReply, label) {
    console.log("################## arrayBubble : ",arrayBubble.length)
    let LINE_HEADER = {
      'Content-Type' : 'application/json',
       Authorization:`Bearer ${bot_token}` 
    }
    return request.post({
      headers: LINE_HEADER,
      url:`${LINE_MESSAGING_API}/reply`,
      body: JSON.stringify({
        replyToken : token,
        messages :[
          {
             type: "flex",
             altText: label,
             contents:{
                type: "carousel",
                contents: arrayBubble
             },
             quickReply:bodyQuickReply 
          }
        ]
      }) 
    })

  }
  // Broadcast Messages
async  function broadcast (msg,bot_token) {
    let LINE_HEADER = {
      'Content-Type' : 'application/json',
       Authorization:`Bearer ${bot_token}` 
      }
    let bc =  JSON.parse( await request.post({
      uri: `${LINE_MESSAGING_API}/broadcast`,
      headers: LINE_HEADER,
      body: JSON.stringify({
        messages: [{ type: "text", text: msg }]
      })
    }) )
    return  bc
  }

async  function memberProfileRoom (room_id, user_id,bot_token){
    let LINE_HEADER = {
      'Content-Type' : 'application/json',
       Authorization:`Bearer ${bot_token}` 
    }
    let memberProfileRoom =  await request.get({
      headers: LINE_HEADER,
      uri: `${LINE_ROOM_API}/${room_id}/member/${user_id}`,
    }) 
    return memberProfileRoom
    // let result =  client.getRoomMemberProfile(room_id, user_id)
    // .then().catch(e=>console.log(e))
    // return result
  }

async function getImages(id,bot_token){
  let LINE_HEADER = {
    'Content-Type' : 'application/json',
     Authorization:`Bearer ${bot_token}` 
  }
    return await request.get({
        headers: LINE_HEADER,
        uri: `${LINE_CONTENT_API}/${id}/content`,
        encoding: null // กำหนดเป็น null เพื่อให้ได้ binary ที่สมบูรณ์
    })
}

// ##### templat #####
function templatQuickReply(type,label,data,displayText){
    
    let results = {
        "type": "action",
        "action":{
          "type": type,
          "label": label,
          "data": data,
          "displayText": displayText,
        }
      }

    return results
}






// const config = require('../config')
// const line = require(`@line/bot-sdk`)
// const client = new line.Client(config.line)
// const LINE_MESSAGING_API = "https://api.line.me/v2/bot/message";
// const LINE_CONTENT_API = "https://api-data.line.me/v2/bot/message";
// const LINE_HEADER = {
//   'Content-Type' : 'application/json',
//   Authorization:`Bearer ${config.line.channelAccessToken}` 
// }
// const LINE_HEADER_NOTIFY = {
 
  
// }
// const request = require('request-promise')

// module.exports = {
//     pushtext,
//     reply,
//     replyFlex,
//     broadcast,
//     getImages,
//     templatQuickReply,
//     groupSummary,
//     groupCount,
//     getMemberGroup,
//     lineNotify,
//     pushflex,
//     memberProfileRoom

// }

// // line notify
// async function lineNotify(token, msg) {
//   return await request.post({
//       headers : {
//         'Content-Type' : 'application/x-www-form-urlencoded',
//         Authorization : `Bearer ${token}`
//       },
//       uri: 'https://notify-api.line.me/api/notify',
//       form:{
//         message:msg
//       }
//   })
// }
// // Get groupSummary
// async function groupSummary(groupId){
//  let group = client.getGroupSummary(groupId)
//  .then()
//  .catch(e => {
//   console.error(e);
//  })
//  return group
// }
// // Get member count in group
// async function groupCount(groupId){
//     let count =  client.getGroupMembersCount(groupId)
//     .then()
//     .catch((e)=>{
//       console.error(e)
//     })  
//     return count
// }
// async function getMemberGroup(groupId, userId){
//   let userid = client.getGroupMemberProfile(groupId, userId)
//   .then().catch((e)=>{
//     console.error(e)
//   })
//   return  userid
// }

// // Push Message
// function pushtext (userId, msg, quickItems) {
//     return request.post({
//       headers: LINE_HEADER,
//       uri: `${LINE_MESSAGING_API}/push`,
//       body: JSON.stringify({
//         to: userId,
//         messages: [{ type: "text", text: msg, quickReply: quickItems }]
//       })
//     })
//   }
  
//   function pushflex(group,message,idAnnounce){
//       return request.post({
//         headers: LINE_HEADER,
//         url:`${LINE_MESSAGING_API}/push`,
//         body: JSON.stringify({
//           to: group,
//           messages: [{
                  
//                   type: "flex",
//                   altText : ` ประกาศจากส่วนกลาง Siamchai (${idAnnounce})`,
//                   contents : {
//                       type : "flex",
//                       type : "bubble",
//                       size: "kilo",
//                       direction: "ltr",
//                       hero: {
//                         type: "image",
//                         url : "https://i.imgur.com/N2eTOn1.png",
//                         gravity: "center",
//                         size : "full",
//                         aspectRatio : "14:4",
//                         aspectMode : "cover",
//                         backgroundColor : "#991B66"
//                       },
//                       body:{
//                         type: "box",
//                         layout: "vertical",
//                         spacing : "none",
//                         contents: message
//                       },
//                       footer:{
//                         type: "box",
//                         layout: "vertical",
//                         spacing : "sm",
//                         contents : [{
//                           type: "text",
//                           text: `ประกาศที่ ${idAnnounce}`,
//                           align: "center"
//                         },
//                         {
//                            type: "button",
//                            style: "primary",
//                            height: "sm",
//                            action:{
//                                type: "postback",
//                                label: 'รับทราบ',
//                                data: idAnnounce, 
//                            }
//                         }]
//                       }
//                   },
//                   quickReply: {
//                     items: [{
//                       type: "action",
//                       action : {
//                          type: "postback",
//                          label: "รับทราบ",
//                          data: idAnnounce,
//                       }
//                     }]
//                   }
//           }]
//         })
//       })
//   }

//   // Reply Message
//   function reply (token, payload,quickItems) {
//    if (quickItems !== undefined) {
//       return request.post({
//           uri: `${LINE_MESSAGING_API}/reply`,
//           headers: LINE_HEADER,
//           body: JSON.stringify({
//           replyToken: token,
//           messages: [{ type: "text", text: payload, quickReply: quickItems }]
//           })
//       })
//    }else{
//         return request.post({
//         uri: `${LINE_MESSAGING_API}/reply`,
//         headers: LINE_HEADER,
//         body: JSON.stringify({
//         replyToken: token,
//         messages: [{ type: "text", text: payload}]
//         })
//       })
//    }
   
//   }

//   function replyFlex(token,id) {
//     return request.post({
//       headers: LINE_HEADER,
//       url:`${LINE_MESSAGING_API}/reply`,
//       body: JSON.stringify({
//       replyToken : token,
//         messages: [{
                
//                 type: "flex",
//                 altText : `ประกาศเฉพาะกลุ่ม`,
//                 contents : {
//                     type : "flex",
//                     type : "bubble",
//                     size: "kilo",
//                     direction: "ltr",
//                     hero: {
//                       type: "image",
//                       url : "https://i.imgur.com/N2eTOn1.png",
//                       gravity: "center",
//                       size : "full",
//                       aspectRatio : "14:4",
//                       aspectMode : "cover",
//                       backgroundColor : "#991B66"
//                     },
//                     body:{
//                       type: "box",
//                       layout: "vertical",
//                       spacing : "none",
//                       contents: [
//                         {
//                           "type": "button",
//                           "style": "link",
//                           "height": "sm",
//                           "action": {
//                             "type": "uri",
//                             "label": "ประกาศเลือกกลุ่ม",
//                             "uri" : `https://demo-line.travox.com/announce_line/liff1/${id}`
//                           },
//                           "style": "primary",
//                           "color": "#c46aa6"
//                         }
//                       ]
//                     }
//                 }
//         }]
//       })
//     })
//   }
//   // Broadcast Messages
//   function broadcast (msg) {

//     return request.post({
//       uri: `${LINE_MESSAGING_API}/broadcast`,
//       headers: LINE_HEADER,
//       body: JSON.stringify({
//         messages: [{ type: "text", text: msg }]
//       })
//     })
//   };


// async function getImages(id){
//     return await request.get({
//         headers: LINE_HEADER,
//         uri: `${LINE_CONTENT_API}/${id}/content`,
//         encoding: null // กำหนดเป็น null เพื่อให้ได้ binary ที่สมบูรณ์
//     })
// }
// function memberProfileRoom (room_id, user_id){
//   let result =  client.getRoomMemberProfile(room_id, user_id)
//   .then().catch(e=>console.log(e))
//   return result
// }
// // ##### templat #####
// function templatQuickReply(type,label,data,displayText){
    
//     let results = {
//         "type": "action",
//         "action":{
//           "type": type,
//           "label": label,
//           "data": data,
//           "displayText": displayText,
//         }
//       }

//     return results
// }





