import axios from 'axios';
import React from 'react'
import { View,ScrollView,SafeAreaView,ActivityIndicator, ActivityIndicatorBase,FlatList,TouchableHighlight ,RefreshControl } from 'react-native';
import { Avatar,Card,Input,Button,ListItem,Badge,SearchBar,Image,CheckBox,Text,Divider} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../lib/style'
import {Picker} from '@react-native-picker/picker';
import  config from '../../../Api/config'
import Modal from "modal-enhanced-react-native-web";
import AsyncStorage from '@react-native-async-storage/async-storage';
const dayjs = require('dayjs');
export default class  ApproveForm  extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            department_id : 0,
            loading: false,
            refresh : false,
            listForm : [],
            tempForm : [],
            status : '',
            search: '',
            valueDropdown : 'All',
        }
    }
        getForm = async ()=>{
        let department_id = await AsyncStorage.getItem('department_id')
        let permission = await AsyncStorage.getItem('permission')
        axios.post( config.uri.api +'api/form/getWaitApproveLineForm',{params:{department_id: department_id,permission: permission}})
        .then(res=>{    
            this.setState({loading: true,listForm: res.data.data,tempForm: res.data.data,status: res.data.status,department_id: department_id})
        }).catch(err=>{
            this.setState({loading: true ,listForm: err.message ,status: 'error'})
            console.log(err);
        })
    }
    componentDidMount (){
        this.getForm()
       this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.getForm()
          });
        }
    
    componentWillUnmount(){
        this._unsubscribe()
    }
    listDetailsForm = (form_id,staff_id,group_id,group_name,name_form,form_desc,line_name,date,flag)=>{
        this.props.navigation.navigate('approve_form_detail',
        {
            form_id : form_id,
            staff_id : staff_id,
            group_id :group_id,
            group_name : group_name,
            form_name: name_form,
            line_name: line_name,
            date:date,
            status: flag,
            description: form_desc,
            department_id: this.state.department_id
        })
    }
    updateSearch = (value) =>{
        let word = value.trim()?.toLowerCase()
        let old_data = this.state.tempForm
        let new_data
        if (this.state.search.length <= 0 && this.state.valueDropdown === 'All'){
             this.setState({tempForm: old_data})
        }else if (this.state.valueDropdown === 'All'){
            new_data =  this.state.listForm.filter((row)=>{
                return  row.name_form?.includes(word) 
                ||  row.group_name?.includes(word)
                ||  row.line_name?.includes(word)
                ||  row.flag?.includes(word) 
            })
        }else{
            new_data =  this.state.listForm.filter((row)=>{
                return  row.name_form?.includes(word) && row.flag.includes(this.state.valueDropdown) 
                ||  row.group_name?.includes(word) && row.flag.includes(this.state.valueDropdown)
                ||  row.line_name?.includes(word) && row.flag.includes(this.state.valueDropdown)
                
           })
        }
         
         console.log(new_data)
         this.setState({tempForm: new_data, search: value})   

    }
   
    itemView = ({item,i}) =>{
        let show_date =  dayjs(item.system_date).format('DD/MM/YYYY HH:mm:ss')
        // let new_format = item.system_date.split(/[- :]/)                       
        // let new_date = new Date(new_format[0], new_format[1] -1, new_format[2], new_format[3], new_format[4], new_format[5]);
        // let date = new Date(new_date)
        // let show_date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
        return(
            <SafeAreaView style={styles.main}>
                <TouchableHighlight  key={i} onPress={()=>this.listDetailsForm(item.form_id,item.staff_id,item.line_group_id,item.group_name,item.name_form,item.description,item.line_name,show_date,item.flag)}>
                    <View>
                        <Text style={styles.textHeader}>{item.name_form}</Text>
                        <Text style={styles.textDesc}>{item.description}</Text>
                        <Text style={styles.textDesc}>ประเภทฟอร์ม {item.re_use === true ? 'ปกติ' : 'ใช้ครั้งเดียว'}</Text>
                         <Divider/>
                        <View style={styles.detailFormApprove}>
                            <View >
                                <Text style={styles.textApproveForm}>กลุ่ม : </Text>
                                <Text style={styles.textApproveForm}>ผู้ขออนุมัติ : </Text>
                                <Text style={styles.textApproveForm}>แผนกอนุมัติ : </Text>
                                <Text style={styles.textApproveForm}>สถานะ : </Text>
                                <Text style={styles.textApproveForm}>วันที่ : </Text>
                            </View>
                        
                            <View style={{alignItems: 'flex-start',paddingLeft:10}}>
                            <Text style={styles.textApproveForm}>{item.group_name}</Text>
                                <Text style={styles.textApproveForm}>{item.line_name}</Text>
                                <Text style={styles.textApproveForm}>{item.department_name.length <= 0 ? '-' : item.department_name}</Text>
                                {this.setFlag(item.flag)}
                                <Text style={styles.textApproveForm}>{show_date}</Text>
                            </View>
                        </View>
                       
                    </View>
                </TouchableHighlight>
            </SafeAreaView>
        )
    }
    setFlag = (flag)=>{
        if (flag === 'รออนุมัติ') {
            return (
                <Text style={{fontSize:18,color:'orange', padding:5}}>{flag}</Text>
            )
        }else if (flag === 'ไม่อนุมัติ'){
            return (
                <Text style={{fontSize:18,color:'red', padding:5}}>{flag}</Text>
            )
        }else if (flag === 'อนุมัติแล้ว'){
            return (
                <Text style={{fontSize:18,color:'green', padding:5}}>{flag}</Text>
            )
            
        }else if(flag === 'รอคำตอบ'){
            return (
                <Text style={{fontSize:18,color:'#CC00FF', padding:5}}>{flag}</Text>
            )

        }else{
            return (
                <Text style={{fontSize:18,color:'blue', padding:5}}>{'ไม่พบสถานะ'}</Text>
            )
        }
    }
    onValueChangeType = (value)=>{
        
        let temp = this.state.listForm
        let new_data  = []
      
        if (value === 'All'){
            this.setState({tempForm: temp,valueDropdown:value})
        }else if (value === 'อนุมัติแล้ว'){
            new_data =  this.state.listForm.filter((row)=>{
                return  row.name_form?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value) 
                ||  row.group_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                ||  row.line_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                
           })
           this.setState({tempForm: new_data,valueDropdown:value})
        }else if (value === 'ไม่อนุมัติ'){
            new_data =  this.state.listForm.filter((row)=>{
                return  row.name_form?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value) 
                ||  row.group_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                ||  row.line_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                
           })
           this.setState({tempForm: new_data,valueDropdown:value})
        }else if (value === 'รออนุมัติ'){
            new_data =  this.state.listForm.filter((row)=>{
            console.log("onValueChangeType 1:", row);
            console.log("onValueChangeType 1:", row.name_form?.toLowerCase());
            console.log("onValueChangeType 2:", this.state.search);
            console.log("onValueChangeType 2:", this.state.search?.toLowerCase());
                return  row.name_form?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value) 
                ||  row.group_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                ||  row.line_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                
           })
           this.setState({tempForm: new_data,valueDropdown:value})
        }else if (value === 'รอคำตอบ'){
            new_data =  this.state.listForm.filter((row)=>{
                return  row.name_form?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value) 
                ||  row.group_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                ||  row.line_name?.toLowerCase().includes(this.state.search?.toLowerCase()) && row.flag.includes(value)
                
           })
           this.setState({tempForm: new_data,valueDropdown:value})
        }
        
     
    }
    render() {
        if (this.state.loading === false){
            return (
                    <SafeAreaView style={styles.container}>
                    <Text>กำลังโหลดฟอร์ม</Text>
                    <ActivityIndicator size={'large'} color={'#33FF66'} />
                    </SafeAreaView>
                
            )
        }else if (this.state.status === 'success' && this.state.listForm.length > 0){
        return  (
            <View style={{backgroundColor:'#F5F5F5'}}>
                
                 <Picker style={{margin:10,padding:10,height: 50,borderWidth:0,color:'gray',fontSize:15}} 
                                selectedValue={this.state.valueDropdown}
                                onValueChange={(itemValue) => this.onValueChangeType(itemValue) }>
                                <Picker.Item label="All" value="All" /> 
                                <Picker.Item label="รออนุมัติ" value="รออนุมัติ" />
                                <Picker.Item label="ไม่อนุมัติ" value="ไม่อนุมัติ" /> 
                                <Picker.Item label="อนุมัติแล้ว" value="อนุมัติแล้ว" /> 
                                <Picker.Item label="รอคำตอบ" value="รอคำตอบ" /> 
                 </Picker> 
                
                <SearchBar
                round = {true}
                placeholder= 'ค้นหา...'
                onChangeText = {this.updateSearch}
                value={this.state.search}
                containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,margin:10}}
                inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
                />
                 <FlatList
                data={this.state.tempForm}
                keyExtractor={(item,index) => index.toString()}
                renderItem={this.itemView}
                />
            </View>
           
                         
             )
        }else{
            return (
                <SafeAreaView style={styles.container}>
                    <Text>{this.state.listForm}</Text>
                </SafeAreaView>
            )
        }
       
    }
   
}