import axios from 'axios';
import React from 'react';
import { View,ScrollView,SafeAreaView,ActivityIndicator, ActivityIndicatorBase,FlatList,TouchableHighlight,Dimensions  } from 'react-native';
import { Avatar,Card,Button,ListItem,Badge,SearchBar,Image,CheckBox,Text,Divider,Input, ThemeConsumer} from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import styles from '../../lib/style';
import  config from '../../../Api/config'
import {Picker} from '@react-native-picker/picker';
const windowSize = Dimensions.get("window");
const screen = Dimensions.get("screen");
const  {width,height} = Dimensions.get('window')
export default class Form_Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            listDetails : [],
            dimensions :{
             windowSize,screen
            },
            master_line_form_id : props.route.params.form_id,
            staff_line_id  : props.route.params.staff_id,
            group_id : props.route.params.group_id,
            group_name : props.route.params.group_name,
            line_name : props.route.params.line_name,
            form_name : props.route.params.form_name,
            description : props.route.params.description,
            date: props.route.params.date,
            flag: props.route.params.status,
            status : '',
            department_id: props.route.params.department_id,
            permissions: props.route.params.permissions,
        }
        // this.onChangeScreen = this.onChangeScreen.bind(this)
    }
    getFormDetail = () =>{
        axios.post(  config.uri.api +  'api/form/listFormDetail',{params:{
            master_line_form_id : this.state.master_line_form_id,
            staff_line_id : this.state.staff_line_id,
            group_id : this.state.group_id,
        }}).then(res=>{
            this.setState({loading: true, listDetails: res.data.data,status: res.data.status,flag:res.data.flag})
        }).catch(err=>{
            console.log(err.message);
            this.setState({loading: true, listDetails: err.message,status: 'error'})
            
        })
    }
    onChangeScreen =({window,screen})=>{
        this.setState({dimensions: {window,screen}})
        let { dimensions } = this.state
    }
    componentDidMount(){
       
        Dimensions.addEventListener("change", this.onChangeScreen);
        this.getFormDetail();
      
    }
   
    componentWillUnmount(){
        Dimensions.removeEventListener("change", this.onChangeScreen);
    }
    answer = (type,name,answer) =>{
        let { dimensions } = this.state
        if (type === 'text'){
            return (
                // <Input style={styles.inputhead}  value={answer} disabled={true} />
                <Text style={styles.ansText}>{answer}</Text>
            )
        }else if (type === 'radio'){
            return(
                <View style={styles.row}>
                    {
                         answer && answer.map(item=>{
                            return(
                                <CheckBox disabled={true} title={item.title} checked={item.status} />
                            )
                        })
                    }  
                </View>
        )
        }else if (type === 'uploadFile'){
            if (answer.length <= 0 ){return (<View style={styles.row}><Text>-</Text></View>)}
            return(
                    answer.map((row,index)=>{
                      let name = row.name.length <= 0 ? '' : (dimensions.windowSize.width <= 500 ? row.name.substr(0,10) + '...' : row.name + '.' + row.type)
                    //   let name = dimensions.window.width <= 500 ? row.name.length <= 0 ? '' : row.name.substr(0,10) + '...' : row.name +'.'+row.type
                      let remark = !row.remark || row.remark.length <= 0 ? '' : (dimensions.windowSize.width <= 500 ? JSON.stringify(row.remark).substr(0,10) : row.remark)
                      if(!row.name || row.name == "" || !row.type || row.type == "") {
                        return (<View style={styles.row}><Text>-</Text></View>)
                      }
                        return (
                            <View style={styles.row}>
                                 {/* <Button
                                    titleStyle={{ fontsize:'15px' }}
                                    icon ={
                                        <Icon 
                                        style={styles.icon}
                                        name='download'
                                        size={15}
                                        color='#fff'
                                        />
                                    } 
                                    title={name}
                                    onPress={()=>this.downloadFile(row.uri,row.name,row.type)}
                                />
                                <View style={{justifyContent:'center'}}>
                                <Text style={{alignItems:'center',marginLeft:10,justifyContent:'center',alignContent:'center'}}>หมายเหตุ : {remark}</Text>
                                </View> */}
                                {/* <Button
                                    style={{margin:10,width:width <= 500 ? 'auto' : '15%'}}
                                    title={row.name+'.'+row.type}
                                    icon={
                                        <Icon
                                            style={styles.icon} 
                                            name={'file'}
                                            size={20}
                                            color={'#fff'}
                                        />
                                    }
                                    onPress={()=>{
                                        if(answer?.length > 0){
                                            let obj = row.uri+'/'+row.name+'.'+row.type
                                            window.open(`${config.uri.api}api/getfile?uri=${obj.uri}`)
                                        }
                                    }}
                                /> */}
                                <Text 
                                    style={{color:'white',padding:5,margin:2,borderRadius:5,backgroundColor:'purple'}}
                                    onPress={()=>{
                                        if(answer?.length > 0) {
                                            console.log("open file:",row);
                                            let obj = row.uri+'/'+row.name+'.'+row.type
                                            window.open(`${config.uri.api}api/getfile?uri=${obj}`)
                                        }
                                    }}
                                    >
                                    {/* {`${'('+(index+1)+')'} ${width <= 500 ? file.name.substr(0,10) +  '.' + file.type : file.name + '.' + file.type}`} */}
                                    {`${''+(index+1)+' )'} ${row.name}.${row.type}`}
                                </Text>
                            </View>
                        )
                    })
            )
        }
    }
    downloadFile =  (uri,name,type) =>{
        let link = document.createElement('a')
        if (uri.length > 0 || name.length > 0 || type.length > 0){
            fetch(uri).then((res)=>{
               res.blob().then(blob=>{
                let newOBject =  URL.createObjectURL(blob);
                link.href = newOBject
                link.download = `${name}.${type}`
                link.target="_blank"   
                link.click()
               })
            }).catch(err=>{
                    console.log(err.message)
            })
        }
      
    }
    itemView = ({item})=>{
        return (
            <SafeAreaView style={styles.main}>
                       <Text style={styles.Question}>{item.question}</Text>
                       {
                           this.answer(item.type_ans, item.name,item.answer)
                       }
            </SafeAreaView>
        )
    }
    saveApprove = ()=>{
        axios.post( config.uri.api + 'api/form/updateApproveForm',{params:{
            master_line_form_id:this.state.master_line_form_id,
            staff_line_id: this.state.staff_line_id,
            group_id : this.state.group_id,
            type: 'อนุมัติ',
            department_id: this.state.department_id,
            permissions: this.state.permissions,
        }}).then(res=>{
            alert(res.data.data)
            this.props.navigation.pop()
            
        }).catch(e=>{
            console.log(e.message)
        })
    }
    cancleApprove = ()=>{
        axios.post( config.uri.api +  'api/form/updateApproveForm',{params:{
            master_line_form_id:this.state.master_line_form_id,
            staff_line_id: this.state.staff_line_id,
            group_id : this.state.group_id,
            type: 'ไม่อนุมัติ'
        }}).then(res=>{
            alert(res.data.data)
            this.getFormDetail()
            // window.open('http://localhost:3300//approve_form','_self')

        }).catch(e=>{
            console.log(e.message)
        })
    }
    setFlag = ()=>{
        if (this.state.flag === 'Wait') {
            return (
                <Text style={{color:'orange',paddingLeft:5,paddingBottom:5,fontSize:18}}>สถานะ : รออนุมัติ</Text>
            )
        }else if (this.state.flag === 'NotApprove'){
            return (
                <Text style={{color:'red',paddingLeft:5,paddingBottom:5,fontSize:18}}>สถานะ : ไม่อนุมัติ</Text>
            )
        }else if (this.state.flag === 'Approve'){
            return (
                <Text style={{color:'green',paddingLeft:5,paddingBottom:5,fontSize:18}}>สถานะ : อนุมัติแล้ว</Text>
            )
        }else if (this.state.flag === 'WaitQ'){
            return (
                <Text style={{color:'#CC00FF',paddingLeft:5,paddingBottom:5,fontSize:18}}>สถานะ : รอคำตอบ</Text>
            )
        }else{
            return (
                <Text style={{color:'blue',paddingLeft:5,paddingBottom:5,fontSize:18}}>สถานะ : Unknown status</Text>
            )
        }
    }
    render() {
        if (this.state.loading === false) {
            return (
                <SafeAreaView style={styles.container}>
                <Text>กำลังโหลดฟอร์ม</Text>
                <ActivityIndicator size={'large'} color={'#33FF66'} />
                </SafeAreaView>
            )
         
        }else if (this.state.status === 'success' && this.state.listDetails.length > 0){
            return (
                <SafeAreaView>
                    <View>
                        <View style={styles.headerApprove}>
                             <Text style={styles.textHeader}>{this.state.form_name}</Text>
                             <Text style={styles.textDesc}>{this.state.description}</Text>
                             {/* <View > */}
                                <Text style={styles.textApproveForm} >ผู้ขออนุมัติ : {this.state.line_name}</Text>
                                {this.setFlag()}
                             {/* </View> */}
                            
                        </View>
                        <View>
                        <FlatList  
                            data={this.state.listDetails}
                            renderItem={this.itemView}
                            keyExtractor={(item,index) => index.toString()}
                            />
                        </View>
                        <SafeAreaView style={styles.flex_btn}>
                         <Button
                            disabled={this.state.flag !== 'Wait'}
                            style={styles.btn_action}
                            buttonStyle={{backgroundColor:'#336699'}}
                            icon={
                            <Icon
                                style={styles.icon}
                                name="save"
                                color='#fff'
                                size={15}
                            />}
                            title='อนุมัติ'
                            onPress={()=> this.saveApprove()}
                         />
                         <Button
                            disabled={this.state.flag !== 'Wait'}
                            style={styles.btn_action}
                            buttonStyle={{backgroundColor:'#a50b0b'}}
                            icon={
                            <Icon
                                style={styles.icon}
                                name="ban"
                                color='#fff'
                                size={15}
                            />}
                            title='ไม่อนุมัติ'
                            onPress={()=> this.cancleApprove()}
                         />
                     </SafeAreaView>   
                    </View>
                </SafeAreaView>
            ) 
        }else{
            return (
                <SafeAreaView style={styles.container}>
                <Text>กำลังโหลดรายละเอียดฟอร์ม</Text>
                <ActivityIndicator size={'large'} color={'#33FF66'} />
                </SafeAreaView>
                )
        }
        
    }
}