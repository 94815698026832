import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text, ActivityIndicator} from 'react-native'
import {Input,Button, Badge, Image, Divider} from 'react-native-elements'
import styles from '../../lib/style'
import liff from '@line/liff';
import _liff from '../../lib/config-line-liff'
import Config from '../../../Api/config'
const Logout = (props) =>{
    const {navigation,route} = props
    const [isloading,setIsloading] = useState(true)
    useEffect(() => {
      const  checkLineLiff = async () => {
        await  liff.init({liffId:_liff.line_liff.line_login})
        .catch((e)=>{
          console.log(e.message)
        })
        if (liff.login){
            setIsloading(false)
            liff.logout()
        }
      }
       checkLineLiff()
    },[])

    if (isloading === true) {
        return (
        <SafeAreaView styles={styles.container}>
            <Text>กำลังออกจากระบบ...</Text>
            <ActivityIndicator size={'large'} color={"green"}  />
        </SafeAreaView>
        ) 
    }else{
        return (
            <SafeAreaView styles={styles.container}>
                <Text>ออกจากระบบเรียบร้อยแล้ว </Text>
                <Button
                    buttonStyle = {{backgroundColor: 'green'}}
                    title ={'เข้าสู่ระบบใหม่อีกครั้ง'}
                    onPress = {()=>window.location.href = Config.uri.web}
                />
            </SafeAreaView>
        )
    }
        
}   

export default Logout