import axios from 'axios';
import React from 'react'
import { Platform,StyleSheet, Text, ActivityIndicator,TouchableHighlight,ScrollView,SafeAreaView,View,TextInput,Dimensions } from 'react-native';
import { CheckBox ,Avatar,Card,Input,Button,ListItem,Badge,SearchBar,Image } from 'react-native-elements'
import liff from '@line/liff';
import _liff from '../../lib/config-line-liff'
import * as ImagePicker from 'expo-image-picker';
import  config from '../../../Api/config'
import { Pagination } from 'react-rainbow-components';
import Modal from "modal-enhanced-react-native-web"
import styless from '../../lib/style';
import Icon from 'react-native-vector-icons/FontAwesome'
const {width, height} = Dimensions.get('window')
class Announce extends React.Component{
        constructor(props) {
            super(props)
            
            this.state = {
                loading: false,
                isCheckedAll : false,
                message : '',
                search : '',
                image  : [],
                title: '',
                activePage : 1,
                Announce: [],
                getAnnounce: [],
                showModal : false,
                id : (isNaN(props.route.params.id) === true ? '' : props.route.params.id),
                groups : [],
                status: '',
                line : {
                    name : '',
                    userId : '',
                    Picture : '' 
                }

            }
        }
        checkRequired =  () => {
            let groups = this.state.groups
            let msg = this.state.message
            let title = this.state.title
            groups =  groups.filter((g,i)=>{
                return g.checked !== false 
            })
            if (title.trim().length <= 0) return this.handleAlert('กรุณากรอกหัวข้อเรื่องด้วยค่ะ')
            if (msg.trim().length <= 0)   return this.handleAlert('กรุณากรอกเนื้อหาข้อความที่จะประกาศ')
            if (msg.trim().length > 1000) return this.handleAlert(`เนื้อหาประกาศเกิน 1000 ตัวอักษร (${msg.trim().length})`)
            if (groups.length <= 0)       return this.handleAlert('โปรดเลือกกลุ่มอย่างน้อย 1 กลุ่ม')
            if (this.state.id === '')     return this.handleAlert('ไม่สามารถประกาศได้ โปรดปิดเว็บและทำการเปิดใหม่')
            return this.save(groups)
            
        }
        save = (groups) =>{
            let line_owner = this.state.line
            axios.post(config.uri.api + 'api/announce_line/saveAnnounce',{
                params:{
                data: groups,
                msg : this.state.message.trim(),
                title: this.state.title.trim(),
                owner: line_owner,
                id: this.state.id,
                img: this.state.image
            }})
            .then((res)=>{

                if (res.data.status === 'success' ) return this.clearData(res.data)
                return this.handleAlert(res.data.message)
            }).catch((e)=>{
                return alert(e.message)
            })
            
        }
        clearData = (data) => {
            let groups = this.state.groups
            this.handleAlert(data.message)
            groups = groups.map(group => {
                    group.checked = false
                    return group
            })
            this.setState({
                groups : groups,
                message: '',
                search: '',
                image : [],
                isCheckedAll : false
            })
           
        }
        handleAlert = (msg) =>{
            return alert(msg)
        }
        updateSearch = (Search) =>{
            this.setState({search : Search})
        }
        deltext = (e)=>{
            this.setState({message : ''})
        }
        setText = (e) =>{
          
            this.setState({message : e})
        }
        handleAllChecked = (event) => {
           let groups = this.state.groups

                groups.forEach(group => {
                    group.checked = !this.state.isCheckedAll
                })
                this.setState({
                    groups : groups,
                    isCheckedAll : !this.state.isCheckedAll
                })

           
        }
        handleCheckChieldElement  = (event,id) => {
            let groups = this.state.groups
           
            groups.forEach(group => {
                if ( group.id === id ) 
                group.checked = !group.checked
            })
            this.setState({
                groups : groups,
                isCheckedAll : false
             })
            
        }
        get_group = () =>{
            axios.post( config.uri.api + 'api/announce_line/getGroupAnnounce')
            .then((res)=>{
                this.setState({
                    loading: true,
                    groups : res.data.data
                })
            }).catch((e)=>{
                console.log(e.message)
                this.setState({
                    loading: true,
                    groups : e.message
                })
            })
        }
       
        componentDidMount = async ()=>{
        //    console.log('group_id :',this.state.id)
            await  liff.init({liffId:_liff.line_liff.announce_line}).catch((e)=>{console.log(e)})
            if (this.state.id === '' ){
                this.setState({loading: true})
                console.log(`error`)
                return window.location.assign("https://developers.line.biz")
            
            }
            if (liff.isLoggedIn()) {
                let getProfile = await liff.getProfile()
                 this.setState({
                    line : {
                        name : getProfile.displayName,
                        userId : getProfile.userId,
                        Picture : getProfile.pictureUrl, 
                    }
                 })
                 console.log('login line success')
                 await this.get_group()
                 await this.getAllAnounce()
              }else{
                liff.login({redirectUri: `${config.uri.web}announce_line/liff1/${this.state.id}`})
              }
           
        }
        getAllAnounce = () => {
           axios.get(config.uri.api + 'api/announce_line/getAllAnnounce')
           .then(res=>{
               this.setState({Announce: res.data.data, status: res.data.status})
           }).catch(err=>{
               console.log(err.message)
               this.setState({Announce: err.message, status: 'error'})
           })
        }
        pickImage = async ()=>{
            let images = [...this.state.image]
                if (Platform.OS !== 'web') {
                    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                    if (status !== 'granted') {
                      alert('Sorry, we need camera roll permissions to make this work!');
                    }
                }
                
                    let result = await ImagePicker.launchImageLibraryAsync({
                        mediaTypes: ImagePicker.MediaTypeOptions.Images,
                        allowsEditing: true,
                        base64:false,
                        aspect: [1, 1],
                        quality: 1,
                      });
                    
                    
                    images.push({uri: result.uri,type: 'image'});
                     this.setState({
                        image: images
                     })
        }       
        showImg = ()=>{
            if (this.state.image.length <= 0){
                return (
                    <View style={{margin:10}} >
                    
                    <Image
                        PlaceholderContent={<Text>ไม่มีรูปภาพ</Text>} 
                        source={{ uri: this.state.image }}
                        style={{  height: 150,padding:10,justifyContent:'center',resizeMode:'contain',maxWidth:'100%',maxHeight:'100%'}} />
                    </View>  
                )
                
            }else{
                return (
                
                    <View style={{flexDirection:'row',justifyContent:'center',overflow:'scroll',backgroundColor:'#fff',margin:10}}>
                    
                    { 

                        this.state.image.map((e,i)=>{
                            
                            return (
                                <View style={{display:'flex',flexDirection:'row'}}>
                                
                                <Image
                                    onPress = { () => this.editImg(i)}
                                    onLongPress= {() => this.delImg(i)}
                                    key={i}
                                    source={{ uri: e.uri }}
                                    style={{ flexDirection:'row', 
                                    justifyContent:'center',
                                    width:200, 
                                    height: 200,
                                    margin:10,
                                    maxWidth:'100%',
                                    maxHeight:'100%',
                                    resizeMode:'contain'}} 
                                />
                                    <Icon onPress={()=> this.delImg(i)} name='times' size={25} type='font-awesome' color='red' containerStyle={{display:'flex',justifyContent:'flex-start',alignItems: 'flex-start'}} />
                                </View>
                                

                            )
    
                        })
                    }

                        
                    </View>
                    
                )
                
                    
            }
        }
        editImg = async (id)=>{
            let image = [...this.state.image]
            let img_element = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                MediaTypeOptions: 'Images',
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
                });
            
                image.forEach((row,i)=>{
                
                    if (i === id)
                        row.uri = img_element.uri
                })
                this.setState({
                    image : image
                })
                
        }
        delImg = (id) =>{
            let img = this.state.image
            img.splice(id,1)
            this.setState({image: img})
            

        }
        handleOnPagination = (e,page) => {
            this.setState({activePage: page})
        }
        showAnnounce = (id) => {
            let data = this.state.Announce.filter(announce => {return announce.id === id})
            this.setState({showModal : !this.state.showModal,getAnnounce: data})
        }
        handleData = () => {
            let selectAnnounce = this.state.getAnnounce
            this.setState({title: selectAnnounce[0].title,message: selectAnnounce[0].body,showModal : false})
        }
        lastItem = () =>  this.state.activePage * 3
        firstItem = () => this.lastItem() -  3;
        numberPage = () => Math.ceil(this.state.Announce.length/3)
        render() {
           
            if (this.state.loading === false) {
                return (
                    <SafeAreaView style={styles.container}>
                            <Text>กำลังโหลดข้อมูล</Text>
                            <ActivityIndicator size="large" color="#00ff00" />
                    </SafeAreaView>
                )
            }else{
               
                return (
                    <SafeAreaView >
                    
                             <View>
                                <TextInput 
                                     style={{ width:'auto',height: 50,margin:10,padding:10,backgroundColor:'#fff' ,borderWidth: 0}}
                                    placeholder={'กรอกหัวข้อเรื่อง'}
                                    value = {this.state.title}
                                    onChangeText = {(value)=>{this.setState({title: value})}}
                                />
                                <TextInput
                                    style={{ width:'auto',height: 120,margin:10,padding:10,backgroundColor:'#fff' ,borderWidth: 0 ,justifyContent: "flex-start"}}
                                    placeholder={`กรอกเนื้อหาประกาศที่นี้ค่ะ`}
                                    onChangeText = {this.setText}
                                    value = {this.state.message}
                                    multiline={true}

                                />
                                <View style={{flexDirection:'row',justifyContent: 'flex-end',marginRight: 10}}> 
                                     <Button
                                        style ={{marginRight:5}}
                                        titleStyle={{fontSize:'14px'}}
                                        buttonStyle={{backgroundColor:'#993333',width:100}}
                                        title='ล้างข้อความ'
                                        onPress = { (e)=> this.deltext(e)}
                                    />
                                      <Button
                                        titleStyle={{fontSize:'14px'}}
                                        buttonStyle={{backgroundColor:'#009933',width:100}}
                                        title='ตกลง'
                                        onPress = { (e) => this.checkRequired()}
                                    />
                                    </View>
                            </View>
                            {this.showImg()}
                                 <View style={{flexDirection:'row',justifyContent: 'flex-end',marginRight: 10}}>
                                 <Button
                                      options = {{hidden: (Platform.OS !== 'web' ? false : true )}}
                                      titleStyle={{fontSize:'12px'}}
                                      buttonStyle={{backgroundColor:'#52af4f',width:100}}
                                      title={`เลือกรูปภาพ ${this.state.image.length === 0 ? '' : `(${this.state.image.length})` }`}
                                      onPress = { () => this.pickImage()}
                                  />
                                    </View>
                            <View style={{ height:60,margin:10,justifyContent:'center',alignItems:'center',backgroundColor:'#006699'}}>
                                  <Text style={{textAlign:'center',color:'#fff'}}> ประกาศทั้งหมด </Text>
                            </View>
                            <View style={{flexDirection:'row',justifyContent: 'center',margin: 10}}>
                                  {
                                      this.state.status === 'error' ? <Text>{this.state.Announce.toString()}</Text>
                                      :
                                     
                                      this.state.Announce && this.state.Announce.slice(this.firstItem(),this.lastItem()).map((announce,index) => {
                                          return (
                                            <Button

                                                style={{margin:5}}  
                                                key={index} 
                                                title={
                                                    width <= 500 ?  
                                                    announce.title.slice(0,12) + '...' :
                                                    announce.title.slice(0,17) + '...'
                                                } 
                                                onPress = { () => { this.showAnnounce(announce.id) }}
                                            />
                                          )
                                          
                                      })
                                  }
                            </View>
                            <View style={{display:this.state.status === 'error' ? 'none' : 'block',margin:10}}>
                                <Pagination 
                                     className="rainbow-m_auto"
                                     pages={this.numberPage()}
                                     activePage={this.state.activePage}
                                     onChange={(e,page)=>this.handleOnPagination(e,page)}
                                     variant="shaded"
                                />
                            </View>
                            <SearchBar
                             round = {true}
                            placeholder="ค้นหา..."
                            onChangeText = {this.updateSearch}
                            value={this.state.search}
                            containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,marginTop:10,marginBottom:10}}
                            inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
                            />
                            <View style={{marginBottom:10}}>
                                <ListItem bottomDivider>
                                    <ListItem.Title >
                                    <CheckBox                                   
                                    containerStyle={{backgroundColor:'#fff',borderWidth:0}}
                                    checkedColor={'purple'}
                                    uncheckedColor={'red'}
                                    title='เลือกกลุ่มทั้งหมด'
                                    style={{textAlign: 'center'}}
                                    checked = {this.state.isCheckedAll}
                                    onPress={(e) => this.handleAllChecked(e)}
                                    />
                                    </ListItem.Title>
                                </ListItem>
                            </View>
                           {
                              this.state.groups && this.state.groups.filter((g)=> (g.group_name.toLowerCase()).includes(this.state.search.toLowerCase()))
                               .map((row,i)=>{
                                   return (
                                       <SafeAreaView key={i}>
                                           <ScrollView>
                                            <ListItem key={i} bottomDivider>
                                                <ListItem.Content >
                                                 <ListItem.Title style={{flexDirection:'row',alignContent: 'flex-start'}}>
                                                    <CheckBox
                                                                key={i}
                                                                title = {  row.group_name }
                                                                checkedColor={'green'}
                                                                uncheckedColor={'red'}
                                                                containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                                                                checked = {row.checked} 
                                                                onPress = { (e) => this.handleCheckChieldElement(e,row.id)} 
                                                    />  
                                                     <Badge
                                                       
                                                        status={'success'}
                                                        value={row.member}
                                                    /> 
                                                     </ListItem.Title> 

                                                </ListItem.Content>
                                            </ListItem>
                                           </ScrollView>
                                       </SafeAreaView>
                                   )
                               })
                           }
                           <Modal isVisible={this.state.showModal}>
                                <View style={{margin:10,padding:10,justifyContent: 'center',backgroundColor: '#fff'}}>
                                      {
                                          this.state.getAnnounce && this.state.getAnnounce.map((getAnnounce,index)=>{
                                              return (
                                                  <View >
                                                       <Text style={{margin:10}}>
                                                         <Badge  status="success" value={getAnnounce.line_name} />   
                                                       </Text>
                                                       <Input
                                                         style = {{margin: 10}}
                                                         disabled={true}
                                                         value = {getAnnounce.title}
                                                       />
                                                       <Input
                                                         style = {{height: 120,margin:10}}
                                                         multiline={true}
                                                         disabled={true}
                                                         value = {getAnnounce.body}
                                                       />
                                                  </View>
                                              )
                                          })
                                      }
                                </View>
                                <View style={styless.flex_btn}>
                                      <Button
                                        buttonStyle = {{backgroundColor:'green'}}
                                        style = {styless.btn_action} 
                                        title = {'คัดลอก'}
                                        onPress = { () => {this.handleData()} }
                                      />
                                      <Button
                                         buttonStyle = {{backgroundColor:'red'}}
                                        style = {styless.btn_action} 
                                        title = {'ปิด'}
                                        onPress = { () => {this.setState({showModal: false})}}
                                      />
                                </View>  
                           </Modal>                        
                    </SafeAreaView>
                )
                   
            }
        }

}

export default Announce

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    }
  
  });