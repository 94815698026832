const fs = require('fs');
const path = require('path');
const dayjs  = require('dayjs');
module.exports = {
  saveLog
}

function saveLog (type,message) {
    // default dir = server/
    let date = new Date()
    let file = path.join('temp',`${date.getDate()}_${date.getMonth()+1}_${date.getFullYear()}.txt`)
   
    let new_date = `Date/Time : ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    let msg = `##############\n${new_date}\n${type}\n${message}\n##############\n\n`
    if (fs.existsSync(file)) {
        fs.appendFile(file,msg,(err)=>{
          if(err) throw err;
          clearLog()
          console.log('update log!!');
        });
    }else{
        fs.mkdirSync('temp',{ recursive: true });
        fs.writeFileSync(file,msg,(err)=>{
            if (err) throw err;
            clearLog()
            console.log('save log!!');
        })
    }
}

function clearLog(){
  const directoryPath = "./temp";
  const dir = fs.readdirSync(directoryPath)
  if (dir.length > 0) {
     for (let index in dir) {
        fs.stat(path.join(directoryPath, dir[index]),(err, stat) => {
          if (err) throw err;
          let dateCreateFile = dayjs(stat.birthtimeMs)
          let lastDaycheck = dayjs(dateCreateFile).add(20,'day').format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
          // console.log("lastDaycheck  ", lastDaycheck)
          if (lastDaycheck) {
              fs.unlinkSync(path.join(directoryPath, dir[index]))
              console.log("delete File : ",dir[index])
          }
        })
     }
  }
}