import axios from 'axios';
import React from 'react'
import { StyleSheet, Text, View, ActivityIndicator,TouchableHighlight,ScrollView,SafeAreaView } from 'react-native';
import { Avatar,Card,Input,Button,ListItem,Badge,SearchBar} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import  config from '../../../Api/config'
class ReportDetail extends React.Component {
    constructor(props) {
        super(props)
        console.log('report-detail',JSON.stringify(props))
        this.state = {
            loading: false,
            status : '',
            params : props.route.params.id,
            values : [],
            search : ''
        }
        console.log(`state : ${props.route.params.id}`)
        console.log(`state params : ${this.state.params}`)
    }
    DetailMembers = (group_id,id_announce,read,group_name) =>{
        console.log(`group_id : ${group_id} `)
        this.props.navigation.navigate('Members',{group_id : group_id, id : id_announce,read: read,group_name: group_name})
        
    }
    updateSearch = (Search) =>{
        this.setState({search : Search})
    }
    export = () =>{
        axios.post( config.uri.api + 'api/report/exportExcel',{params:{data : this.state.params , type : 'AllGroupMember'}})
        .then((res)=>{
            window.open(res.data.uri,'_blank')
            alert('ทำการออกรายงานเรียบร้อยแล้ว')
        }).catch((e)=>{
            console.log(e)
            alert(e.message)
        })
    }
    getReportDetail = () =>{
        axios.post(`${config.uri.api}api/report/reportDetail`,{params:{id:this.state.params}})
        .then((res)=>{
         console.log(res)
         this.setState({
             loading: true,
             status : res.data.status,
             values: res.data.data
         })
        }).catch((e)=>{
            this.setState({
                loading: true,
                values: e.message
            })
        })
    }
    componentDidMount(){
        this.getReportDetail()
    }
    componentWillUnmount(){
        console.log(`UnMount`)
    }
    render() {
         if (this.state.loading === false) {
           return ( 
                    <SafeAreaView style={styles.container}>
                            <Text>กำลังโหลดข้อมูล</Text>
                            <ActivityIndicator size="large" color="#00ff00" />
                    </SafeAreaView>
                  )
         }else if (this.state.status === 'not found')   {
            return ( 
                <SafeAreaView style={styles.container}>
                        <Text>{this.state.values}</Text>
                </SafeAreaView>
              )
         }else{
            
          return (

            <SafeAreaView >
             <View style={{margin:10,alignItems:'flex-end'}}>
                <Button style={{display:'flex', justifyContent:'flex-end',alignItems:'flex-end'}} titleStyle={{fontSize:'15px'}} buttonStyle={{backgroundColor:'black',width:180}}  disabled={this.state.values.length <= 0}  icon={<Icon name="file" size={15} color="white" style={{marginRight: 5}} />} title='ออกรายงานทุกกลุ่ม' onPress={()=> this.export()}></Button>
             </View>
            <SearchBar
            round = {true}
            placeholder= 'ค้นหากลุ่ม...'
            onChangeText = {this.updateSearch}
            value={this.state.search}
            containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,marginTop:10,marginBottom:10}}
            inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
            />
            
            {
                this.state.values.filter((row)=>row.group_name.toLowerCase().includes(this.state.search.toLowerCase())).map((row,i)=>{
                   
                           return (
                                    <SafeAreaView key={i}>
                                        <ScrollView key={i}>
                                            <TouchableHighlight key={i} onPress={() => this.DetailMembers(row.group_id, row.announce_line_id,row.cn_read,row.group_name)}>                                   
                                                <ListItem key={i} bottomDivider>
                                                <Avatar
                                                    loadingProps={<ActivityIndicator/>}
                                                    size="medium"
                                                    title='img'
                                                    source={ config.uri.api +  'images/meeting.png'}
                                                />
                                                <ListItem.Content >
                                                  <ListItem.Title>
                                                      <Text style={styles.texts}>{row.group_name}</Text>
                                                  </ListItem.Title>
                                                  <ListItem.Subtitle style={styles.template}>
                                                      <Text style={styles.texts}>มีสมาชิก</Text>
                                                      <Badge
                                                            containerStyle={{marginLeft:30}}
                                                            key={i}
                                                            value={row.membercount}
                                                            />
                                                      <Text style={styles.texts}>คน</Text>
                                                  </ListItem.Subtitle>
                                                  <ListItem.Subtitle style={styles.template}>
                                                      <Text style={styles.texts}>อ่านประกาศ</Text>
                                                      <Badge
                                                            containerStyle={{marginLeft:10}}
                                                            key={i}
                                                            value={row.member_read}
                                                            status = {row.member_read == 0 ? 'error' : 'success'}
                                                            />
                                                      <Text style={styles.texts}>คน</Text>
                                                  </ListItem.Subtitle>
                                                </ListItem.Content>
                                            </ListItem>
                                            </TouchableHighlight>  
                                        </ScrollView> 
                                    </SafeAreaView>
                           )
               })
            }
            </SafeAreaView>
          )
          
         }
    }
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    texts:{
        flex:0.3,
        marginLeft:10
    },
    template: {
        flexDirection:'row',
        margin:2
    }
   
  }); 
export default ReportDetail