import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text, ActivityIndicator,TouchableHighlight,Switch, Dimensions } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Input,Button, Badge, Image, Divider, Avatar } from 'react-native-elements'
import {
    DrawerContentScrollView,
    DrawerItem
} from '@react-navigation/drawer'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../../lib/style'
import axios from 'axios'
import config from '../../../../Api/config'
import {Picker} from '@react-native-picker/picker';
import Modal from "modal-enhanced-react-native-web";
const {width, height} = Dimensions.get('window')
const dayjs = require('dayjs')
const Detail_user =  (props) => {
    const [loading,setLoading] =  useState(false)
    const [employees,setEmployees] = useState(props.route.params.employees) 
    const [Alldepartment, setAlldepartments] = useState()
    const [AllteamLine, setAllteamLine] = useState()
    const [AllgroupPermission, setAllgroupPermission] = useState()
    useEffect(() => {
        if (props.route.params.employees === undefined){return props.navigation.navigate.pop() }
        axios.post(config.uri.api + 'api/setting/detailMasterStaff')
        .then((res) => {
            setLoading(true)
            setAlldepartments(res.data.listDepartment)
            setAllteamLine(res.data.listTeamLine)
            setAllgroupPermission(res.data.listGroupPermission)
            
        }).catch((err)=>{ console.log(err.message)})
    }, [])
    const handleStatus = (value,master_id,index) => {
        let data = employees
        let getData = data.filter(data=>{return data.master_id === master_id})
        getData[0].is_active = value === true ? 'Y' : 'N'
        let new_data = data[index] = getData
        setEmployees(new_data)
    }
    const onChangeValueDropDownTeam = (value,master_id,select_index) =>{
        let data = employees.filter((e)=>{return e.master_id === master_id})
        data[0].team_id = AllteamLine[select_index].id
        data[0].team_name = value
        setEmployees(data)
    }
    const onChangeValueDropDownDepartmment = (value,master_id,select_index) => {
        let data = employees.filter((e)=>{return e.master_id === master_id})
        data[0].department_id = Alldepartment[select_index].id
        data[0].department_name = value
        setEmployees(data)
    }
    const onChangeValueDropDownGroupPermission = (value,master_id,select_index) => {
        let data = employees.filter((e)=>{return e.master_id === master_id})
        data[0].role_id = AllgroupPermission[select_index].id
        data[0].role_name = value
        setEmployees(data)
    }
    const handleSave = () => {
        saveEditUser()
    }
    const saveEditUser = async () => {
        console.log('called saveEditUser')
        let id =  await  AsyncStorage.getItem('id_owner')
        if (id === null || id === undefined) {
            alert('ระบบล๊อคอินผิดพลาด จะทำการล็อคอินใหม่อีกครั้ง (error : id_owner is undifined)')
            return window.location.href(config.uri.web)
        }
        axios.post(config.uri.api + 'api/setting/saveEditUser',{
            params:{
                staff: employees,
                id_owner : id
            }
        }).then(res=>{return  handleResponse(res.data)})
        .catch(err=>{return alert(err.message)})
    }
    const handleResponse = async (data) => {
        alert(data.data)
        return data.status === 'error' ? alert(data.data) :  setStorage(data)
    }
    const setStorage = async (data) =>{
        await AsyncStorage.setItem('role_menu',JSON.stringify(data.screen))
        return props.navigation.pop()
    }   
    return (
        <SafeAreaView style={styles.main}>
            <View style={{margin: 5}}>
                    {
                        loading === false ? <ActivityIndicator  size={'large'} color={'green'} /> : <Icon style={{ margin:5}} name={'check-circle'} color={'green'} size={30}/>
                    }
            </View>
         
                    {
                        employees && employees.map((employee,index) => {                            
                            return (
                                <View key={index}>
                                    <View style={styles.row}>
                                    <Text>สถานะ {employee.is_active === 'Y' ? 'เปิดใช้งาน'  : 'ปิดใช้งาน'} </Text>
                                    <Switch
                                        style={{marginLeft:10,marginRight:10}}
                                        trackColor={{ false: "red", true: "#64baaa" }}
                                        thumbColor={ employee.is_active === true ? "#f4f3f4" : "#f4f3f4"}
                                        ios_backgroundColor="#3e3e3e"
                                        onValueChange={(value)=>{handleStatus(value,employee.master_id,index)}} 
                                        value={ employee.is_active === 'Y' ? true : false}
                                    />
                                    </View>
                                    <View style={{margin: 10}}>
                                    <Text>วันที่สร้าง   : {dayjs(employee.system_date).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                    <Text>แก้ไขล่าสุด  : {dayjs(employee.update_date).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                    <Text>แก้ไขโดย   : {employee.update_by_name }</Text>
                                    </View>
                                    <Input
                                        leftIcon={
                                            <Icon 
                                                name={'user'}
                                                color={'#656566'}
                                                size={30}
                                            />
                                        }
                                        disabled={true}
                                        label={'รหัสผู้ใช้งาน'} 
                                        value={employee.staff_id}
                                     />
                                    <Input
                                        leftIcon={
                                            <Icon 
                                                name={'at'}
                                                color={'#656566'}
                                                size={30}
                                            />
                                        }
                                        disabled={true}
                                        label={'อีเมล์ไลน์'} 
                                        value={employee.mail_line}
                                    />
                                    <View style={{flexDirection: 'row',margin: 5}}>
                                        <View >
                                             <Text style={{ margin:5,padding:5}}>ทีม Line : </Text>
                                             <Text style={{ margin:5,padding:5}}>แผนก : </Text>
                                             <Text style={{ margin:5,padding:5}}>กลุ่มสิทธิ์ : </Text>
                                        </View >
                                        <View >
                                        <Picker
                                            style={{
                                                width: width <= 500 ? 'auto' : '100%',
                                                margin:5,
                                                padding:5,
                                            }}
                                            selectedValue = {employee.team_name}
                                            onValueChange = {(value,valueIndex)=> {onChangeValueDropDownTeam(value,employee.master_id,valueIndex)}}
                                        >
                                            {
                                                AllteamLine && AllteamLine.map((team,team_index) => {
                                                    return (      
                                                        <Picker.Item key={team_index} label={team.team_name} value={team.team_name}  />
                                                    )
                                                })
                                            }
                                        </Picker>
                                        <Picker
                                            disabled = {true}
                                            style={{
                                                width: width <= 500 ? 'auto' : '100%',
                                                margin:5,
                                                padding:5,
                                            }}
                                            selectedValue={employee.department_name}
                                            onValueChange = {(value,valueIndex) => {onChangeValueDropDownDepartmment(value, employee.master_id,valueIndex)}}
                                        >
                                        {
                                            Alldepartment && Alldepartment.map((department,department_index) => {
                                                return (       
                                                    <Picker.Item key={department_index} label={department.name} value={department.name}  />
                                                )
                                            })
                                        }
                                        </Picker>
                                        <Picker
                                            style={{
                                                width: width <= 500 ? 'auto' : '100%',
                                                margin:5,
                                                padding:5,
                                            }}
                                            selectedValue={employee.role_name}
                                            onValueChange = {(value,valueIndex) => {onChangeValueDropDownGroupPermission(value, employee.master_id,valueIndex)}}
                                        >
                                        {
                                            AllgroupPermission && AllgroupPermission.map((permission,permission_index) => {
                                                return (       
                                                    <Picker.Item key={permission_index} label={permission.role_name} value={permission.role_name}  />
                                                )
                                            })
                                        }
                                        </Picker>
                                        </View>
                                    </View>
                                </View>
                            )
                        })
                    }
                    <View style={styles.flex_btn}>
                          <Button
                            disabled={loading === false}
                            style={styles.btn_action}
                            icon={
                                <Icon
                                    style={styles.icon} 
                                    name={'save'}
                                    color={'#fff'}
                                    size={18}
                                />
                            }
                            title={'บันทึก'}
                            onPress={()=>{handleSave()}}
                          />
                    </View>
        </SafeAreaView>
    )
}




export default Detail_user