import axios from 'axios';
import React from 'react'
import { StyleSheet, Text, View, ActivityIndicator,TouchableHighlight,ScrollView,SectionList,SafeAreaView  } from 'react-native';
import { Avatar,Card,Input,Button,ListItem,Badge,SearchBar,Image} from 'react-native-elements'
import  config from '../../../Api/config'
import Icon from 'react-native-vector-icons/FontAwesome'
import { Pagination } from 'react-rainbow-components';
class Member extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                loading: false,
                group_id : props.route.params.group_id,
                id  : props.route.params.id,
                status : '',
                members : [],
                error : '',
                search : '',
                activePage: 1,
            }
        }
        handleOnChange = (event, page) => {
            this.setState({ activePage: page });
        }
        updateSearch = (Search) =>{
            this.setState({search : Search})
        }
        export = () =>{
            console.log('start..Export')
            axios.post( config.uri.api + 'api/report/exportExcel',{params:{data: this.state.members,type : 'GroupMember'}})
            .then(res=>{
                window.open(res.data.uri,'_blank')
            }).catch(err=>{
                console.log(err.message)
                alert(err.message)
            })

        }
        getMembers = async () => {
          await  axios.post(`${config.uri.api}api/report/listStaff`,{params:{group_id : this.state.group_id, id : this.state.id}})
            .then((res) => {
                this.setState({
                    loading: true,
                    status : res.data.status,
                    members : res.data.data
                })
            }).catch((err) => {
                console.error(err.message)
                this.setState({
                    loading: true,
                    error : err.message
                })
            })
            
        }

         componentDidMount(){
            console.log(`DidMount view member`)
            this.getMembers()
        }

        componentWillUnmount(){
            console.log(`Will Unmount view member`)
        }
        componentDidUpdate(prevProps, prevState){
            if (prevState.members.length === this.state.members.length){
                return false
            }
        }
        render() {
            if (this.state.loading === false){
                return (
                    <View style={styles.container}>
                        <Text>กำลังโหลดข้อมูล</Text>
                     <ActivityIndicator size="large" color="#00ff00" />
                     </View>
                  
                ) 
            }else if (this.state.status === "error"){
                return (
                    <View style={styles.container}><Text>{this.state.members} {this.state.error} </Text></View>
                )
            }else if (this.state.members.length <= 0) {
                return (
                    <SafeAreaView style={styles.container}>
                    <Text style={{fontWeight: 'bold'}}> ⚠️ ไม่พบสมาชิกในกลุ่มนี้  </Text>
                  </SafeAreaView>
                ) 
            }else{
                const lastItem = this.state.activePage * 30;
                const firstItem = lastItem -  30;  
                const page  = Math.ceil(this.state.members.length/30)
                // console.log("member : ",this.state.members)
                return (
                    <SafeAreaView>
                         <View style={{margin:10}}>
                            <Button titleStyle={{fontSize:'15px'}} style={{display:'flex', justifyContent:'flex-end',alignItems:'flex-end'}} buttonStyle={{backgroundColor:'black',width:200}}  disabled={this.state.members.length <= 0}  icon={<Icon name="file" size={15} color="white" style={{marginRight: 5}} />} title='ออกรายงานกลุ่มนี้' onPress={()=> this.export()}></Button>
                        </View>
                        <SearchBar
                             round = {true}
                            placeholder="ค้นหาสมาชิก..."
                            onChangeText = {this.updateSearch}
                            value={this.state.search}
                            containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,marginTop:10,marginBottom:10}}
                            inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
                        />
                         <View style={{display: this.state.members.length <= 0 || this.state.loading === false ? 'none' : 'block',marginTop:2,marginBottom:2}}>
                                <Pagination
                                className="rainbow-m_auto"
                                pages={page}
                                activePage={this.state.activePage}
                                onChange={this.handleOnChange}
                                variant="shaded"
                                />
                         </View>
                        {  
                               this.state.members  &&  this.state.members.filter(member => member.line_name && member.line_name.includes( this.state.search || ""))
                                .slice(firstItem,lastItem).map((row,i)=>{
                                   
                                        let new_format = row.system_date.split(/[- :]/)                       
                                        let new_date = new Date( new_format[0], new_format[1]-1, new_format[2], new_format[3], new_format[4], new_format[5]);
                                        var date = new Date(new_date) 
                                    
                                   
                                    return (
                                        <SafeAreaView  >
                                            <ScrollView >
                                             
                                                <ListItem key={i} bottomDivider> 
                                                <Avatar
                                                    loadingProps={<ActivityIndicator/>}
                                                    size="large"
                                                    title='รูป'
                                                    source={{uri : config.uri.api + row.picture}}
                                                    />                                              
                                                    <ListItem.Content key={i}>
                                                   
                                                        <ListItem.Title style={{fontWeight: 'bold',fontsize:'1rem'}}>
                                                            <Text >
                                                                {row.line_name}
                                                            </Text>
                                                            </ListItem.Title>
                                                            <ListItem.Subtitle>
                                                             <Text  style={{color:'#009966'}}>
                                                            {
                                                             row.status === 'Wait' || row.status === null ? '-' : `อ่านเมื่อ ${row.system_date}`
                                                             }</Text>
                                                            </ListItem.Subtitle>
                                                    </ListItem.Content>
                                                    <ListItem.Subtitle>
                                                        <Badge
                                                            value={row.status === 'Wait'  || row.status === null ? 'ยังไม่อ่าน' : 'อ่านแล้ว'}
                                                            status={row.status === 'Wait'  || row.status === null ? 'error' : 'success'}
                                                            badgeStyle={{padding: 10}}

                                                        />
                                                    </ListItem.Subtitle>
                                                </ListItem>                                  
                                            </ScrollView>
                                         </SafeAreaView>
                                    )
                                })

                        }
                        <View style={{display: this.state.members.length <= 0 || this.state.loading === false ? 'none' : 'block',marginTop:2,marginBottom:2}}>
                            <Pagination
                            className="rainbow-m_auto"
                            pages={page}
                            activePage={this.state.activePage}
                            onChange={this.handleOnChange}
                            variant="shaded"
                            />
                        </View>
                    </SafeAreaView>
                    
                )

            }
          
        }
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }); 
export default Member