import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text, ActivityIndicator,TouchableHighlight,Dimensions } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Input,Button, Badge, Image, Divider, Avatar,CheckBox} from 'react-native-elements'
import {
    DrawerContentScrollView,
    DrawerItem
} from '@react-navigation/drawer'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../../lib/style'
import axios from 'axios'
import config from '../../../../Api/config'
const dayjs = require('dayjs')
const {width , height } = Dimensions.get('window')

const CreateGroupPermissions = (props) => {
    const [loading,setLoading] = useState(false)
    const [screen,setScreen] = useState()
    const [checkAll,setCheckAll] = useState(false)
    const [header,setHeader] = useState([{role_name: '',role_description: ''}])
    const [data,setData] = useState([])
    useEffect(() => {
        getAllScreen()
    },[])
    const getAllScreen = () => {
        axios.post(config.uri.api + 'api/setting/getAllScreenSave')
        .then(res => {
            setScreen(res.data.data)
            setLoading(true)
        }).catch(err => {
            console.log(err.message)
        })
    }
    const handleChecked = (id) => {
        let data = screen
        data = data.map(item => {
            if (item.id === id)
                item.checked = !item.checked
            return item
        })
        let checkAll = data.filter(item => {return item.checked === true})
        let isTrue =  checkAll.length >= data.length ? true : false
        setScreen(data)
        setCheckAll(isTrue)
    }
    const handleCheckAll = () => {
        let data = screen
        data = data.map(item=>{
            item.checked = !checkAll
            return item
        })
        setScreen(data)
        setCheckAll(!checkAll)
    }
    const handleHeader = (e,id) => {
        let data = header
        data = data.map((item =>{
            if (id === 1) item.role_name =  e.nativeEvent.text.trim()
            if (id === 2) item.role_description =  e.nativeEvent.text.trim()
            return item
        }))
        setHeader(data)
    }
    const save = () => {
        let checked = screen.filter(screen => { return screen.checked == false })
        if (header[0].role_name.length <= 0) return  alert('โปรดระบุชื่อกลุ่มสิทธิ์')
        if (checked.length >= screen.length) return  alert('โปรดกำหนดสิทธิ์เมนูอย่างน้อย 1 เมนู')
        axios.post(config.uri.api + 'api/setting/saveGroupPermission',{params:{
           header: header,
           screen: screen 
        }}).then(res => {
            handleResponse(res.data)
        }).catch(err => {
            console.log(err.message)
        })
    }
    const handleResponse = (data) => {
        if (data.status === 'error') return alert(data.data)
        alert(data.data)
        return props.navigation.pop()
    }
    if (!loading) {
        <SafeAreaView style={styles.container}>
            <Text>กำลังโหลดเมนู ...</Text>
            <ActivityIndicator size={'large'} color={'green'}/>
        </SafeAreaView>
    }
    return (
        <SafeAreaView style={styles.main}>
            {
                header && header.map((item,index) => {
                    return (
                            <View key={index} style={{margin:10}}>
                                <Input
                                   
                                    style={{margin:5,padding:5}}
                                    label={'ระบุชื่อกลุ่มสิทธิ์'}
                                    value={item.role_name}
                                    onChange = {(e)=> {handleHeader(e,1)}}
                                />
                                <Input
                                    
                                   style={{margin:5}}
                                   label={'ระบุรายละเอียด'}
                                   value={item.role_description}
                                   onChange = {(e)=> {handleHeader(e,2)}}
                                />
                            </View>  
                    )
                })
            }
             <View style={{justifyContent:'center',alignItems:'center',backgroundColor:'#9999FF'}}>
                     <Text style={{margin:5,fontSize:20,color:'#fff'}}>กำหนดสิทธิ์เมนู</Text>
            </View>
            <View>
                <CheckBox title={'เลือกทั้งหมด'} checked={checkAll} onPress={() => {handleCheckAll()}} />
            </View>
            {
                screen && screen.map((screen,index) => {
                    return (
                        <CheckBox
                            key={index} 
                            title={screen.screen_name}
                            checked={screen.checked}
                            checkedColor={'green'}
                            onPress={() => {handleChecked(screen.id)}}
                        />
                    )
                })
            }
            <View style={styles.flex_btn}>
               <Button
                    style={{margin:10}}
                    icon = {
                        <Icon
                            style={styles.icon} 
                            name = {'save'}
                            color = {'#fff'}
                            size={18}
                        />
                    } 
                    title={'บันทึก'}
                    onPress={() => {save()}}
               />
            </View>
        </SafeAreaView>
    )
}
export default CreateGroupPermissions