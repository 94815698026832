import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text } from 'react-native'
import styles from '../../lib/style'
 const Home = (props)=>{
    const [data, setData] = useState()
    // useEffect(() => {
        
    // },[])
    // getMasterStaffLine = () =>{
    //     axios.get('')
    // }
    return (
        <View style={styles.container}><Text>ยินดีต้อนรับ</Text></View>
    )
}

export default Home