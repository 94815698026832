import { StatusBar } from 'expo-status-bar';
import React,{useState,useEffect,useRef} from 'react';
import { AppState,StyleSheet, Text, View, ActivityIndicator,TouchableHighlight,ScrollView, SafeAreaView,TextInput,Dimensions} from 'react-native';
import {Input,ListItem,SearchBar,Badge, Tooltip,Button} from 'react-native-elements'
// import { NavigationContainer ,DrawerActions   } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/FontAwesome'
import '@expo/match-media'
import  config from '../../../Api/config'
// import styless from '../../lib/style'
// import Modal from "modal-enhanced-react-native-web";
const axios = require('axios')
const dayjs = require('dayjs')
const window = Dimensions.get('window')
const screen = Dimensions.get('screen')
import { Pagination } from 'react-rainbow-components';
const Report = ({navigation}) => {
    const [dimensions,setDimensions] = useState({window,screen});
    const [report,setReport] = useState([]);
    const [loading,setLoading] = useState(false);
    const [search,setSearch] = useState('');
    const [msg,setMsg] = useState([]);
    const [modal,setModal] = useState(false);
    const [activePage,setActivePage] = useState(1);
    const [status,setStatus] = useState(true);
    // const [smallLoading,setSmallLoading] = useState(false)
    

    useEffect( () => {
        // const element = ref.current;
        navigation.addListener('focus',() => {
            getData()
        })
        Dimensions.addEventListener('change', onchangeScreen)
    },[])
    const getReport = () => {
        axios
        .post( config.uri.api + 'api/report/report',{data: null},{timeout: 120000})
        .then((res) => {
            // console.log("res Data : ",res.data.data)
            setLoading(true);
            setReport(res.data.data);
            setMsg(res.data.message);         
        }).catch((err) => {
            console.log("load error report : ",err.message)
            setLoading(true);
            setStatus(false);
            setReport([]);
            setMsg(err.message);
        })
    }
    const  updateSearch = (search) => {
        if (activePage > 1) { setActivePage(1)}
        setSearch(search);
    }
    const  handleOnChange = (event, page) => {
        setActivePage(page);
    }
    const onchangeScreen = ({window,screen}) => {
        setDimensions({window,screen})
    }
    const  Delete = (id) =>{
        axios.post(`${config.uri.api}api/report/deleteAnnounceLine`,{params:{form_id: id}},{timeout:120000})
        .then(res=>{
            if (res.data.status !== 'error'){
                alert(res.data.data)
                getReport()
            }else{
                alert(res.data.data)
            }
        }).catch(err=>{
            alert("ไม่สามารถลบประกาศได้ โปรดทำรายการใหม่อีกครั้ง")
            console.log("err delete : ",err.message)
        })
    }
    const  handleAlert = (id,msg) =>{
        alert(msg)
    }
    const handleClick = (id,message) => {
        // console.log("navigation : ",navigation)
        navigation.navigate('reportDetails',{id : id,text: message })
    }
    const getData = () => {
        setLoading(false);
        getReport();
    }
    const DataError =  () => {
        return (
            <SafeAreaView style={styles.container}>
            <Text>ไม่สามารถโหลดข้อมูลประกาศได้...</Text>
            <Button
                style={{marginLeft:2,marginTop:5}}
                titleStyle={{fontSize:12,textAlign:'center'}} 
                title={'ลองอีกครั้ง'}
                onPress ={() => getData }
            />
            </SafeAreaView>
        )
    }
    // console.log("data : ",report)
    // console.log("loading : ",loading)
    if (!loading) {
        return (
            <SafeAreaView style={styles.container}>
                <Text>กำลังโหลดข้อมูล</Text>
                <ActivityIndicator size="large" color="#00ff00" />
            </SafeAreaView>   
        )
        
    }else if (!status) {
        DataError()
    }
    else if (report.length <= 0){
        return (
            <SafeAreaView style={styles.container}>
                    <Text style={{fontWeight: 'bold'}}> ⚠️ ไม่มีข้อมูลการประกาศ</Text>
                    <Button
                        style={{marginLeft:2,marginTop:5}}
                        titleStyle={{fontSize:12,textAlign:'center'}} 
                        title={'ลองอีกครั้ง'}
                        onPress ={() => getData }
                    />
            </SafeAreaView>
        )
        
    }else{

        if (report.length)  {
            const lastItem =  activePage * 30;
            const firstItem = lastItem -  30;  
            const page  = Math.ceil(report.length/30)
            return (
                <SafeAreaView>
                    
                    <SearchBar
                    round = {true}
                    placeholder= 'ค้นหารหัสประกาศ...'
                    onChangeText = {updateSearch}
                    value={search}
                    containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,marginTop:10,marginBottom:10}}
                    inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
                    />
                    <View style={{display: report.length <= 0 || loading === false ? 'none' : 'block',marginTop:2,marginBottom:2}}>
                        <Pagination
                                    className="rainbow-m_auto"
                                    pages={page}
                                    activePage={activePage}
                                    onChange={handleOnChange}
                                    variant="shaded"
                    />
                    </View>
                    {
                        
                        report.length > 0 &&  report.filter(i => 
                            
                                    i.orderMessage.toLowerCase().includes(search.toLowerCase())
                                || i.group_count.toString().includes(search)
                                || i.Send_at.includes(search)
                                || i.message.includes(search)
                                || i.member_read.toString().includes(search)
                        
                        ).slice(firstItem,lastItem).map((row,i)=>{
                            let date = dayjs(new Date(row.Send_at)).format('DD/MM/YYYY HH:mm:ss')
                            let text = row.message.length <= 0 ? '-' : row.message
                            let formatText = dimensions.window.width <= 500 ? text.substr(0,50) : text
                            return (
                                <SafeAreaView key={i}>
                                    
                                    <ScrollView >
                                            
                                            <TouchableHighlight key={i} onPress={()=>{handleClick(row.id,row.orderMessage)}}>  
                                            <ListItem key={i} bottomDivider>                                            
                                                    <ListItem.Content>
                                                        <View  style={{flexDirection:'row',margin:5,textAlign:'left',padding:5}}>
                                                            <View >
                                                                <ListItem.Title style={styles.headerText}>
                                                                    <Text style={styles.texts}>รหัสประกาศ</Text>
                                                                </ListItem.Title>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text  style={styles.texts}>ข้อความ</Text>
                                                                </ListItem.Subtitle>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text style={styles.texts}>ประกาศโดย</Text>
                                                                </ListItem.Subtitle>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text style={styles.texts}>แผนก</Text>
                                                                </ListItem.Subtitle>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text style={styles.texts}>วันที่ประกาศ</Text>
                                                                </ListItem.Subtitle>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text style={styles.texts}>ประกาศทั้งหมด</Text>
                                                                </ListItem.Subtitle>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text style={styles.texts}>สมาชิกทั้งหมด</Text>
                                                                </ListItem.Subtitle>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text style={styles.texts}>จำนวนคนอ่าน</Text>
                                                                </ListItem.Subtitle>
    
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Button
                                                                        style={{marginLeft:2,marginTop:5}}
                                                                        buttonStyle={{backgroundColor:'red'}}
                                                                        titleStyle={{fontSize:12,textAlign:'center'}} 
                                                                        title={'ลบประกาศ'}
                                                                        onPress ={() =>{Delete(row.id)}}
                                                                    />
                                                                </ListItem.Subtitle>
                                                            </View>
    
                                                            <View style={{marginLeft:15}}>
                                                                <ListItem.Title style={styles.headerText}>
                                                                    <Text>{row.orderMessage}</Text>
                                                                </ListItem.Title>
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Text>{formatText}</Text>
                                                                    {
                                                                        formatText.length <= 0 ? '' :
                                                                        <Badge
                                                                        onPress ={()=>{handleAlert(row.id, row.fulltext)}}
                                                                        key={i}
                                                                        value='เพิ่มเติม'
                                                                        badgeStyle={{backgroundColor:'#333399'}}
                                                                        >
                                                                        </Badge>
                                                                    }
                                                                </ListItem.Subtitle>
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                        <Badge 
                                                                        key={i}
                                                                        value={row.line_name}
                                                                        badgeStyle={{backgroundColor:'#336699'}}
                                                                    />
                                                                </ListItem.Subtitle>
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                    <Badge 
                                                                            key={i}
                                                                            value={row.department_name}
                                                                            badgeStyle={{backgroundColor:'#3399CC'}}
                                                                            
                                                                        />
                                                                </ListItem.Subtitle>
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                        <Badge 
                                                                        key={i}
                                                                        value={date}
                                                                        badgeStyle={{backgroundColor:'#3399FF'}}
                                                                    />
                                                                </ListItem.Subtitle>
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                        <Badge 
                                                                        key={i}
                                                                        value={row.group_count}
                                                                        badgeStyle={{backgroundColor:'#33CCCC'}}
                                                                    />
                                                                    <Text style={styles.lastText}>กลุ่ม</Text>
                                                                </ListItem.Subtitle>
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                        <Badge 
                                                                        key={i}
                                                                        value={row.member_count}
                                                                        badgeStyle={{backgroundColor:'#33CCFF'}}
                                                                    />
                                                                        <Text style={styles.lastText}>คน</Text>
                                                                </ListItem.Subtitle>
                                                                <ListItem.Subtitle style={styles.descText}>
                                                                        <Badge 
                                                                        key={i}
                                                                        value={row.member_read}
                                                                        badgeStyle={{backgroundColor:row.member_read <= 0 ? 'red' : '#3CB371'}}
                                                                    />
                                                                    <Text style={styles.lastText}>คน</Text>
                                                                </ListItem.Subtitle>
                                                            </View>
                                                        </View>
                                                    </ListItem.Content>
                                                    <Icon
                                                key={i}
                                                name='angle-right'
                                                size={20}
                                                color='rgb(171,57,133)'
                                            ></Icon>
                                            </ListItem>
                                            </TouchableHighlight>
                                            </ScrollView>
                                                        
                                                                                
                                </SafeAreaView>
                            )             
                        })
    
                    }
                    <View style={{display: report.length <= 0 || loading === false ? 'none' : 'block',marginTop:2,marginBottom:2}}>
                        <Pagination
                        className="rainbow-m_auto"
                        pages={page}
                        activePage={activePage}
                        onChange={handleOnChange}
                        variant="shaded"
                    />
                    </View>
                </SafeAreaView>
                        
            )
        }else  {
            DataError()
        }

        
        
    }   

}


// class Report extends React.Component {
   
//     constructor(props) {
//         super(props);
//         this.state = {
//             dimensions:{
//                 window,
//                 screen
//             },
//             report : [],
//             loading : false,
//             search : '',
//             msg : [],
//             modal: false,
//             activePage: 1,
            
//         }
//         this.handleClick = this.handleClick.bind(this);
       
//     }
//      handleOnChange = (event, page) => {
//         this.setState({ activePage: page });
//     }
//     handleAlert = (id,msg) =>{
//        alert(msg)
//     }
//     updateSearch = (search) => {
//         const { activePage } = this.state
//         if (activePage > 1) { this.setState({ activePage: 1 }) }
//         this.setState({
//             search : search,
//         });
//     }
//     handleClick(id,message) {
        
//         this.props.navigation.navigate('reportDetails',{id : id,text: message })
//     }
   
//    getReport =  () => {
//        axios.post( config.uri.api + 'api/report/report')
//        .then((res) => {
//         this.setState({
//             loading: true,
//             report: res.data.data,
//             msg : res.data.message
//         })
//        }).catch((err) => {
//             console.log(err.message)
//             this.setState({
//                 loading: true,
//                 report : err.message
//             })
//        })
//    }
//    onchangeScreen = ({window,screen}) => {
//         this.setState({dimensions:{window,screen}})
//    }
//    componentDidMount(){
//     Dimensions.addEventListener('change', this.onchangeScreen)
//     this.getReport()
    
//    }
//    componentWillUnmount(){
//        console.log("Will Unmount")
//        Dimensions.removeEventListener('change', this.onchangeScreen)
//    }
//    componentDidUpdate(prevProps, prevState){
//        if (prevState.report.length === this.state.report.length){
//            return false
//        }
//    }
//    delete = (id) =>{
//        axios.post(`${config.uri.api}api/report/deleteAnnounceLine`,{params:{form_id: id}})
//        .then(res=>{
       
//         if (res.data.status !== 'error'){
//             alert(res.data.data)
//             this.getReport()
//         }else{
//             alert(res.data.data)
//         }
//        }).catch(err=>{
//         alert(err.message)
//         console.log(err.message)
//        })
//    }
//    ShowModal = ()=>{
//        this.setState({modal:!this.state.modal})
//    }
//    render() {
//        if (this.state.loading === false){
//         return (
//             <SafeAreaView style={styles.container}>
//             <Text>กำลังโหลดข้อมูล</Text>
//             <ActivityIndicator size="large" color="#00ff00" />
//           </SafeAreaView>
//         )
//        }else if (this.state.report.length <= 0){
//         return (
//             <SafeAreaView style={styles.container}>
//             <Text style={{fontWeight: 'bold'}}> ⚠️ ไม่มีข้อมูลการประกาศ {this.state.report} </Text>
//           </SafeAreaView>
//         ) 
//        }else{
//         // const { activePage } = this.state.activePage;
//         const lastItem = this.state.activePage * 30;
//         const firstItem = lastItem -  30;  
//         const page  = Math.ceil(this.state.report.length/30)
//         // console.log("report : ",page,this.state.report)
//         // console.log("report : ", firstItem, lastItem)
//         return (
//             <SafeAreaView>
              
//                 <SearchBar
//                 round = {true}
//                 placeholder= 'ค้นหารหัสประกาศ...'
//                 onChangeText = {this.updateSearch}
//                 value={search}
//                 containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,marginTop:10,marginBottom:10}}
//                 inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
//                 />
//                 <View style={{display: this.state.report.length <= 0 || this.state.loading === false ? 'none' : 'block',marginTop:2,marginBottom:2}}>
//                     <Pagination
//                                 className="rainbow-m_auto"
//                                 pages={page}
//                                 activePage={this.state.activePage}
//                                 onChange={this.handleOnChange}
//                                 variant="shaded"
//                 />
//                 </View>
//                 {
                    
//                    this.state.report &&  this.state.report.filter(i => 
                        
//                              i.orderMessage.toLowerCase().includes(search.toLowerCase())
//                           || i.group_count.toString().includes(search)
//                           || i.Send_at.includes(search)
//                           || i.message.includes(search)
//                           || i.member_read.toString().includes(search)
                    
//                     ).slice(firstItem,lastItem).map((row,i)=>{
//                         let date = dayjs(new Date(row.Send_at)).format('DD/MM/YYYY HH:mm:ss')
//                         let text = row.message.length <= 0 ? '-' : row.message
//                         let formatText = this.state.dimensions.window.width <= 500 ? text.substr(0,50) : text
//                         return (
//                             <SafeAreaView key={i}>
                               
//                                 <ScrollView >
                                        
//                                         <TouchableHighlight key={i} onPress={()=>{this.handleClick(row.id,row.orderMessage)}}>  
//                                         <ListItem key={i} bottomDivider>                                            
//                                                 <ListItem.Content>
//                                                     <View  style={{flexDirection:'row',margin:5,textAlign:'left',padding:5}}>
//                                                         <View >
//                                                             <ListItem.Title style={styles.headerText}>
//                                                                 <Text style={styles.texts}>รหัสประกาศ</Text>
//                                                             </ListItem.Title>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text  style={styles.texts}>ข้อความ</Text>
//                                                             </ListItem.Subtitle>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text style={styles.texts}>ประกาศโดย</Text>
//                                                             </ListItem.Subtitle>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text style={styles.texts}>แผนก</Text>
//                                                             </ListItem.Subtitle>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text style={styles.texts}>วันที่ประกาศ</Text>
//                                                             </ListItem.Subtitle>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text style={styles.texts}>ประกาศทั้งหมด</Text>
//                                                             </ListItem.Subtitle>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text style={styles.texts}>สมาชิกทั้งหมด</Text>
//                                                             </ListItem.Subtitle>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text style={styles.texts}>จำนวนคนอ่าน</Text>
//                                                             </ListItem.Subtitle>

//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Button
//                                                                     style={{marginLeft:2,marginTop:5}}
//                                                                     buttonStyle={{backgroundColor:'red'}}
//                                                                     titleStyle={{fontSize:12,textAlign:'center'}} 
//                                                                     title={'ลบประกาศ'}
//                                                                     onPress ={() =>{this.delete(row.id)}}
//                                                                 />
//                                                             </ListItem.Subtitle>
//                                                         </View>

//                                                         <View style={{marginLeft:15}}>
//                                                             <ListItem.Title style={styles.headerText}>
//                                                                 <Text>{row.orderMessage}</Text>
//                                                             </ListItem.Title>
//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Text>{formatText}</Text>
//                                                                 {
//                                                                     formatText.length <= 0 ? '' :
//                                                                     <Badge
//                                                                     onPress ={()=>{this.handleAlert(row.id, row.fulltext)}}
//                                                                     key={i}
//                                                                     value='เพิ่มเติม'
//                                                                     badgeStyle={{backgroundColor:'#333399'}}
//                                                                     >
//                                                                     </Badge>
//                                                                 }
//                                                             </ListItem.Subtitle>
//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                  <Badge 
//                                                                     key={i}
//                                                                     value={row.line_name}
//                                                                     badgeStyle={{backgroundColor:'#336699'}}
//                                                                 />
//                                                             </ListItem.Subtitle>
//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                 <Badge 
//                                                                      key={i}
//                                                                      value={row.department_name}
//                                                                      badgeStyle={{backgroundColor:'#3399CC'}}
                                                                     
//                                                                  />
//                                                             </ListItem.Subtitle>
//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                  <Badge 
//                                                                     key={i}
//                                                                     value={date}
//                                                                     badgeStyle={{backgroundColor:'#3399FF'}}
//                                                                 />
//                                                             </ListItem.Subtitle>
//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                  <Badge 
//                                                                     key={i}
//                                                                     value={row.group_count}
//                                                                     badgeStyle={{backgroundColor:'#33CCCC'}}
//                                                                 />
//                                                                 <Text style={styles.lastText}>กลุ่ม</Text>
//                                                             </ListItem.Subtitle>
//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                  <Badge 
//                                                                     key={i}
//                                                                     value={row.member_count}
//                                                                     badgeStyle={{backgroundColor:'#33CCFF'}}
//                                                                 />
//                                                                  <Text style={styles.lastText}>คน</Text>
//                                                             </ListItem.Subtitle>
//                                                             <ListItem.Subtitle style={styles.descText}>
//                                                                  <Badge 
//                                                                     key={i}
//                                                                     value={row.member_read}
//                                                                     badgeStyle={{backgroundColor:row.member_read <= 0 ? 'red' : '#3CB371'}}
//                                                                 />
//                                                                 <Text style={styles.lastText}>คน</Text>
//                                                             </ListItem.Subtitle>
//                                                         </View>
//                                                     </View>
//                                                 </ListItem.Content>
//                                                 <Icon
//                                             key={i}
//                                             name='angle-right'
//                                             size={20}
//                                             color='rgb(171,57,133)'
//                                         ></Icon>
//                                         </ListItem>
//                                         </TouchableHighlight>
//                                         </ScrollView>
                                                  
                                                                           
//                             </SafeAreaView>
//                         )             
//                     }) ||   <SafeAreaView style={styles.container}>
//                     <Text style={{fontWeight: 'bold'}}> ⚠️ ไม่สามารถโหลดข้อมูลประกาศได้  </Text>
//                   </SafeAreaView>

//                 }
//                 <View style={{display: this.state.report.length <= 0 || this.state.loading === false ? 'none' : 'block',marginTop:2,marginBottom:2}}>
//                     <Pagination
//                     className="rainbow-m_auto"
//                     pages={page}
//                     activePage={this.state.activePage}
//                     onChange={this.handleOnChange}
//                     variant="shaded"
//                 />
//                 </View>
//             </SafeAreaView>
             
//         )
//        }  
//    }
// }




export default Report
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    texts:{
       margin:2
    },
    descText:{
        margin:2
    },
    lastText:{
        marginLeft:5
    },
    template: {
        flexDirection:'row',
        margin:2
    },
    headerText: {
        fontWeight:'bold',
        marginBottom:5
    }
  }); 


  