module.exports = {
    line_liff : {
        report_group : '1655718255-ypXeO8Q5',
        announce_line : '1655718255-zKWQkaxN',
        save_form: '1655718255-2QLbz4og',
        line_login: '1655718255-MJWK8meG'
    },
    // line_liff : {
    //     report_group : '1655718255-AmKYzyQb',
    //     announce_line : '1655718255-AmKYzyQb',
    //     save_form: '1655718255-AmKYzyQb',
    //     line_login: '1655718255-AmKYzyQb'
    // },
}