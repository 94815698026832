const Ctrl = {}
const model = require('../../model/handledb')
const line = require('../../lib/line')
const fs = require('fs');
const path = require('path');
const l  = require('../../lib/log');
Ctrl.listMasterStaff = async  (req,res) => {
    try {
        // let limit = req.body.params.limit
        // console.log(limit)
        let staff = await model.getlistMasterStaffLine(req.db,{})
        let Allstaff = await model.getMasterLineStaff(req.db,{})
        // console.log('Allstaff : ',Allstaff)
        staff = staff.map(row=>{
            let getstaff = Allstaff.filter(all=>{return all.id === row.update_by}) || ''
            console.log('get Staff = ',getstaff)
            row.update_by_name = (getstaff.length <= 0 ? '-' : getstaff[0].staff_line_name)
            return row
        })
        // console.log('staff : ',staff)
        return res.send({status: 'success',data: staff})
    } catch (e) {
        console.log(e.message)
        return res.send({status:'error',data: e.message})
    }
}
Ctrl.detailMasterStaff = async (req, res) => {
    try {
        console.log('req detailMasterStaff => ',req.body)
        let Alldepartment = await model.getDepartmentForm(req.db,{})
        let AllteamLine = await model.getTeamLine(req.db,{})
        let AllGroupPermission = await model.getMasterRoleScreen(req.db,{})
        AllteamLine = [{id:0,team_name:'ยังไม่ได้กำหนดทีม Line'},...AllteamLine] || ''
        
        // console.log('Alldepartment',Alldepartment)
        // console.log('team',AllteamLine)
        return res.send({status: 'success',listDepartment: Alldepartment,listTeamLine: AllteamLine,listGroupPermission: AllGroupPermission})
    } catch (e) {
        console.log(e.message)
        return res.send({status:'error',data: e.message})    
    }
}
Ctrl.saveEditUser = async (req, res) => {
    req.db.transaction(async (trx) => {
        try {
                console.log('=== Save EditUser ====')
                console.log(req.body)
                let staff = req.body.params.staff || ''
                console.log('staff :',staff)
                let id  = req.body.params.id_owner || ''
                console.log('id_onwer : ',id)
                if (staff.length <= 0 || id.length <= 0 ) {return res.send({status: 'error',data: 'ทำรายการผิดพลาด โปรดทำรายการใหม่อีกครั้ง'})}
                let update = await model.updateMasterLineStaff(trx,{
                    master_id: staff[0].master_id,
                    role_id : staff[0].role_id,
                    team_id : staff[0].team_id,
                    scs_department_id : staff[0].department_id,
                    is_admin: staff[0].department_id === 12 || staff[0].department_id === 7 ? "Y" : "N",
                    is_active : staff[0].is_active,
                    update_by : id
                })
                return update <= 0 ? res.send({status: 'error',data: 'ทำรายการผิดพลาด โปรดทำรายการใหม่อีกครั้ง'}) : getMenu(res,trx,staff)
        } catch (e) {
            trx.rollback()
            l.saveLog('saveEditUser',e.message)
            console.log(e.message)
            return res.send({status:'error',data: e.message}) 
        }
    })
}
Ctrl.getlistGroupPermisson = async (req, res) => {
    try {
         let AllGroupPermission = await model.getMasterRoleScreen(req.db,{})
         return res.send({status:'success',data: AllGroupPermission})
    } catch (e) {
         console.log(e.message)
         return res.send({status:'error',data: e.message}) 
    }
}
Ctrl.getDetailGroupPermission = async (req, res) => {
    try {
        let master_id =req.body.params.master_id
        console.log('master_id : ',master_id)
        if (master_id === null || master_id === undefined) {return res.send({status: 'error',data:'Not Found parameter id'})}
        let master_role_screen = await model.getMasterRoleScreen(req.db,{id: master_id})
        let Allscreen  = await model.getLineScreen(req.db,{screen_status: 'active'})
        let roleMenu = await model.getRolePermissionMenu(req.db,{role_id: master_id})
        master_role_screen =  master_role_screen.map((master) => {
            master.role_status = master.role_status === 'active' ? true : false
            return master
        })
         Allscreen = Allscreen.map(screen => {
             let check_screen = roleMenu.filter(role => { return role.screen_id === screen.id })
             screen.master_id = master_id
             screen.checked =  check_screen.length > 0 ? true : false
             screen.type = check_screen.length > 0 ? 'old' : 'new'
             return screen
         })
         console.log('Allscreen : => ',Allscreen)
        return res.send({status:'success',data : Allscreen,header: master_role_screen})
    } catch (err) {
         console.log(err.message)
         return res.send({status:'error',data: err.message})
    }
}
Ctrl.saveEditDetailGroupPermission = async (req, res) => {
    req.db.transaction(async (trx) => {
        try {

            let screen = req.body.params.screen
            let header = req.body.params.header
            if (screen === undefined || screen === null) {return res.send({status:'error',data: 'ทำรายการผิดพลาด โปรดทำรายการใหม่อีกครั้ง'})}
            console.log('screen : ',screen)
           
            let get_insert = screen.filter((screen) => {return screen.checked === true && screen.type === 'new'})
            let get_deleted = screen.filter((screen) =>{return screen.checked === false && screen.flag === 'deleted'})
            
            if (header.length > 0){
                let updated = await model.updateMasterRoleScreen(trx,{
                    id : header[0].id,
                    role_name: header[0].role_name,
                    role_description: header[0].role_description,
                    role_status : header[0].role_status === true ? 'active' : 'inactive'
                })
                console.log('update Master Role Screen => ',updated)
            } 

            if (get_insert.length > 0) {
                for (let value of get_insert) {
                    let save = await model.saveRolePermissionMenu(trx,{role_id: value.master_id,screen_id: value.id})
                    console.log('save roleMenu : ',save)
                }               
            }
            if (get_deleted.length > 0) {
                for (let value of get_deleted) {
                    let deleted = await model.deleteRolePermissionMenu(trx,{role_id: value.master_id,screen_id: value.id})
                    console.log('deleted roleMenu : ',deleted)
                }  
            }
            return res.send({status:'success',data: 'บันทึกสำเร็จ ✔️'})
        } catch (e) {
            trx.rollback()
            console.log(e.message)
            return res.send({status:'error',data: e.message})
        }
    })
}
Ctrl.getAllScreenSave = async (req,res) => {
    try {
          console.log('=== getAllScreenSave ===')
          let Screen = await model.getLineScreen(req.db,{screen_status: 'active'})
          Screen = Screen.map(screen => {
              screen.checked = false
              return screen
          })
          console.log(Screen)
          return res.send({status: 'success', data: Screen})
    } catch (e) {
        console.log(e.message)
        return res.send({status:'error',data: e.message})
    }
}
Ctrl.saveGroupPermission = async (req, res) => {
    req.db.transaction(async (trx) => {
        try {
            let header = req.body.params.header
            let screen = req.body.params.screen
            if (header.length <= 0 || screen.length <= 0) return res.send({status:'error', data : 'No parameter'})
            let saved = await model.saveMasterRoleScreen(trx,header)
            console.log('saved Master Role Screen : ',saved)
            if (saved > 0) {
                screen = screen.filter(screen => {return screen.checked === true})
                if (screen.length > 0) {
                 for (let value of screen) {
                    let saved_permission = await model.saveRolePermissionMenu(trx,{
                        role_id: saved,
                        screen_id: value.id,
                    })
                    console.log('saved Permission : ',saved_permission)
                 }
                }
            }
            return res.send({status:'success', data: 'บันทึกสำเร็จ ✔️'})
        } catch (e) {
            trx.rollback()
            console.log(e.message)
            return res.send({status:'error',data: e.message})
        }
    })
    
}
Ctrl.deletedGroupPermission = async (req, res) => {

    req.db.transaction(async (trx) => {
        try {
            let master_id = req.body.params.master_id;
            if (master_id === null || master_id === undefined) return res.send({status: 'error',data: 'Not parameter'});
            let deletedMasterRole = await model.deleteMasterRoleScreen(trx,{id: master_id});
            console.log('deletedMasterRole : ',deletedMasterRole);
            if (deletedMasterRole > 0) {
                let deletedRoleScreen = await model.deleteRolePermissionMenu(trx,{role_id: master_id})
                console.log('deleteRolePermissionMenu : ',deletedRoleScreen)
            }
            return res.send({status:'success',data:'ลบกลุ่มสิทธิ์สำเร็จ ✔️'})
        } catch (e) {
            trx.rollback()
            console.log(e.message)
            return res.send({status:'error',data: e.message})
        }
    })
    
}
Ctrl.deleteStaff = async (req, res) => {
    let {db} = req
    let {id} = req.body.params

    db.transaction(async (trx) => {
        try {
          console.log('id : ',id)
          let getMasterLineData = await  model.getMasterLineStaff(trx,{id: id})
          
          if (getMasterLineData.length > 0) {
              l.saveLog('deleted line ',JSON.stringify(getMasterLineData))
              await model.deleteMasterLineStaff(trx,{id: id})
              return res.send({status: "success",data: 'ลบพนักงานสำเร็จ'})
          }else {
              return res.send({status: "error",data: 'ไม่พบพนักงานคนนี้ในระบบ'})
          }
         
        } catch (er) {
            trx.rollback()
            console.log(er.message)
            l.saveLog('deleted line ',er.message)
            return res.send({status: "error",data: 'er.message'})
        }
    })
    
}
const getMenu = async (res,trx,staff_list) => {
  let screen = await model.getMenu(trx,{role_id: staff_list[0].role_id,screen_status:'active'})
  return res.send({status:'success',screen: screen,data: 'บันทึกสำเร็จ ✔️'})
}
module.exports = Ctrl