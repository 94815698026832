module.exports = {
    optionsDate : {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: '2-digit', minute: '2-digit',
        hour12: false,
        timeZone: 'Asia/Bangkok'
    },
    server : {
        port : '3303',
    },
    uri:{
        web  : 'https://scs-line.travox.com/',
        api : 'https://line.dev.ns.co.th/'
        // api : 'https://api-scsline.travox.com/'
    },
    db: {
        client : 'mysql',
        //base Real
        connection : {
            host : '10.0.1.191',
            port : '33001',
            user : 'ss',
            password : 'ss2015',
            database : 'ss_scs',
            supportBigNumber: true,
            timezone: '+7:00',
            charset : 'utf8_unicode_520_ci',
            dateStrings: true,
        },
        // base Test
        // connection : {
        //     host : '10.0.1.32',
        //     port : '3306',
        //     user : 'siamchai',
        //     password : 'scs2020',
        //     database : 'ss_scs',
        //     supportBigNumber: true,
        //     timezone: 'UTC',
        //     dateStrings: true,
        //     charset : 'utf8_unicode_520_ci'
        // }
        // base line_demo 
        // connection : {
        //     host : '202.129.206.245',
        //     port : '3306',
        //     user : 'line_robot',
        //     password : 'line@4555',
        //     database : 'line_robot_demo',
        //     supportBigNumber: true,
        //     timezone: 'UTC',
        //     dateStrings: true,
        //     charset : 'utf8mb4_unicode_ci',
        // },
        // pool: { min: 2, max: 100 },
        pool: { min: 0, max: 7 },
        acquireConnectionTimeout: 140000 // 60 sec
    },
    line : {
        disable_GroupLine : ['C71a1dd9f3ca2d302067dfbf0b109c4c1','C5b193e321a667c5d4504629963552572'],
         siamchai_bot : {
            channelAccessToken : 'QOXPwOoSt8A7ANVOwlORRdnaALc5SuIepVU+u1saJxIbOxXEBpuo3wPsdkKclaKCvZ8b11b034/Zq5E64U1CpbuvnztOyGvhWrElA9xuyZ35KCgUypydj9KuDvJr2GUuR5c/0YsJtTlvvamHzntUJwdB04t89/1O/w1cDnyilFU=',
            channelSecret : '86acdf2d759cc478ab9c50a364607efb'
         },
        siamchai_bot_v2 : {
            channelAccessToken : '6s1dWpe1tU95jrpQCa7Cq+DRT0+RdYvlk0OZWA09hQ4fL6j67r5ghMbdB6XLnIsSAF1FD1bVO+XHQvGTywmeyDzg5Xl9SBxO+x+l/zAYYPlQfuHr8tSFt1S7Z/X+GZb75buUvi9/JbNZFWfktQCddAdB04t89/1O/w1cDnyilFU=',
            channelSecret : 'd3cc15695eac6f7622851e13eb6e419f'
        },
        // bot_test : {
        //      channelAccessToken : 'LdPkAhoEgG7mNjsKu+ErX+ZwdT01//cFt2OzrnHxkoqPUCslHF4pn9jGxTp6dAn1EWbTRU+pFnmo86HXsnmShU/2ZaxJt5UtIH0fQ/LoDTDWk821cf0pgTIbg/mUdbHj6P08Addo+NZ7NOfmRZ5aNgdB04t89/1O/w1cDnyilFU=',
        //     channelSecret :  '27e635c0d6050e898b2ffd6f23ff2286',
        // },
        // channelAccessToken : 'LdPkAhoEgG7mNjsKu+ErX+ZwdT01//cFt2OzrnHxkoqPUCslHF4pn9jGxTp6dAn1EWbTRU+pFnmo86HXsnmShU/2ZaxJt5UtIH0fQ/LoDTDWk821cf0pgTIbg/mUdbHj6P08Addo+NZ7NOfmRZ5aNgdB04t89/1O/w1cDnyilFU=',
        // channelSecret :  '27e635c0d6050e898b2ffd6f23ff2286',
        // siamchai_bot_v3 : {
        //     channelAccessToken : 'O2bPXU1GAba4PG67yS7uLbQUSfEzrkyfgkQyhcQMsshZBZCzxh3A5kZh9uNrXq1k31ZrA4q2iaRZT/xeoeDKuFIEuURu71/1VWrNjP2bbTv5VmsHQeHIPGMWO/UkslVqPt3K3Gpnaw5BGohSdIR4MgdB04t89/1O/w1cDnyilFU=',
        //     channelSecret : '45c6d4714d30974d5fc85e9a73fd927a'
        // }
      
    }
}