import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { FlatList,StyleSheet, Text, View, ActivityIndicator,TouchableHighlight,ScrollView, SafeAreaView,TextInput, Button, Dimensions} from 'react-native';
import {SearchBar,Card,Input} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
const axios = require('axios')
import liff from '@line/liff';
import _liff from '../../lib/config-line-liff'
import  config from '../../../Api/config'
import Modal from "modal-enhanced-react-native-web"
import styless from '../../lib/style'
const dayjs = require('dayjs')
const {width, height} =  Dimensions.get('window')
export default class ReportAnnounceGroup extends React.Component{
    constructor(props) {
        super(props)
        this.state ={
            loading: false,
            showModal: false,
            group_id : props.route.params.id,
            line : {
                name : '',
                user_id : '',
                Picture : '' 
            },
            list_data : [],
            msg : '',
            keyword : '',
            data_sort : [],
            staff : '',
            pass : ''
        }
    }
    updateSearch = (text)=>{
        
        let input_text = text
        let old_data = this.state.list_data
        const newData = this.state.data_sort.filter(row=>  {
            return  row.text.toLowerCase().includes(input_text.toLowerCase()) || row.orderMessage.toLowerCase().includes(input_text.toLowerCase())
        })
        this.setState({keyword : input_text})
        if (this.state.keyword.length <= 1){
           this.setState({
            data_sort: old_data
            })
        }else{
            this.setState({
                data_sort : newData
            })
        }
    }
    getAnnounceGroup = () =>{
        axios.post( config.uri.api + 'api/report/reportAnnounceGroup',{params:{group_id: this.state.group_id,line: this.state.line}})
        .then((res)=>{
            if (res.data.status === 'add' || res.data.status === 'update') return this.checkMasterStaffLine(res.data)
            return this.setData(res.data) 
        }).catch((e)=>{
            console.log(e.message)
            this.setState({
                loading : true,
                list_data: e.message
            })
        })
    }
    checkMasterStaffLine = (data) => {
        this.setState({
            loading : true,
            showModal: true,
        })
    }
    setData = (data) =>{
        this.setState({
            loading : true,
            msg : data.status,
            list_data: data.data,
            data_sort: data.data
        })
    }
    confirmMasterStaffLine = () => {
        if (this.state.staff.trim().length <= 0) return this.setState({msg:'โปรดระบุไอดี'})
        if (this.state.pass.trim().length <= 0)  return this.setState({msg:'โปรดระบุรหัสผ่าน'})
        let staff = {id: this.state.staff, pass: this.state.pass}
        axios.post(config.uri.api + 'api/report/confirmMasterStaffLine',{params:{line: this.state.line,staff: staff}})
        .then(res=> {
            if (res.data.status === 'error') return this.setState({msg:res.data.data})
            return this.handleconfirm(res.data)
        }).catch(err=> {
            console.log(err.message)
            this.setState({msg: err.message})
        })
    }
    handleconfirm = (data) => {
        this.setState({msg: '',showModal:false,loading:false})
        this.getAnnounceGroup()
    }
    componentDidMount = async ()=>{
        console.log('Web all ready...')
         await  liff.init({liffId:_liff.line_liff.report_group}).catch((e)=>{console.log(e)})
            if (liff.isLoggedIn()) {
                let getProfile = await liff.getProfile()
                 this.setState({
                    line : {
                        name : getProfile.displayName,
                        user_id : getProfile.userId,
                        Picture : getProfile.pictureUrl, 
                    }
                 })
                
                  this.getAnnounceGroup()
                  this._unsubscribe = this.props.navigation.addListener('focus', () => {
                    this.getAnnounceGroup()
                  })
              }else{
                liff.login({redirectUri: `${config.uri.web}report_group/liff1/${this.state.group_id}`})
              }
       
    }
    
    componentWillUnmount(){
        console.log('Will Unmount')
        this._unsubscribe()
    }
    detail = (id,name) =>{
        let data = this.state.list_data
        let line = this.state.line
        let group_id = this.state.group_id
        data =  data.filter(row => row.id == id)
        this.props.navigation.navigate('report_group_detail',{name: name,data : data, line: line, group_id: group_id})
    }
    ItemView = ({item}) =>{           
        let show_date = dayjs(item.Send_at).format('DD/MM/YYYY HH:mm:ss')
        return (
            <SafeAreaView style={styles.view_data}>
           
                <ScrollView>
                    <TouchableHighlight  onPress={() => this.detail(item.id,item.orderMessage)}>
                        <View >
                            <View style={styles.topic}>
                                <Text style={{color:'#fff',fontSize:15}}>รหัสประกาศ  {item.orderMessage}</Text>
                            </View>
                            <View style={styles.flex_row}>
                                <View style={styles.flex_column}>
                                    <Text>กลุ่ม</Text>
                                    <Text>เรื่อง</Text>
                                    <Text>แนบรูป </Text>
                                    <Text>วันที่ประกาศ</Text>
                                    <Text>สถานะ</Text>
                                </View>
                                <View style={styles.flex_column}>
                                <Text>{item.group_name}</Text>
                                <Text>{width <= 500 ? item.text.substring(0,item.text.length/2) + '...' : item.text}</Text>
                                <Text>{item.img.length > 0 ? 'มี' : '-'}</Text>
                                <Text>{show_date}</Text>
                                {
                                    item.approve.map((approve,app_index)=>{
                                        
                                    return (
                                        <Text  key={app_index} style={{color: approve.status === 'Wait' ? 'red' : 'green'}}>
                                            { approve.status === 'Read' ? 'รับทราบประกาศแล้ว' : 'ยังไม่รับทราบประกาศ' }
                                        </Text>
                                    )
                                    })
                                }
                            </View>
                            </View>
                        
                        </View>
                    </TouchableHighlight>
                </ScrollView>
            </SafeAreaView>
        )
    }
    listData = ()=>{      
       
        return  (
        
        <FlatList
        data={this.state.data_sort}
        renderItem={this.ItemView}
        keyExtractor={(item,index) => item.id.toString()}
        />
                     
         )
    }
    handledata = () =>{
      if (this.state.msg === 'error'){
          return (
              <SafeAreaView style={styles.container}>
                    <View>{this.state.list_data}</View>
              </SafeAreaView>
          )
      }else if (this.state.showModal){
        return (
            <SafeAreaView>
            <Modal   isVisible={this.state.showModal } >
                <View style={styless.modalContent}>
                    <Text style={styless.textHeader}>ระบบยืนยันตัวตน</Text>
                    <Text style={styless.textDesc}>โปรดระบุไอดี staff ของคุณ.</Text>
                    <Text style={{color:'red'}}>{this.state.msg}</Text>     
                    <Input
                    style={{paddingLeft:10,width:'auto'}}
                    onChangeText = {(value) => this.setState({staff: value})}
                    placeholder='STAFF ID'
                    value={this.state.staff}
                    />
                     <Input
                     secureTextEntry={true}
                    style={{paddingLeft:10,width:'auto'}}
                    onChangeText = {(value) => this.setState({pass: value})}
                    placeholder='password'
                    value={this.state.pass}
                    />          
                    <View style={styles.flex_btn}>
                    <Button disabled={this.state.staff.trim().length <= 0 || this.state.pass.trim().length <= 0} style={{width:100,margin:10}} buttonStyle={{backgroundColor: 'blue'}}  title={'ตกลง'}  onPress={() => this.confirmMasterStaffLine()} ></Button> 
                    </View> 
                </View>
            </Modal >
    </SafeAreaView>
          
        )
      }else if (this.state.list_data.length <= 0) {
        <SafeAreaView style={styles.container}>
        <View>ไม่พบข้อมูลประกาศ</View>
        </SafeAreaView>
      }else{
        return ( this.listData())
         
      }
    }
    render() {
         
        if (this.state.loading === false){
            return (
                <SafeAreaView style={styles.container}>
                <Text>กำลังโหลดข้อมูล</Text>
                <ActivityIndicator size="large" color="green" />
            </SafeAreaView>
            )
           
        }else{
            return ( 
                <SafeAreaView>
                <SearchBar
                placeholder= 'ค้นหา...'
                onChangeText = {this.updateSearch}
                value={this.state.keyword}
                containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,margin:15,padding:10,visibility:this.state.msg === 'error' || !this.state.msg ? 'hidden' : 'visible'}}
                inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'2px',borderWidth:0}}
                />
                {}
                {this.handledata() }
                </SafeAreaView>
                
                )
        }
    }
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'transparent',
      alignItems: 'center',
      justifyContent: 'center',
    },
    view_data : {
        backgroundColor:'transparent',
        margin:10,
        padding:10,
        borderWidth: 0,
        borderRadius:15
    },
    flex_row : {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      backgroundColor: '#fff',
      marginLeft: 5,
      marginRight: 5,
      padding:10,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius:5,
     
    },
    flex_column : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        padding:10,
        marginLeft: 5,
        marginRight: 5,
    },
    topic :{
        backgroundColor : '#6666CC',
        borderWidth: 0,
        color: '#FFFFFF',
        borderTopRightRadius: 5,
        borderTopLeftRadius:5,
        padding:10,
        marginLeft:5,
        marginRight:5,
    }
 
});