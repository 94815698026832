import { StatusBar } from 'expo-status-bar';
import React,{useEffect, useState} from 'react';
import { StyleSheet, Text, View, ActivityIndicator,TouchableHighlight,ScrollView,SafeAreaView,TextInput,FlatList} from 'react-native';
import {Input,ListItem,SearchBar,Badge, Tooltip,Button} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import {Picker} from '@react-native-picker/picker';
import { DatePicker,Pagination } from 'react-rainbow-components';
import  config from '../../../Api/config'
// import { DatePicker } from "react-rainbow-components";
// import DatePicker from 'react-date-picker'
import styless from '../../lib/style'
import Modal from "modal-enhanced-react-native-web";
const axios = require('axios')
const dayjs = require('dayjs')
// const duration = require('dayjs/plugin/duration')
const ReportDaily = (props) => {
    const [Daily,setDaily] = useState([])
    const [valueType,setValueType] = useState('daily')
    const [dataExport,setDataExport] = useState([])
    const [state, setState] = useState({ activePage: 1 })
    const [DatePicker1,setDatePicker1] = useState({date:''})
    const [DatePicker2,setDatePicker2] = useState({date:''})
    const [teamLine, setTeamLine] = useState()
    const [loading,setLoading] = useState(true)
    const [loadingData,setLoadingData] = useState(false)
    const [selectedTeamLine,setSelectedTeamLine] = useState([{id:0,team_name:'ทีมทั้งหมด'}])
    const [datatype,setDatatype] = useState('')
    const [minDate,setMinDate] = useState()
    const [status,setStatus] = useState(true)
    const page = Math.ceil(Daily.length/30)
    useEffect(() => {
        getTeamLine()
    },[])
    const getTeamLine = () => {
        axios.get(config.uri.api + 'api/report/getTeamLine')
        .then(res=>{
            setLoading(false)
            if (res.data.status === 'success'){
                setTeamLine(res.data.data)
            }
         }).catch(err=>{console.log(err.message)})
    }
    const onChangeDate1 = (date) => {
        const setDate  = {date: date}
        const minDate = date
        setDatePicker1(setDate)
        setMinDate(minDate)
        if (DatePicker2.date === ''){
            setDatePicker2(setDate)
        }
        if (date > new Date(DatePicker2.date)){
            console.log('less')
            setDatePicker2(setDate)
        }
        
    }
    const onChangeDate2 = (date) => {
        const setDate  = {date: date}
        setDatePicker2(setDate)
    }
      
    const handleChangeTeamLine = (selected) => {
        console.log(selected)
        let newSelected = [{id:0,team_name:''}]
        let getSelect  = teamLine.filter(team => {return team.team_name === selected});
        console.log(getSelect)
        newSelected[0].id = getSelect[0].id
        newSelected[0].team_name = getSelect[0].team_name
        setSelectedTeamLine(newSelected)
      
    }
    const handleOnChange = (event, page) => {
        setState({ activePage: page });
    }

    const listDaily = () => {
       
        const { activePage } = state;
        const lastItem = activePage * 30;
        const firstItem = lastItem -  30;
      
        return (
            Daily && Daily.slice(firstItem, lastItem).map((item, index) => {
                let date = dayjs(item.msg_date).format('DD/MM/YYYY')
                   if (datatype === 'daily') {
                       return (
                           <View key={index} style={{backgroundColor:'#fff',margin:10,padding:10,borderBottomWidth:1}}>
                               <Text>ชื่อผู้ส่ง : {item.user_send && item.user_send} </Text>
                               <Text>เบอร์ทั้งหมด : {item.cn_send && item.cn_send}</Text>
                               <Text>วันที่  : {date}</Text>
                           </View>
                       )
                   }
                   if (datatype === 'daily-detail') {
                       return (
                           <View key={index} style={{backgroundColor:'#fff',margin:10,padding:10,borderBottomWidth:1}}>
                               <Text>ชื่อผู้ส่ง : {item.user_write && item.user_write} ชื่อผู้รับ : {item.user_read && item.user_read} </Text>
                               <TextInput
                                   style={{ width:'auto',height: 120,margin:10,padding:10,backgroundColor:'#fff' ,borderWidth: 0 ,justifyContent: "flex-start"}}
                                   disabled={true}
                                   value = {item.line_message}
                                   multiline={true}
                                />
                               <Text>วันที่  : {date}</Text>
                           </View>
                       )
                   }
                   if (datatype === 'daily-read') {
                       return (
                           <View style={{backgroundColor:'#fff',margin:10,padding:10,borderBottomWidth:1}}>
                               <Text>ชื่อผู้อ่าน {item.user_read && item.user_read}</Text>
                               <Text>อ่านทั้งหมด {item.cn_read && item.cn_read}</Text>
                               <Text>วันที่  : {item.msg_date}</Text>
                           </View>
                       )
                   }
               })
        )
    }
    const onValueChangeType = (value) =>{
         console.log('type ',value)
         setValueType(value)
    }
    const confirmDate = () => {
        if (DatePicker1.date === '') return alert('โปรดระบุวันที่เริ่มต้น')
        if (DatePicker2.date === '') return alert('โปรดระบุวันสิ้นสุด')
        return searchDate()
    }
    const searchDate = () => {
        const { activePage } = state
        if (activePage > 1) { setState({ activePage: 1 }) }
        if (Daily.length > 0) {setDaily([]),setDataExport([])}
        setLoadingData(true)
        let startDate = dayjs(DatePicker1.date).format('YYYY-MM-DD')
        let endDate   = dayjs(DatePicker2.date).format('YYYY-MM-DD')
        axios.post(config.uri.api + 'api/report/listReportDaily',{params: {
            date1   : startDate,
            date2   : endDate,
            type    : valueType,
            team_id : selectedTeamLine[0].id
        }}).then(res=>{
            console.log(res.data)
            setLoadingData(false)
            if (res.data.data.length <= 0){ return setStatus(false)}
            return handleReport(res.data)
        }).catch(err=>{
            console.log(err.message)
        })
    }
    const handleReport = (value) => {
        setStatus(true)
        setDaily(value.data)
        setDataExport(value.data)
        setDatatype(value.type)
    }
    const exportExcel = () => {
        axios.post( config.uri.api + 'api/exportExcel',{params:{data: dataExport,type: valueType}})
        .then((res)=>{
                console.log(res.data)
                window.open(res.data.uri,'_blank')
        })
        .catch(err=>{
            console.log(err.message)
        })
    }
    const checkStatusData = () => {
        if (!status){
            return (
                <View><Text style={{textAlign:'center'}}>ไม่พบข้อมูลรายงาน </Text></View>
            )
        }
        return listDaily()
    }
   
    const { activePage } = state;  
    if (loading){
        return (
            <SafeAreaView style={styles.container}>
            <Text>กำลังโหลดข้อมูล ...</Text>
            <ActivityIndicator size={'large'} color={'green'}/>
        </SafeAreaView>
        )
    }
    return (
        <SafeAreaView style={{backgroundColor:'#F2F2F2'}}> 
        {/* <ScrollView> */}
        <View style={{flexDirection: 'row'}}> 
            <Picker style={{margin:10,padding:10,height: 50,borderWidth:0,color:'gray',width:'50%'}} 
                selectedValue={valueType}
                onValueChange={(itemValue) => onValueChangeType(itemValue) }>
                <Picker.Item label="รายงานสรุปจำนวนรวมพนักงานที่ส่งเบอร์โทรศัพท์" value="daily" /> 
                <Picker.Item label="รายงานแสดงรายละเอียดข้อความของพนักงาน" value="daily-detail" />
                <Picker.Item label="รายงานสรุปจำนวนรวมพนักงานที่ได้รับเบอร์โทรศัพท์" value="daily-read" /> 
            </Picker>
            <Picker
                
                 style={{margin:10,padding:10,height: 50,borderWidth:0,color:'gray',width:'50%'}} 
                selectedValue={selectedTeamLine[0].team_name}
                onValueChange={(itemValue) => handleChangeTeamLine(itemValue)}
            >
                {
                    teamLine && teamLine.map((team,index) => {
                        return (
                            <Picker.Item key={index} label={team.team_name} value={team.team_name} />
                        )
                    })
                }
            </Picker> 
        </View>
        
        <View style={{flexDirection:'row',width:'auto',margin:10}}>
             <DatePicker
                disabled ={loadingData === true}
                // label={'ระบุวันเริ่มต้น'}
                placeholder={'ระบุวันเริ่มต้น'}
                locale={'TH'} 
                onChange = {(date) => {onChangeDate1(date)}}
                value = {DatePicker1.date}
             />
               <DatePicker
                disabled={DatePicker1.date === '' || loadingData === true}
                // label={'ระบุวันสิ้นสุด'}
                placeholder={'ระบุวันสิ้นสุด'}
                minDate={minDate}
                locale={'TH'} 
                onChange = {(date) => {onChangeDate2(date)}}
                value = {DatePicker2.date}
             />
        </View>
        <View style={{flexDirection:'row',justifyContent:'center',margin:10}}>
            <Button
                disabled = {DatePicker1.date === '' || DatePicker2.date === '' || loadingData === true} 
                icon={
                    <Icon name="search" size={15} color="white" style={{marginRight: 5}} />
                } 
                style={{margin:10}} 
                title= {'ค้นหา'} 
                titleStyle={{fontSize:'14px'}} 
                buttonStyle={{backgroundColor:'green',width:120}} onPress={()=>confirmDate()} /> 
            <Button
                icon={
                        <Icon name="file" size={15} color="white" style={{marginRight: 5}} />
                    } 
                style={{margin:10}} titleStyle={{fontSize:'14px'}} 
                buttonStyle={{backgroundColor:'#000000',width:105}} 
                title="ออกรายงาน" 
                disabled={Daily.length <= 0} onPress={()=> exportExcel()} /> 
        </View>
        <View style={{display: Daily.length <= 0 || loadingData === true ? 'none' : 'block'}}>
         <Pagination
                    className="rainbow-m_auto"
                    pages={page}
                    activePage={activePage}
                    onChange={handleOnChange}
                    variant="shaded"
         />
         </View>
            {
                loadingData === true ? <View><ActivityIndicator size={'large'} color={'green'} /></View> : checkStatusData()
            }
        {/* </ScrollView> */}
        </SafeAreaView>
    )
}
export default ReportDaily

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    }
  
  });

