// In App.js in a new project
import * as React from 'react';
import { NavigationContainer ,DrawerActions   } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator  } from '@react-navigation/drawer';
import { DrawerNavigator } from 'react-navigation';
import { TouchableOpacity,useWindowDimensions,Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome'
import Login from './components/login/login'
import Report from './components/report/report'
import ReportDetails from './components/report/report-detail';
import Members from './components/report/member'
import Announce from './components/announce_line/main'
import ReportDaily from './components/report/report-daily'
import ReportGroup from './components/report/report-group'
import ReportGroupDetail from  './components/report/report-group-detail'
import GenForm from './components/form/genForm'
import saveForm from './components/form/saveForm';
import approveForm from './components/form/approveForm';
import approveFormDetails from './components/form/approveFormDetails';
import listForm from './components/form/listForm';
import listFormDetail from './components/form/listForm-detail';
import duplicateForm from './components/form/duplicateForm'
import Home from './components/login/home'
import DrawerContent from './components/login/drawerContent'
import Logout from './components/login/logout'
import ListUser from './components/setting/user/list_user'
import Detail_user from './components/setting/user/detail_user'
import ListGroupPermisson from './components/setting/permission/list_group_pesmission';
import DetailGroupPermission from './components/setting/permission/detail_group_permission';
import CreateGroupPermissions from './components/setting/permission/add_group_permission';
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

function drawerRouter(){
  const dimensions = useWindowDimensions();
 
 return (
    <Drawer.Navigator initialRouteName='home' drawerContent={props=><DrawerContent {...props}/>}
    >
      <Drawer.Screen name="home"  component={Home} />
      <Drawer.Screen name="announce_line"  component={Announce}  />
      <Drawer.Screen name="report"  component={Report}  />
      <Drawer.Screen name="listForm" component={listForm} />
      <Drawer.Screen name="approveForm" component={approveForm} />
      <Drawer.Screen name="exportExcel" component={ReportDaily} />
      <Drawer.Screen name="setting_user" component={ListUser} />
      <Drawer.Screen name="setting_role" component={ListGroupPermisson} />
    </Drawer.Navigator>
   
   ); 
}

function App() {

   return (
    <NavigationContainer
      linking ={{
        config  : {
            screens : {

              login : {
                path : 'login',
              },
              home : {
                path : 'home'
              },
              announce_line : {
                path : 'announce_line/liff1/:id?',
              },
              
              report_group:{
                path : 'report_group/liff1/:id?',
              },
              gen_form : {
                path : 'gen_form',
              },
              save_form : {
                path : 'save_form/liff1/:group_id?/:id?',
              },
            }
        }
      }}
    >
      <Stack.Navigator>
      
        <Stack.Screen name="login" component={Login}
        options={
          ({navigation,route})=>({
            title : 'login' ,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        } 
        // options={{ headerShown:false, 
        //   headerStyle: {
        //     backgroundColor: 'rgb(171,57,133)',
        //   },}} 
        
        />
      <Stack.Screen name="home" component={drawerRouter} 
            options={
              ({navigation,route})=>({
                title : 'หน้าหลัก' ,
                headerStyle:{
                  backgroundColor : 'rgb(171,57,133)',
                },
                headerLeft : () => (
                  <View style={{flexDirection: 'row',alignItems: 'center'}}>
                    <Icon
                      onPress ={()=>   navigation.dispatch(DrawerActions.openDrawer())}
                      name='bars'
                      color='#fff'
                      size={30}
                      style={{marginLeft: 20,marginRight:20}}
                    />
                    <Text style={{marginLeft:5,color:'#fff',fontSize:18}}>เมนู</Text>
                    </View>
                ),
                headerTitleAlign:'center',
                headerTintColor:'#fff',
                headerTitleStyle: { color: '#fff' }
              })
            }
        />
      <Stack.Screen name="report" component={Report} 

          options={
            ({navigation,route})=>({
              title : 'รายงานประกาศ' ,
              headerStyle:{
                backgroundColor : 'rgb(171,57,133)',
              },
              headerTitleAlign:'center',
              headerTintColor:'#fff',
              headerTitleStyle: { color: '#fff' }
            })
          }
        />
    
         <Stack.Screen name="reportDetails" component={ReportDetails}
           options={
            ({route})=>({
              title : route.params.text,
              headerStyle:{
                backgroundColor : 'rgb(171,57,133)',
              },
              headerTitleAlign:'center',
              headerTintColor:'#fff',
              headerTitleStyle: { color: '#fff' },
            })
          } 
        />
         <Stack.Screen name="Members" component={Members}
           
         options={
          ({route})=>({
            title : (route.params.group_name !== '' || route.params.group_name !== undefined ? route.params.group_name : 'รายชื่อสมาชิก'),
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        } 
        />
         <Stack.Screen name="announce_line" component={Announce}
         
         options={
           {
           title: 'สร้างประกาศเฉพาะกลุ่ม',
           headerStyle:{
             backgroundColor : 'rgb(171,57,133)',
           },
           headerTitleAlign:'center',
           headerTintColor:'#fff',
         }}
       />
         <Stack.Screen name="report_daily" component={ReportDaily}
         
         options={
           {
           title: 'รายงานรายวันข้อความไลน์',
           headerStyle:{
             backgroundColor : 'rgb(171,57,133)',
           },
           headerTitleAlign:'center',
           headerTintColor:'#fff',
           
         }}
       />
        <Stack.Screen name="report_group" component={ReportGroup}
         
         options={
           {
           title: 'รายงานเฉพาะกลุ่ม',
           headerStyle:{
             backgroundColor : 'rgb(171,57,133)',
           },
           headerTitleAlign:'center',
           headerTintColor:'#fff',
           
         }}
       /> 
        <Stack.Screen name="report_group_detail" component={ReportGroupDetail}
         
         options={
          ({route})=>({
            title : 'รายละเอียดประกาศ ' + route.params.name ,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        } 
       />
       <Stack.Screen name="gen_form" component={GenForm}
         
         options={
          ({route})=>({
            title : 'สร้างฟอร์ม ',
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        } 
       /> 
        <Stack.Screen name="save_form" component={saveForm}
         
         options={
          ({route})=>({
            title : 'แบบฟอร์มข้อมูล',
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       /> 
       <Stack.Screen name="approve_form" component={approveForm}
         
         options={
          ({route})=>({
            title : 'ระบบจัดการฟอร์ม',
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
       <Stack.Screen name="approve_form_detail" component={approveFormDetails}
         
         options={
          ({route})=>({
            
            title : route.params.form_name ,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
       <Stack.Screen name="listForm" component={listForm}
         
         options={
          ({route})=>({
            
            title : 'รายการฟอร์ม' ,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
        <Stack.Screen name="listFormDetail" component={listFormDetail}
         
         options={
          ({route})=>({
            title : 'รายละเอียดคำถาม' ,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
       <Stack.Screen name="duplicateForm" component={duplicateForm}
         
         options={
          ({route})=>({
            title : 'คัดลอก' + route.params.name,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
        <Stack.Screen name="logout" component={Logout}
         options={
          ({route})=>({
            title : 'ออกจากระบบ',
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
        <Stack.Screen name="setting_user" component={ListUser} 
         options={
          ({route})=>({
            title : 'รายชื่อพนักงาน',
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
       <Stack.Screen name="detail_user" component={Detail_user} 
         options={
          ({route})=>({
            title : 'รายละเอียดพนักงาน',
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
        <Stack.Screen name="setting_role" component={ListGroupPermisson} 
         options={
          ({route})=>({
            title : 'ตั้งค่ากลุ่มสิทธิ์',
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
       <Stack.Screen name="detail_GroupPermission" component={DetailGroupPermission} 
         options={
          ({route})=>({
            title : 'รายละเอียดกลุ่มสิทธิ์ ' ,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />
       <Stack.Screen name="AddGroupPermission" component={CreateGroupPermissions} 
         options={
          ({route})=>({
            title : 'เพิ่มกลุ่มสิทธิ์' ,
            headerStyle:{
              backgroundColor : 'rgb(171,57,133)',
            },
            headerTitleAlign:'center',
            headerTintColor:'#fff',
            headerTitleStyle: { color: '#fff' }
          })
        }
       />                         
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App
