import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Input,Button, Badge, Image, Divider, Avatar} from 'react-native-elements'
import {
    DrawerContentScrollView,
    DrawerItem
} from '@react-navigation/drawer'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../lib/style'
import axios from 'axios'
import  config from '../../../Api/config'
 const DrawerContent = (props) => {
    const data = props.state.routes.filter(routes=>{return routes.name === 'home'})
    const [Data,setData] = useState(data[0].params.data)
    const [Menu,setMenu] = useState()
    useEffect(() => {
        getMenu()
    },[])
    const getMenu = async () => {
        try {
            console.log('Menu ready...')
            await  AsyncStorage.setItem('staff',JSON.stringify({ad: Data[0].is_admin, ac: Data[0].is_active}))
            let menu = await AsyncStorage.getItem('role_menu')
            if (menu.length <= 0) { menu  =  []} 
            setMenu(JSON.parse(menu))
          } catch(e) {
            console.log(e.message)
          }
    } 
    const handleIcon =  (screen_code) => {
        let icon = ''
        if (screen_code === 'home') return icon = 'home' 
        if (screen_code === 'report') return icon = 'bullhorn' 
        if (screen_code === 'listForm') return icon = 'tasks'
        if (screen_code === 'approveForm') return icon = 'check-square'
        if (screen_code === 'exportExcel') return icon = 'file'
        if (screen_code === 'setting_user') return icon = 'cogs'
        if (screen_code === 'setting_role') return icon = 'wrench'
        return icon = 'question-circle'
    }  
    return (
     <SafeAreaView style={{flex:1}}>
         <DrawerContentScrollView {...props}>
             <View style={{flexDirection:'row',textAlign:'center',alignItems:'center'}}>
                 <View style={{margin:'6%'}}>
                    <Avatar 
                        rounded = {true}
                        source={{
                            uri:
                            Data[0].images,
                        }}
                    />
                 </View>
                 <View style={{flexDirection:'row',alignItems: 'center'}}>
                 <Icon
                    style={styles.icon}
                    name={'users'}
                    size={15}
                 />
                 <Badge 
                    status={'success'}
                    value={`${Data[0].department_name}`}
                  />
                 </View>
                 
             </View>
           
            <View style={{textAlign:'center'}}>
            <Divider  inset={true} insetType="middle" />
            </View>
            <View style={{margin:15,textAlign: 'center'}}>
                <View style={{flexDirection:'row',textAlign: 'center',alignItems: 'center'}}>
                    <View >
                        {/* <Icon
                            style={styles.icon}
                            name={'user'}
                            size={15}
                        /> */}
                         <Avatar 
                            rounded = {true}
                            source={{
                                uri: `${config.uri.api}/images/line.png`,
                            }}
                        />
                    </View>
                    <View style={{marginLeft:10}}>
                        <Badge 
                            status={'warning'}
                            value={`${ Data[0].staff_line_name  || "-"}`}
                        /> 
                    </View>
                      
                </View>
                <View style={{flexDirection:'row',textAlign: 'center',alignItems: 'center'}}>
                    <View>
                        {/* <Icon
                            style={styles.icon}
                            name={'envelope'}
                            size={15}
                        />   */}
                         <Avatar 
                            rounded = {true}
                            source={{
                                uri: `${config.uri.api}/images/email.png`,
                            }}
                        />
                    </View>
                    <View style={{marginLeft:10}}>
                        <Badge 
                            status={'warning'}
                            value={`${Data[0].mail_line || "-"}`}
                        />    
                    </View>   
                </View>
             </View>
             <View style={{textAlign:'center'}}>
            <Divider  inset={true} insetType="middle" />
            </View>
              <View style={{marginTop:15}}>
                {
                    Menu  && Menu.map((menu,index) => {
                      let icon_name =  handleIcon(menu.screen_code)
                        return (
                            <DrawerItem
                                key={index}
                                icon={({color,size})=>(
                                    <Icon 
                                        name={icon_name}
                                        size={20}
                                        color={color}
                                    />
                                )}
                                label={menu.screen_name}
                                onPress={()=> props.navigation.navigate(`${menu.screen_code}`)}
                            />  
                        )
                    })
                }
              </View>
         </DrawerContentScrollView>
     </SafeAreaView>
    )
}
// const styles = StyleSheet.create({
  
// })
export default DrawerContent