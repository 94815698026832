import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text, ActivityIndicator,TouchableHighlight } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Input, Badge, Image, Divider, Avatar,SearchBar, Button} from 'react-native-elements'

import styles from '../../../lib/style'
import axios from 'axios'
import config from '../../../../Api/config'
import { Pagination, Modal  } from 'react-rainbow-components';
const dayjs = require('dayjs')
const List_user = (props) => {
    const [loading,setLoading] = useState(false)
    const [state, setState] = useState({ activePage: 1 })
    const [employees,setEmployees] = useState([])
    const [department,setDepartment] = useState()
    const [status,setStatus] = useState('')
    const [action,setAction] = useState('list')
    const [search,setSearch] = useState('')
    const [tempData,setTempData] = useState([])
    const [HandleButton, setHandleButton] = useState(false)
    const page = Math.ceil(employees.length/30)
    useEffect(() => {
        getlistMasterStaff()
        const unsubscribe = props.navigation.addListener('focus', () => {
            getlistMasterStaff()
          });
        return unsubscribe
    },[props.navigation])
    const getlistMasterStaff = async () => {
       
        axios.post(config.uri.api + 'api/setting/listMasterStaff')
        .then(res=>{
            setLoading(true)
            setEmployees(res.data.data)
            setTempData(res.data.data) 
            setStatus(res.data.status)
             
        })
        .catch((err)=>{console.log(err.message)})
        await handleButton()
    }
    const detail_Employees =  (master_id) =>{
        let getdata = employees.filter(e=>{return e.master_id === master_id});
        if (getdata.length > 0) {
            props.navigation.navigate('detail_user',{employees: getdata});
        }
    }
    const handleOnChange = (event, page) => {
        setState({ activePage: page });
    }
    const updateSearch = (value) => {
        let new_search = value.toLowerCase()
        setSearch(new_search)
        let data = tempData
        if (search.length <= 1) {
            setEmployees(data)
        }else {
            let update = employees.filter((val) => {
                if (val.staff_line_name.toLowerCase().includes(search.toLowerCase())) {
                    return val
                }else if (val.display_name.toLowerCase().includes(search.toLowerCase())) {
                    return val
                }else if (val.mail_line.toLowerCase().includes(search.toLowerCase())) {
                    return val
                }else if (val.role_name.toLowerCase().includes(search.toLowerCase())) {
                    return val
                }else if (val.department_name.toLowerCase().includes(search.toLowerCase())) {
                    return val
                }
               
            })
            setEmployees(update)
        }
       
    }
    const handleButton = async () => {
          let getPermissions =  JSON.parse(await AsyncStorage.getItem('staff'))
          if ( getPermissions.ad === "Y" && getPermissions.ac === "Y") {
            setHandleButton(true)
          }
    }
    const deleteStaff = async (id) => {
        axios.post(config.uri.api + 'api/setting/deleteStaff',{params:{id: id || null}})
        .then(res=>{
            alert(res.data.data)
            getlistMasterStaff()  
        })
        .catch((err)=>{console.log(err.message)})
    }
    const { activePage } = state;
    const lastItem = activePage * 30;
    const firstItem = lastItem -  30; 
    if (loading === false){
        return (
            <SafeAreaView style={styles.container}>
                <Text>กำลังโหลดข้อมูล...</Text>
                <ActivityIndicator size={'large'} color={'green'} />
            </SafeAreaView>
        )
    }
    if (status === 'error') {
        return (
            <View style={styles.container}>
                 <Text>{employees.toString()}</Text>
            </View>     
        )
    }
    
        return (
            <SafeAreaView style={{backgroundColor: '#F2F2F2'}}>
                {/* <Modal id="modal-1" isOpen={true} onRequestClose={closeModal()}>
                        <Text>Test</Text> 
                </Modal>   */}
                <SearchBar
                    round = {true}
                    placeholder= 'ค้นหา...'
                    onChangeText = {updateSearch}
                    value={search}
                    containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,margin:10}}
                    inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
                    />
                <View style={{display: employees.length <= 0 || loading === false ? 'none' : 'block'}}>
                <Pagination
                            className="rainbow-m_auto"
                            pages={page}
                            activePage={activePage}
                            onChange={handleOnChange}
                            variant="shaded"
                />
                </View>
                {
                  employees && employees.slice(firstItem, lastItem).map((employee,index) => {
                        return (
                            <View  key={index} style={styles.main}  >
                                <TouchableHighlight onPress={()=> detail_Employees(employee.master_id)}>
                                <View style={styles.row}>
                                      <View style={{marginLeft:5,marginRight:5}}>
                                            <Text>ชื่อ-นามสกุล : </Text>
                                            <Text>ชื่อไลน์ : </Text>
                                            <Text>อีเมลไลน์ : </Text>
                                            <Text>สถานะไอดี : </Text>
                                            <Text>ระดับกลุ่มสิทธิ์ : </Text>
                                            <Text>ทีม Line : </Text>
                                            <Text>แผนก : </Text>
                                            <Text>วันที่สร้าง : </Text>
                                           
                                     </View>
                                      <View style={{marginLeft:5,marginRight:5}}>
                                            <Text>{employee.display_name}</Text>
                                            <Text>{employee.staff_line_name || '-'}</Text>
                                            <Text>{employee.mail_line || '-'}</Text>
                                            <Text>{employee.is_active === 'Y' ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }</Text>
                                            <Text>{employee.role_name || '-'}</Text>
                                            <Text>{employee.team_name || '-'}</Text>
                                            <Text>{employee.department_name || '-'}</Text>
                                            <Text>{dayjs(employee.system_date).format('DD/MM/YYYY HH:mm:ss') }</Text>
                                      </View>
                                </View>
                                </TouchableHighlight>
                                <Button
                                    style={{marginLeft:'1%',marginTop:5,width:'5%',display: HandleButton === false ? 'none' : 'block'}}
                                    buttonStyle={{backgroundColor:'red'}}
                                    titleStyle={{fontSize:12,textAlign:'center'}} 
                                    title={'ลบพนักงาน'}
                                    onPress ={() => {deleteStaff(employee.master_id)}}
                                />
                            </View>
                        )
                    })
                    
                }
                
            </SafeAreaView>
        )
      
   
}
export default  List_user 