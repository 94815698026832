import  React,{useEffect,useState} from 'react'
import { SafeAreaView, View, StyleSheet,Text, ActivityIndicator,TouchableHighlight,Dimensions } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Input,Button, Badge, Image, Divider, Avatar,CheckBox} from 'react-native-elements'
import {
    DrawerContentScrollView,
    DrawerItem
} from '@react-navigation/drawer'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../../lib/style'
import axios from 'axios'
import config from '../../../../Api/config'
import { deletedGroupPermission } from '../../../../Api/ctrl/setting/setting';
const dayjs = require('dayjs')
const {width , height } = Dimensions.get('window')
const Detail_group_permission = (props) => {
    const [loading, setLoading] = useState(false)
    const [screen,setScreen] = useState()
    const [status,setStatus] = useState('')
    const [header,setHeader] = useState()
    const [checkAll,setCheckAll] = useState(false)
    console.log(props)
    useEffect(() => {
        getDetailGroupPermission()
    },[])
    const getDetailGroupPermission = () => {
        axios.post(config.uri.api + 'api/setting/getDetailGroupPermission',{params:{master_id: props.route.params.master_id}})
        .then(res=>{
            console.log(res.data)
            setLoading(true)
            setHeader(res.data.header)
            setStatus(res.data.status)
            setScreen(res.data.data)
            checkAllList(res.data.data)
        })
        .catch(err =>{
            setStatus('error')
            setScreen(err.message)
        })
    }
    const checkAllList = (data) =>{
        let getData = data.filter(screen => {return screen.checked === true})
        if (getData.length >= data.length) return setCheckAll(true)
    }
    const handleChecked = (index) => {
        let data = screen
        data = data.map((screen,s_index) =>{
            if (s_index === index)
            screen.checked = !screen.checked
            screen.flag = (screen.type === 'old' && screen.checked === false) ?  'deleted' : 'noting'
            return screen
        })
        let checkAll = data.filter(item => {return item.checked === true})
        let isTrue =  checkAll.length >= data.length ? true : false
        console.log('isTrue ',isTrue)
        setScreen(data)
        setCheckAll(isTrue)
    }
    const save = () => {
        let checked = screen.filter(screen => {return screen.checked === false})
        if (header[0].role_name.length <= 0) return alert('โปรดระบุชื่อกลุ่มสิทธิ์')
        if (checked.length >= screen.length) return alert('โปรดระบุสิทธิ์เมนูอย่างน้อย 1 เมนู')
        axios.post(config.uri.api + 'api/setting/saveEditDetailGroupPermission',{params:{
            screen: screen,
            header: header
        }}).then((res) => {
            handleResponse(res.data)
        }).catch((err) => { console.log(err.message)})
    }
    const handleResponse = (data) => {
        if (data.status === 'error') {
           return alert(data.data)
        }
        alert(data.data)
        return props.navigation.pop()
    }
    const handleHeader = (e,id) => {
        let get_header = header
        if (id === 1){
            get_header = get_header.map(header => {
                header.role_name = e.nativeEvent.text.trim()
                return header
            })   
        }
        if (id === 2){
            get_header = get_header.map(header => {
                header.role_description = e.nativeEvent.text.trim()
                return header
            })
        }
        setHeader(get_header)
        
    }
    const handleRoleStatus = () => {
        let data = header
        data  = data.map(header => {
            header.role_status = !header.role_status
            return header
        })
        setHeader(data)
    }
    const deletedMasterGroupPermission = () => {
        axios.post(config.uri.api + 'api/setting/deletedGroupPermission',{params:{
            master_id: header[0].id
        }}).then(res=>{
            handleResponse(res.data)
        }).catch(err=>{console.log(err.message)});
    }
    const handleCheckAll = () => {
        let data = screen
        data = data.map(item=>{
            item.checked = !checkAll
            return item
        })
        setScreen(data)
        setCheckAll(!checkAll)
    }
    if (!loading){
        return (
            <SafeAreaView style={styles.container}>
                    <Text>กำลังโหลดข้อมูล...</Text>
                    <ActivityIndicator size={'large'} color={'green'}/>
            </SafeAreaView>
        )
    }
    if (status === 'error'){
        return (
            <SafeAreaView style={styles.container}>
                    <Text>{JSON.stringify(screen)}</Text>
            </SafeAreaView>
        )
    }
    return (
        <SafeAreaView style={styles.main}>
                {
                    header && header.map((header,index) => {
                        
                        return (
                            <View key={index} style={{margin:5}}>
                                <Input
                                    style={{margin:5,padding:5}}
                                    label={'ระบุชื่อกลุ่มสิทธิ์'} 
                                    value={header.role_name}
                                    onChange = {(e) => {handleHeader(e,1)}}
                                />
                                <Input
                                        style={{margin:5,padding:5}}
                                    label={'ระบุรายละเอียด'} 
                                    value={header.role_description}
                                    onChange = {(e) => {handleHeader(e,2)}}
                                />
                                <CheckBox
                                    containerStyle={{backgroundColor:'#fff',margin:3,width: width <= 500 ? 'auto': '10%'}} 
                                    title={ header.role_status === true ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
                                    checked={header.role_status}
                                    onPress = {()=>{handleRoleStatus()}}
                                />
                            </View>
                        )
                    })
                }
                 <View style={{justifyContent:'center',alignItems:'center',backgroundColor:'#9999FF'}}>
                     <Text style={{margin:5,fontSize:20,color:'#fff'}}>กำหนดสิทธิ์เมนู</Text>
                 </View>
                 <View>
                     <CheckBox title={'เลือกทั้งหมด'} checked={checkAll} onPress={() => {handleCheckAll()}} />
                </View>
                {
                    screen && screen.map((screen,index)=>{
                        return (
                            <View key={screen.id}>
                                    <CheckBox 
                                    title   = {screen.screen_name}
                                    checked = {screen.checked}
                                    checkedColor={'green'}
                                    onPress = {() => {handleChecked(index)} }
                                    />
                            </View>
                        )
                    })
                }
                <View style={{flexDirection:'row',margin:10,justifyContent:'flex-end',padding:10}}>
                      <Button
                        style={{margin:5}}
                         buttonStyle={{backgroundColor:'green'}}
                        icon = {
                            <Icon
                                style={styles.icon} 
                                name={'save'}
                                color={'#fff'}
                                size={18}
                            />  
                        } 
                        title={'บันทึก'}
                        onPress = { () => {save()} }
                      />
                      <Button
                        style={{margin:5}}
                        buttonStyle={{backgroundColor:'#990000'}}
                        icon = {
                            <Icon
                                style={styles.icon} 
                                name={'trash'}
                                color={'#fff'}
                                size={18}
                            />  
                        } 
                        title={'ลบกลุ่มสิทธิ์นี้'}
                        onPress = { () => {deletedMasterGroupPermission()} }
                      />
                </View>
        </SafeAreaView>
    )
    }
export default Detail_group_permission