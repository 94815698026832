import axios from 'axios';
import React from 'react'
import { View,ScrollView,SafeAreaView,FlatList,Switch,Dimensions } from 'react-native';
import { Avatar,Card,Input,Button,ListItem,Badge,SearchBar,Image,CheckBox,Text} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../lib/style'
import {Picker} from '@react-native-picker/picker';
import Modal from "modal-enhanced-react-native-web";
import  config from '../../../Api/config'
const {width,height} = Dimensions.get('window')
export default class  genForm  extends React.Component {
    constructor(props){
        super(props)
       
        this.input = React.createRef();
        this.name = React.createRef()
        this.state={
            number : 1,
            question : [{id:1,question : '',type_ans: 'text',require_ans:false,dropdown_role:{id:0,value:'ทุกแผนก'},list_type:[]}],
            name : 'ชื่อฟอร์ม',
            description : 'คำอธิบาย',
            groups : [],
            approve_department:[{department_id:0,department_name: 'ทุกแผนก'}],
            checkAll : false,
            status_form : true,
            reuse :  true,
            selected : 'ทุกแผนก',            
            department: [],
            listCheckbox: [],
            form_type : false,
        }
        this.handleQ = this.handleQ.bind(this)
        this.onChangeText = this.onChangeText.bind(this)
    }
    componentDidMount(){
        this.listGroupsPermissionForm()
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.listGroupsPermissionForm()
          });
    }
    
    componentWillUnmount(){
        this._unsubscribe()
    }
    listGroupsPermissionForm = () =>{
        axios.get( config.uri.api + 'api/form/listGroupsPermissionForm')
        .then(res=>{
            this.setState({groups:res.data.data,department: res.data.department})
        }).catch(err=>{
            console.log(err.message)
        })
    }
   
    handleQ = (value,index_Q)=>{
        let text = this.state.question
        text.map((row,i)=>{
            if (i == index_Q)
             row.quiz = value
            return row
        })
        this.setState({question : text})
    }
    handleTitleRadio = (e,idQuestion,idRadio) => {
        let text = e.nativeEvent.text
        let new_question = this.state.question
        new_question =  new_question.map((q) => {
            if (q.id === idQuestion) {
                q.list_type.map((l) => {
                    if (l.id == idRadio) {
                        l.title = text
                    }
                    return l
                })
            }
            return q
        })
        this.setState({question: new_question},() => {console.log('question : ',this.state.question)})
    }
    showAnswer = (inputType,id,list_type)=>{
        if (inputType === 'text'){
            return (
                <Input
                    placeholder='คำตอบ'
                    disabled={true}
                />
            )
        }else if (inputType === 'radio'){
            
            return (
                <View style={{margin:'2%'}}>
                     {
                           list_type && list_type.map((row,rIndex) => {
                               return (
                                <View style={{flexDirection:'row',width:'auto'}}>
                                    <CheckBox 
                                        key={row.id}
                                        style={{backgroundColor:'transparent',borderWidth:0}}
                                        disabled={true}
                                        checked = {row.status}
                                    />
                                    <Input
                                        key={row.id}
                                        containerStyle= {{width:width <= 500 ? 'auto' : '25%'}} 
                                        style={{width: '100%'}}
                                        inputStyle ={{padding:'1%'}}
                                        value={row.title}
                                        onChange = {(e) => {this.handleTitleRadio(e,id,row.id)}}
                                    />
                                </View>
                               )
                           })
                       } 
                </View>     
            )
        }else if (inputType === 'uploadFile'){
            return (
                <View style={styles.row}>
                    <Button 
                     disabled={true}
                     titleStyle={{fontSize:10}}
                     title={'อัพโหลดไฟล์'}
                    />
                </View>
            )
        }else{
            return (null)
        }
    }
    onChangeText = async (e,idQuestion) =>{
        let text = e.nativeEvent.text
        let q = this.state.question
        q =  q.map((row,i)=>{
            if (row.id === idQuestion)
            row.question = text
            return row
        })
        this.setState({question : q})
        
    }
    require = (id)=>{
        let q = [...this.state.question]
        q = q.map((row,i)=>{
            if (row.id == id)
            row.require_ans = !row.require_ans
            return row
        })
        this.setState({question : q})

    }
    departmentChange = (selected,id) =>{
       let new_role = {id:0,value:''}
       let department = this.state.department
       department.map(row=>{
           if (row.name === selected){
            new_role.id = row.id
            new_role.value = row.name
           }
           
       })
       let new_question = this.state.question.map((row,index)=>{
           if (row.id === id){
            row.dropdown_role = new_role
           }
            return row
        })
          this.setState({question: new_question})
        
    
    }
     form = () =>{
         return (
           this.state.question.map((d,i)=>{
                return (
                        <SafeAreaView style={styles.main}>
                            <View  style={styles.chooseType}>
                                    <Picker
                                    enabled={this.state.reuse === false ? true:false}
                                    style={styles.picker} 
                                    selectedValue={d.dropdown_role.value}
                                    onValueChange={(value) => this.departmentChange(value,d.id) }>
                                    {
                                    this.state.department.map((department,key)=>{
                                         return (
                                            <Picker.Item   label={department.name} value={department.name} />
                                         )
                                     })
                                    // this.state.d.map((row=>{
                                    //     return(
                                    //         <Picker.Item key={row.id}  label={row.name} value={row.name} />
                                    //     )
                                    // }))
                                    }  
                                    </Picker> 
                                    <Picker 
                                            style={styles.picker} 
                                            selectedValue={d.type_ans}
                                            onValueChange={(value,index) => this.onValueChangeType(value,index,d.id) }>
                                            <Picker.Item  label="คำตอบสั้นๆ" value="text" />
                                            <Picker.Item  label="ช่องทำเครื่องหมายติ๊กเลือก" value="radio" /> 
                                            <Picker.Item  label="แนบเอกสาร" value="uploadFile" /> 
                                    </Picker>  
                                        <CheckBox
                                        
                                        containerStyle={styles.checkbox} 
                                        title='จำเป็น'
                                        checkedColor='green'
                                        uncheckedColor='red'
                                        checked={d.require_ans}
                                        onPress={()=>this.require(d.id)}
                                        />
                                        <Icon
                                        onPress={()=>this.addQ(this)}
                                        style={styles.icon}
                                        color= 'green'
                                        name = 'plus-circle'
                                        size={25}
                                        />
                                        
                                        <Icon
                                            onPress={()=>this.removeQ(d.id)}
                                            style={styles.icon}
                                            color= 'red'
                                            name = 'minus-circle'
                                            size={25}
                                        />
                            </View>
                            
                            <View style={{display:'flex',flexDirection:'column',margin:10,padding:10}}>
                                <Input
                                    style={{padding:5}}
                                    ref={this.input}
                                    label='คำถาม'
                                    placeholder={`กรอกคำถาม #${d.id}`}
                                    onChange = {(e)=>this.onChangeText(e,d.id)}
                                    value ={d.question}
                                />
                                    {
                                    this.showAnswer(d.type_ans, d.id,d.list_type)
                                    }
                            </View>           
                        </SafeAreaView>
                ) 
               })
         )    
    }
    onValueChangeType =  (value,index_dd,id)=>{
        console.log('value : ',value)
        let question   = this.state.question
         question.map( (row,i)=>{
            if (row.id == id) {
                row.type_ans = value
                if (value === 'radio') {
                    row.list_type = [{id:1,title:'',status: false},{id:2,title: '',status: false}]
                }else{
                    row.list_type = []
                }
            }
            return  row
        })       
            this.setState({question : question},() => console.log('question : ',this.state.question))
    }
    addQ =  ()=>{
    let number = this.state.number + 1
    console.log('num id question : ',number)
    this.setState(prevState =>({ question: [...prevState.question,{id: number,question: '',type_ans:'text',require:false,dropdown_role:{id:0,value:'ทุกแผนก'},list_type:[]}],number: number}),() => {console.log('state',this.state.question,this.state.number)})
    }
    removeQ = (id)=>{
        let q = this.state.question
        if (this.state.question.length <= 1){
            alert('ต้องมีคำถามอย่างน้อย 1 คำถาม')
        }else{
            q = q.filter((row,i)=>{
                return   row.id !== id
            })
        }
        this.setState({question : q})
    }
    saveForm = () =>{
        let checkRadio = []
        let q = this.state.question
        let checkq = q.filter((row,i)=>{
            if (row.question.trim() === '')
             return i+1
        })
        let setPermission = this.state.groups.filter(row=>{
             return row.checked === true
        })
        let nullTitleRadio  = 0
        let QuestionRadio = q.filter(row=>{ return row.type_ans === 'radio'})
        QuestionRadio.map(q => {
            q.list_type.map(list => {
                if (!list.title) {
                    checkRadio.push({id:list.id})
                }
            })
        })
        console.log('radio is null : ',checkRadio.length)
        console.log('checktitleRadio : ',checkRadio)
        console.log('nullTitleRadio : ',nullTitleRadio)
        if (this.state.name.trim() === '' || this.state.name.trim() === 'ชื่อฟอร์ม'){
            this.name.current.focus()
            this.name.current.clear()
            return alert('กรุณากรอกชื่อฟอร์ม')
        }
        if (checkq.length > 0)  return alert('ยังไม่ได้ใส่คำถาม '+ checkq.length + ' ข้อ' )
        if (setPermission.length <= 0)  return alert('กำหนดสิทธิ์การใช้ฟอร์มอย่างน้อย 1 กลุ่ม' )
        if (checkRadio.length > 0) return alert('พบ checkbox ยังไม่ระบุข้อความ')
        return axios.post(config.uri.api +'api/form/saveform',{params:{data: this.state.question,name : this.state.name, description: this.state.description,groups: this.state.groups,reuse: this.state.reuse, approve_department: this.state.approve_department}})
            .then(res=>{
                
                if (res.data.status === 'error'){
                    alert(res.data.data)
                }else{
                    alert(res.data.data)
                    this.props.navigation.pop()
                }
              
            }).catch(e=>{
                console.log(e.message)
            })
        
    }
    cancle= ()=>{
        let q = [{question : '',type_ans: 'text',require_ans:false}]
        this.setState({question: q,name: 'ชื่อฟอร์ม',description:'คำอธิบาย'})
    }
    handlecheckAll = ()=>{
        let g = this.state.groups.map(row=>{
            row.checked = !this.state.checkAll
            return row
        })
        console.log('update check groups : ',g)
        this.setState({groups: g,checkAll  : !this.state.checkAll})
    }
    handlecheck = (id) =>{
       
        let update = this.state.groups.map(row=>{
            if (row.id == id)
            row.checked = !row.checked
            return row
        })
        console.log('update unique id : ', id,update)
        this.setState({groups: update,checkAll: false})
    }
    groupLine = ({item}) =>{
        return (
                     
             <CheckBox 
              containerStyle={{  backgroundColor:'transparent',
              borderWidth:0,
              width:'50%'
              }}
              title={item.group_name}
              checked={item.checked}
              onPress={()=>this.handlecheck(item.id)}
              />                    
         
        )
    }
    toggleSwitch = (value,id) =>{
       
        switch(id){
            case 0 :
                this.setState({status_form: value})
            break
            case 1 :
                this.setState({reuse: value})
            break
        }
    }
    onChangeApprove = (department_name) => {
       
        let new_data = []
        let department = this.state.department
        department.map(d=>{
            if (d.name === department_name){
               new_data.push({department_id: d.id,department_name: d.name})
            }
        })
      
        this.setState({approve_department: new_data});
    }
    render(){
        return (
            <SafeAreaView style={{backgroundColor:'#F5F5F5'}}>
                <ScrollView>
                    <View style={styles.header}>
                        <View style={width <= 500 ? {margin:10,padding:20,flexDirection:'row',flexWrap: 'wrap'} : styles.row}>
                            <Text>สถานะฟอร์ม : {this.state.status_form === true ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</Text>
                            <Switch 
                            style={{marginLeft:10,marginRight:10}}
                            trackColor={{ false: "red", true: "#64baaa" }}
                            thumbColor={ this.state.status_form === true ? "#f4f3f4" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={(value)=>{this.toggleSwitch(value,0)}}
                            value={this.state.status_form}
                            />
                            <Text> ประเภทฟอร์ม : {this.state.reuse === true ? 'ปกติ' : 'ใช้ครั้งเดียว'}</Text>
                            <Switch 
                            style={{marginLeft:10,marginRight:10}}
                            trackColor={{ false: "red", true: "#64baaa" }}
                            thumbColor={ this.state.reuse === true ? "#f4f3f4" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={(value)=>{this.toggleSwitch(value,1)}}
                            value={this.state.reuse}
                            />
                             <Text> กำหนดแผนกอนุมัติ : </Text>
                            <Picker
                                 
                                selectedValue={this.state.approve_department[0].department_name}
                                onValueChange = {(value)=>this.onChangeApprove(value)}
                            >
                            {
                                this.state.department.map((department)=>{
                                    return(
                                        <Picker.Item label={department.name} value={department.name} />
                                    )
                                })
                            }
                            </Picker>
                        </View>
                        <View>
                            <Input
                                ref={this.name}
                                containerStyle={styles.inputhead}
                                style={{padding:5,width:'80%'}}
                                placeholder='กรอกชื่อฟอร์ม'
                                onChangeText={value=>this.setState({name: value})}
                                value={this.state.name}
                            />
                            <Input
                                
                                containerStyle={styles.input_des}
                                style={{padding:5,width:'80%',fontSize:'15px'}}
                                placeholder='คำอธิบายฟอร์ม'
                                onChangeText={value=>this.setState({description: value})}
                                value={this.state.description}
                            />
                        </View>
                       
                    </View>
                    <View style={styles.main}>
                            <View>
                                <Text>กำหนดสิทธิ์การใช้ฟอร์มของกลุ่ม</Text>
                                <CheckBox 
                                    containerStyle={{backgroundColor:'transparent',
                                    borderWidth:0,
                                    width:'auto'}} 
                                    title={'เลือก/ไม่เลือกทั้งหมด'} 
                                    checked={this.state.checkAll} 
                                    onPress={()=> this.handlecheckAll()} />
                            </View>
                            <View style={{width:'auto',flexDirection: 'row'}}>
                                <FlatList 
                                    data={this.state.groups}
                                    numColumns={2}
                                    keyExtractor={(item,index) =>item.id.toString()}
                                    renderItem = {this.groupLine}
                                />
                            </View>  
                    </View>
                    {
                     this.form()
                     }
                     <SafeAreaView style={styles.flex_btn}>
                         <Button
                            style={styles.btn_action}
                            buttonStyle={{backgroundColor:'#336699'}}
                            icon={
                            <Icon
                                style={styles.icon}
                                name="save"
                                color='#fff'
                                size={15}
                            />}
                            title={ this.state.question.length <= 0 ? 'บันทึก (' + 1 + ')' : 'บันทึก (' + this.state.question.length + ')'}
                            onPress={()=> this.saveForm()}
                         />
                         <Button
                            style={styles.btn_action}
                            buttonStyle={{backgroundColor:'#a50b0b'}}
                            icon={
                            <Icon
                                style={styles.icon}
                                name="ban"
                                color='#fff'
                                size={15}
                            />}
                            title='ยกเลิก'
                            onPress={()=> this.cancle()}
                         />
                     </SafeAreaView>
                </ScrollView>
            </SafeAreaView>
        )
    }
   
}