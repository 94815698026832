import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    textHeader: {
      fontWeight: 'bold',
      fontSize: 25,
      alignItems: 'center',
      padding:5,
      bottom:0
    },
    textDesc:{
      alignItems: 'center',
      fontSize: 22,
      alignItems: 'center',
      padding:5,
      bottom:0
    },
    icon : {
      padding:5
    },
   
     main :{
      backgroundColor: '#fff',
      borderWidth:1,
      borderLeftWidth:10,
      borderLeftColor:'#9999FF',
      borderColor:'#9999CC',
      display:'flex',
      margin: 10,
      padding: 10,
      borderRadius: 10,
      flexDirection:'column',
      color:'#000',
      width:'auto'
      
     },
     header:{
      backgroundColor: '#fff',
      display:'flex',
      borderWidth:1,
      borderLeftWidth:10,
      borderTopWidth:10,
      borderLeftColor:'#9999FF',
      borderColor:'#9999CC',
      margin:10,
      padding:10,
      height:170,
      flexDirection:'column',
      borderRadius: 10,
     },
     headerApprove:{
      display:'flex',
      borderWidth:1,
      borderLeftWidth:10,
      borderTopWidth:10,
      borderLeftColor:'#9999FF',
      borderColor:'#9999CC',
      margin:10,
      padding:10,
      height:'auto',
      flexDirection:'column',
      borderRadius: 10,
      backgroundColor:'#fff',
     },
     row : {
      width:'80%',
      display:'flex',
      margin: 10,
      flexDirection:'row',
      textAlign:'left'
     
     },
     chooseType:{
      display:'flex',
      margin: 5,
      padding: 1,
      flexDirection:'row',
      justifyContent:'flex-end',
      textAlign:'center',
      alignItems:'center',

     },
     checkbox:{
       backgroundColor:'transparent',
       borderWidth:0
     },
     picker:{
     width:'15%',
     borderRadius: 5,
     borderWidth:1,
     color:'#000',
     fontSize:'15px',
     marginLeft:10,
     marginRight: 10,
     height:40
     },
     inputhead:{
      padding:10,
      margin:5,
      width:'60%',
      fontSize:'15px',
      height:40,
      borderColor:'#9999CC',
      '::placeholder': {
      color: '#000000', // Replace with your desired color
      opacity: 1, // Ensure placeholder is fully visible
    },
     },
     ansText:{
      paddingLeft:10,
      paddingTop:10,
      paddingRight:10,
      margin:5,
      width:'60%',
      fontSize:'15px',
      height:40,
      // borderColor:'#9999CC',
      borderBottomWidth: 2,
      borderBottomColor: '#999',
     },
     input_des:{
      padding:10,
      margin:5,
      width:'60%',
      fontSize:'10px',
      height:5,
      borderColor:'#9999CC',
     },
     btn_action:{
      fontSize:'7px',
      width:135,
      textAlign: 'center',
      padding:10
    },
    
    flex_btn:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-end',
      alignItems:'center',
      marginLeft: 10,
    },
    Question:{
      padding:5,
      fontWeight:200,
      fontSize:20,
    },
    
    detailFormApprove :{
       flexDirection: 'row',
       margin:5

    },
    textApproveForm:{
      fontSize:18,
      padding:5,
      color:'gray'
    },
    text_listForm:{
      flexDirection: 'row',
      flexWrap: 'wrap',
      fontSize:16,
      textAlign:'left',

    },
    modalContent: {
      backgroundColor:'#fff',
      padding: 22,
      margin: 10,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      borderColor: "rgba(0, 0, 0, 0.1)"
  },
    warning:{
      fontSize:15,
      color:'red',
      margin:10
    },
    modalUpload: {
      padding:10,
      backgroundColor:'#fff',
      borderRadius: 10,
    }
  });
 
export default styles