import axios from 'axios';
import React from 'react'
import { View,ScrollView,SafeAreaView,Text,ActivityIndicator,FlatList,TouchableHighlight } from 'react-native';
import { Avatar,Card,Input,Button,ListItem,Badge,SearchBar,Image,CheckBox} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../lib/style'
import {Picker} from '@react-native-picker/picker';
import Modal from "modal-enhanced-react-native-web";
import  config from '../../../Api/config'
const dayjs = require('dayjs')
export default class  listForm  extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            Form : [],
            temp : [],
            loading: false,
            status: '',
            search: '',
            valueDropdown: 'All'
        }
    }
    getListForm = async () =>{
        axios.get(config.uri.api + 'api/form/getListFormTotal')
        .then(res=>{
            this.setState({Form:res.data.data,temp:res.data.data,status:res.data.status,loading:true})
        }).catch(err=>{
            this.setState({Form:err.message,loading:true,status:'error'})
            console.log(err);
        })
    }
    componentDidMount(){
        this.getListForm()
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            console.log('refresh getListForm');
            this.getListForm()
        })
    }
    componentWillUnmount(){
        this._unsubscribe()
    }
    onValueChangeType = (value)=>{
        console.log('You selected : ',value)
        let temp = this.state.Form
        let new_row = []

        if (value === 'All'){ return this.setState({temp: temp,valueDropdown:value})}

        new_row =  this.state.Form.filter(row=>{
            // let date = new Intl.DateTimeFormat('th-TH',config.optionsDate).format(new Date(row.system_date))
            let date = dayjs(row.system_date).format('DD/MM/YYYY HH:mm:ss');
            return  row.name_form.toLowerCase().includes(this.state.search.toLowerCase()) && row.isActive.includes(value) 
            ||  date.toLowerCase().includes(this.state.search.toLowerCase()) && row.isActive.includes(value)
        })
        return this.setState({temp: new_row, valueDropdown:value})
    }
    updateSearch = (value) =>{
        let word = value.trim()
        let old_data = this.state.Form
        if (value.length >= 0) {  this.setState({search: word})}
        if (this.state.search.trim().length <= 0 && this.state.valueDropdown === 'All'){ this.setState({temp: old_data})}
        if (this.state.valueDropdown === 'All'  ) {  this.handleAllFilter(word,this.state.Form)}
        if  (this.state.valueDropdown !==  'All') {  this.handleFilter(word,this.state.Form)}
    }
    detail = ({item,index}) =>{
        // let date = new Intl.DateTimeFormat('th-TH',config.optionsDate).format(new Date(item.system_date)) + ' น.'
        let date = dayjs(item.system_date).format('DD/MM/YYYY HH:mm:ss');
        return (
            <SafeAreaView style={styles.main}>
                <ScrollView>
                <TouchableHighlight onPress={() =>this.listFormDetail(item.id,item.name_form)} >
                    <View style={{flexDirection: 'row',margin:20}}>
                         <View>
                              <Text style={styles.text_listForm}>ชื่อฟอร์ม : </Text>
                              <Text style={styles.text_listForm}>คำอธิบาย : </Text>
                              <Text style={styles.text_listForm}>จำนวนคำถาม : </Text>
                              <Text style={styles.text_listForm}>เปิดใช้งาน : </Text>
                              <Text style={styles.text_listForm}>วันที่สร้าง : </Text>
                         </View>
                         <View>
                              <Text style={styles.text_listForm}>{item.name_form}</Text>
                              <Text style={styles.text_listForm}>{item.description}</Text>
                              <Text style={styles.text_listForm}>{item.question.length}</Text>
                              <Text style={styles.text_listForm}>{item.isActive === 'Y' ? '✔️' : '❌'}</Text>
                              <Text style={styles.text_listForm}>{date}</Text>
                         </View>
                    </View>
                </TouchableHighlight>
                </ScrollView>
            </SafeAreaView>
        )
    }
    listFormDetail = (id,name) =>{
       this.props.navigation.navigate('listFormDetail',{id: id,name: name})
    }
    Form_create = () => {
        this.props.navigation.navigate('gen_form')
    }
    handleAllFilter = (word,form) => {
       let getDate = form.filter(f=>{
           return dayjs(f.system_date).format('DD/MM/YYYY HH:mm:ss').toString().includes(word)
        })
       let name_form = form.filter(f=>{return f.name_form.toString().includes(word)}) 
       if (name_form.length > 0) return this.setState({temp: name_form})
       if (getDate.length  > 0) return this.setState({temp: getDate})
    }
    handleFilter = (word,form) =>{
        let getDate = form.filter(f=>{
            return dayjs(f.system_date).format('DD/MM/YYYY HH:mm:ss').toString().includes(word)
             && f.isActive === this.state.valueDropdown
         })
        let name_form = form.filter(f=>{return f.name_form.toString().includes(word) && f.isActive === this.state.valueDropdown }) 
        if (name_form.length > 0) return this.setState({temp: name_form})
        if (getDate.length  > 0) return this.setState({temp: getDate})
    }
    render() {
        if (this.state.loading === false){
            return (
                <SafeAreaView style={styles.container}>
                    <ActivityIndicator size={'large'} color={'#33FF66'} />
                </SafeAreaView>
            )
        }else if (this.state.status === 'error'){
            return (
                <SafeAreaView style={styles.container}>
                    <Text>{this.state.temp}</Text>
                </SafeAreaView>
            )
        }else{
            return (
                <View style={{backgroundColor:'#F5F5F5'}}>
                     <Picker style={{margin:10,padding:10,height: 50,borderWidth:0,color:'gray',fontSize:15,backgroundColor:'#fff'}} 
                        selectedValue={this.state.valueDropdown}
                        onValueChange={(itemValue) => this.onValueChangeType(itemValue) }>
                        <Picker.Item label="All" value="All" /> 
                        <Picker.Item label="เปิดใช้งาน" value="Y" />
                        <Picker.Item label="ปิดใช้งาน" value="N" /> 
                    </Picker> 
                    <SearchBar
                        round = {true}
                        placeholder= 'ค้นหา...'
                        onChangeText = {this.updateSearch}
                        value={this.state.search}
                        containerStyle={{backfaceVisibility:'hidden',backgroundColor:'#fff',borderWidth:0,borderTopWidth:0,borderBottomWidth:0,margin:10}}
                        inputContainerStyle={{backgroundColor: '#fff',width:'100%',height:'10px',borderWidth:0}}
                    />
                    <View>
                        <Button
                            buttonStyle={{backgroundColor:'green'}}
                            icon={
                                <Icon name={'plus-circle'} style={styles.icon} color='#fff' size={20} />
                            }
                            style={{width:150,margin:10}}
                            title={'สร้างฟอร์ม'}
                            onPress={()=> this.Form_create()}
                        />
                    </View>
                    <FlatList 
                        data={this.state.temp}
                        keyExtractor={(item,index) => item.id.toString()}
                        renderItem = {this.detail}
                    />
                </View>
              
            )
                
        }
       
    }
}   