import axios from 'axios';
import React from 'react'
import { View,ScrollView,SafeAreaView,Text,ActivityIndicator,FlatList,Switch,Dimensions } from 'react-native';
import { Avatar,Card,Input,Button,ListItem,Badge,SearchBar,Image,CheckBox  } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import styles from '../../lib/style'
import {Picker} from '@react-native-picker/picker';
import Modal from "modal-enhanced-react-native-web";
import  config from '../../../Api/config'
const {width, height} = Dimensions.get('window')
export default class duplicateForm extends React.Component {
     constructor(props) {
         super(props)
         this.state = {
            loading: false,
            tempname : '',
            tempDesc : '',
            Form: props.route.params.Form,
            Question  : props.route.params.Question,
            groups: props.route.params.groups,
            checkAll: props.route.params.checkAll,
            department: props.route.params.department,
         }
     }
     toggleSwitch = (value,id)=>{
        let change 
        let updateQ
        // let reuse
        switch (id){
            case 0 :
                let v = (value === true ? 'Y' : 'N')
                 change = this.state.Form.map(row=>{
                    row.isActive = v
                    return row
                })
                this.setState({Form: change})
            break
            case 1 :
                 change = this.state.Form.map(row=>{
                    row.re_use = value
                    return row
                })
                 updateQ = this.state.Question.map(row=>{
                    row.re_use = value
                    return row
                 })
                 this.setState({Form: change,Question: updateQ})
            break
        }
       
       
    }
    onChangeText = (value) =>{
       let name = this.state.Form.map((row)=>{
            row.name_form = value
            return row
        })
        this.setState({Form: name,tempname: value})
    }
    onChangeDesc = (value) =>{
       let desc = this.state.Form.map((row)=>{
            row.description = value
            return row
        })
        this.setState({Form: desc,tempDesc: value})
    }
    onChangeTextQuestion =  (e,id) =>{
        let text = e.nativeEvent.text
        let q = this.state.Question
        q =  q.map((row,i)=>{
            if (row.id === id)
            row.question = text
            return row
        })
        this.setState({Question : q})
    }
   
    require = (id)=>{
        let new_data = this.state.Question.map((row,index)=>{
            if (row.id === id)
             row.require_ans = (row.require_ans === 'true' ? 'false'  : 'true')
             return row
        })
        this.setState({Question: new_data})
    }
    onValueChangeType =  (value,index_dd,idQuestion)=>{
        console.log('idQuestion : ',idQuestion,this.state.Question)
        let question   = this.state.Question.map((row,i)=>{
            if (row.id === idQuestion) {
                row.type_ans = value
                if (row.type_ans === 'radio' && value === 'radio') {
                    row.list_type = ( !row.list_type || row.list_type.length <= 0 ? [{id:1,title: '',status: false},{id: 2,title: '',status: false}] : row.list_type)
                    console.log('list_type : ',row.list_type)
                }
            }
           
            return  row
        })
        this.setState({Question : question})
    }
    addQuestion = () =>{
        let question = this.state.Question
        let last_id =  question[question.length-1]
        this.setState(prevState =>({ Question: [...prevState.Question,{
             id: last_id.id + 1
            ,question: ''
            ,master_line_form_id : this.state.id
            ,type_ans:'text'
            ,require_ans:false
            ,re_use: this.state.Form[0].re_use,
            department_id:1,
            department:{id:1, name: "ทุกแผนก"},
            list_type: []
            }]}))
    }
    removeQuestion = (id) =>{
        let q;
        let data 
        if (this.state.Question.length <= 1){
            alert('ต้องมีคำถามอย่างน้อย 1 คำถาม')
            return
        }else{
           data = this.state.Question.filter((row)=>{
                return row.id === id
            })
            q = this.state.Question.filter((row,i)=>{
                return   row.id !== id
            })
        }
        this.setState(prevState =>({Question: q }));
    }
    handlecheckAll = ()=>{
        let g = this.state.groups.map(row=>{
            row.checked = !this.state.checkAll
            return row
        })
        this.setState({groups: g,checkAll  : !this.state.checkAll})
    }
    handlecheck = (id) =>{
        let update = this.state.groups.map(row=>{
            if (row.id == id)
            row.checked = !row.checked
            return row
        })
        this.setState({groups: update,checkAll: false})
    }
    saveQuestion = () =>{
        let checkRadio = []
        let  chk_question = this.state.Question.filter((row,i)=>{
            return row.question.trim() === '' || row.question.trim() === null
        })
        let permissions_group = this.state.groups.filter(row=>{
            return row.checked === true
        })
        let QuestionRadio = this.state.Question.filter(row=>{ return row.type_ans === 'radio'})
        QuestionRadio.map(q => {
            q.list_type.map(list => {
                if (!list.title) {
                    checkRadio.push({id:list.id})
                }
            })
        })
        if (chk_question.length > 0) return alert('ยังไม่ได้ตั้งคำถาม ' + chk_question.length + ' ข้อ')
        if (permissions_group.length <= 0) return  alert('โปรดกำหนดสิทธิ์การใช้ฟอร์มของกลุ่มอย่างน้อย 1 กลุ่ม')
        if (checkRadio.length > 0) return alert('พบ checkbox ยังไม่ระบุข้อความ')
        return axios.post(config.uri.api + 'api/form/saveDuplicateForm',{params:{
                groups: this.state.groups,
                question: this.state.Question,
                form : this.state.Form,
            }}).then(res=>{
                if (res.data.status === 'error'){
                    alert(res.data.data)
                }else{
                    alert(res.data.data)
                    // this.props.navigation.goBack()
                }
            }).catch(err=>{
                console.log(err.message)
            })
        
      
    }
    delForm  = () =>{
        this.setState({modalVisible: false})
        axios.post(config.uri.api + 'api/form/delForm',{params:{
            id : this.state.id
        }}).then(res=>{
            if (res.data.status === 'error'){
                alert(res.data.data);
            }else{
                alert(res.data.data);
                this.props.navigation.goBack()
            }
        }).catch(err=>{
            alert(err.message)
            console.log(err.message)
        })
    }
    departmentChange = (selected,q_id) =>{
        let new_data = {id:1 ,name: 'ทุกแผนก'}
        console.log('selected department : ',selected)
        let new_department = this.state.department.filter(row=>{
            return row.name == selected
        })
        new_data ={id: new_department[0].id, name: new_department[0].name}
        let updateQuestion = this.state.Question.map(row=>{
            if (row.id == q_id){
                row.department_id = new_department[0].id
                row.department = new_data
            }
            return row
        })
        this.setState({Question: updateQuestion})
    }
    onchangeApprove = (department_name) => {
           
        let department = this.state.department
        let Form = this.state.Form
        department = department.filter(d=>{return d.name === department_name})
        Form = Form.map(form=>{
            form.approve_by = department
            return form
        }) 
        this.setState({Form: Form}) 
    }
    handleTitleRadio = (e,idQuestion,idRadio) => {
        let text = e.nativeEvent.text
        let new_question = this.state.Question
        new_question =  new_question.map((q) => {
            if (q.id === idQuestion) {
                q.list_type.map((l) => {
                    if (l.id == idRadio) {
                        l.title = text
                    }
                    return l
                })
            }
            return q
        })
        this.setState({Question: new_question},() => {console.log('question : ',this.state.Question)})
    }
     render() {
         return (
            <SafeAreaView>
                <View>
                    <FlatList 
                        data={this.state.Form}
                        keyExtractor={(item,index) =>item.id.toString()}
                        renderItem={({ item }) => (
                            <View style={styles.headerApprove}>
                    
                            <View style={styles.text_listForm}>
                                <Input placeholder={item.name_form} 
                                    containerStyle={styles.inputhead}
                                    style={{padding:5,width:'60%'}}
                                    onChangeText={value=>this.onChangeText(value)} 
                                    value={item.name_form}
                                />
                                    <Input placeholder={item.description} 
                                    containerStyle={styles.inputhead}
                                    style={{padding:5,width:'60%'}}
                                    onChangeText={value=>this.onChangeDesc(value)} 
                                    value={item.description}
                                />  
                            </View>
                            <View style={{flexDirection:'row', margin:20}}>
                                <View >
                                    <Text style={styles.text_listForm}>เปิดใช้งานฟอร์ม</Text>
                                    <Text style={styles.text_listForm}>ประเภทฟอร์ม ({item.re_use === true ? 'ปกติ' : 'ใช้ครั้งเดียว'}) </Text>
                                    <Text style={styles.text_listForm}>กำหนดแผนกอนุมัติฟอร์ม </Text>
                                </View>
                                <View >
                                    <Switch 
                                            style={{marginLeft: 10,marginRight:10}}
                                            trackColor={{ false: "red", true: "#64baaa" }}
                                            thumbColor={item.is_active === 'Y' ? "#f4f3f4" : "#f4f3f4"}
                                            ios_backgroundColor="#3e3e3e"
                                            onValueChange={(value)=>{this.toggleSwitch(value,0)}}
                                            value={item.isActive === 'Y' ? true : false}
                                    />
                                    <Switch 
                                            style={{marginLeft: 10,marginRight:10}}
                                            trackColor={{ false: "red", true: "#64baaa" }}
                                            thumbColor={item.re_use  === true ? "#f4f3f4" : "#f4f3f4"}
                                            ios_backgroundColor="#3e3e3e"
                                            onValueChange={(value)=>{this.toggleSwitch(value,1)}}
                                            value={ item.re_use}
                                    />
                                     <Picker
                                    style={{marginLeft:10,marginTop:2,marginRight:10}}
                                    selectedValue={item.approve_by[0].name}
                                    onValueChange={(value) => {this.onchangeApprove(value)}}
                                    >
                                    {
                                        this.state.department.map(row=>{
                                            return(
                                                <Picker.Item label={row.name} value={row.name} />
                                            )
                                        })
                                    }
                                </Picker>
                                </View>        
                        </View>  
                    </View>
                        )}
                    />
                </View>
                <View style={styles.main}>
                        <View>
                            <Text>กำหนดสิทธิ์การใช้ฟอร์มของกลุ่ม</Text>
                            <CheckBox 
                                containerStyle={{backgroundColor:'transparent',
                                borderWidth:0,
                                width:'auto'}} 
                                title={'เลือก/ไม่เลือกทั้งหมด'} 
                                checked={this.state.checkAll} 
                                onPress={()=> this.handlecheckAll()} />
                        </View>
                        <View style={{width:'auto',flexDirection: 'row'}}>
                            <FlatList 
                                data={this.state.groups}
                                numColumns={2}
                                keyExtractor={(item,index) =>item.id.toString()}
                                renderItem={({ item }) => (
                                    <CheckBox 
                                    containerStyle={{  backgroundColor:'transparent',
                                    borderWidth:0,
                                    width:'50%'
                                    }}
                                    key={item.id}
                                    title={item.group_name}
                                    checked={item.checked}
                                    onPress={()=>this.handlecheck(item.id)}
                                    />   
                                )}
                            />
                        </View>  
                </View>
                <View >
                    <FlatList 
                        data={this.state.Question}
                        keyExtractor={(item,index) =>item.id.toString()}
                        renderItem = {({ item })=>{
                            return(
                                <View style={styles.main}>
                                <View  style={styles.chooseType}>
                                        <Picker 
                                                enabled={item.re_use === true ? false : true}
                                                style={styles.picker} 
                                                selectedValue={item.department.name}
                                                onValueChange={(itemValue) => this.departmentChange(itemValue,item.id) }>
                                        {
                                                this.state.department.map((row=>{
                                                    return(
                                                        <Picker.Item key={row.id}  label={row.name} value={row.name} />
                                                    )
                                                }))
                                        }  
                                        </Picker>
                                        <Picker 
                                                style={styles.picker} 
                                                selectedValue={item.type_ans}
                                                onValueChange={(value,index) => this.onValueChangeType(value,index,item.id) }>
                                                <Picker.Item  label="คำตอบสั้นๆ" value="text" />
                                                <Picker.Item  label="ช่องทำเครื่องหมายติ๊กเลือก" value="radio" />
                                                <Picker.Item  label="แนบไฟล์" value="uploadFile" />  
                                        </Picker>
                                        <CheckBox
                                            containerStyle={styles.checkbox} 
                                            title='จำเป็น'
                                            checkedColor='green'
                                            uncheckedColor='red'
                                            checked={item.require_ans === 'true' ? true : false}
                                            onPress={()=>this.require(item.id)}
                                            />
                                        <Icon
                                            onPress={()=> this.addQuestion()}
                                            style={styles.icon}
                                            color= 'green'
                                            name = 'plus-circle'
                                            size={25}
                                            />  
                                        <Icon
                                            onPress={()=>this.removeQuestion(item.id)}
                                            style={styles.icon}
                                            color= 'red'
                                            name = 'minus-circle'
                                            size={25}
                                        />        
                                </View>
                                <View style={styles.text_listForm}>
                        <Input
                            style={{padding:5}}
                            ref={this.input}
                            label='คำถาม'
                            onChange = {(e)=>this.onChangeTextQuestion(e,item.id)}
                            value ={item.question}
                        />
                        {
                            item.type_ans === 'radio' ?
                            <View style={{margin:'2%'}}>
                                {
                                    item.list_type && item.list_type.map((row,rIndex) => {
                                        return (
                                        <View style={{flexDirection:'row',width:'auto'}}>
                                            <CheckBox 
                                                key={rIndex}
                                                style={{backgroundColor:'transparent',borderWidth:0}}
                                                disabled={true}
                                                checked = {row.status}
                                            />
                                            <Input
                                                key={rIndex}
                                                containerStyle= {{width:width <= 500 ? 'auto' : '100%'}} 
                                                style={{width: '100%'}}
                                                inputStyle ={{padding:'1%'}}
                                                value={row.title}
                                                onChange = {(e) => {this.handleTitleRadio(e,item.id,row.id)}}
                                            />
                                        </View>
                                        )
                                    })
                                } 
                            </View>
                            : item.type_ans === 'uploadFile' ? 
                             <View style={styles.row}>
                                    <Button
                                    disabled={true}
                                    title = {'แนบเอกสาร'}
                                    />
                             </View >
                            :
                             <Input
                             placeholder='คำตอบ'
                             disabled={true}
                            />
                        }
                     </View>
                    </View>
                        
                        
                            )
                        
                         
                        }}
                    /> 
                 </View>
                <View style={styles.flex_btn}>
                    <Button 
                            style={styles.btn_action}
                            buttonStyle={{backgroundColor:'#336699'}}
                            icon={
                            <Icon
                                style={styles.icon}
                                name="save"
                                color='#fff'
                                size={15}
                            />}
                            title={ 'บันทึก'}
                            onPress={()=> this.saveQuestion()}
                        />
                </View>
            </SafeAreaView>
         )
     }

}