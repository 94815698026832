import { StatusBar } from 'expo-status-bar';
import React,{useEffect,useState } from 'react';
import { StyleSheet, Text, View, ActivityIndicator,SafeAreaView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Input,Button, Badge, Image} from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'
import '@expo/match-media'
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import  config from '../../../Api/config'
import _liff from '../../lib/config-line-liff'
import CreateStaff from '../login/create_staff'
import styless from '../../lib/style'
import liff from '@line/liff';
import Home from './home';
const axios = require('axios')

export default function Login(props) {
  const {navigation} = props
  const [Id, SetId] = useState('');
  const [Pass,Setpass] = useState('');
  const [msg, Setmsg] = useState(''); 
  const [Line,getLine] = useState();
  const [status, SetStatus] = useState('');
  const [Data,SetData] = useState([]);
  const [ModalShow,setModalShow] = useState(false);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState('');
  useEffect(() => {
    fetchData()
      const unsubscribe = async function requestData() {
            await getLineLiff()
      }
      return unsubscribe;
  },[])
  const fetchData = async ()=>{
    await getLineLiff()
  }
  const getData = async (Line,EmailLine)=>{
    axios.post(`${config.uri.api}api/login/authen_staff`,{params: {line:  Line,email: EmailLine}})
    .then(response =>{
      handleError(response.data)
      // handleData(response.data.data)
      // SetData(response.data.data)
      setLoading(false)
    }).catch(err=>{console.log(err.message)})
  }
  const handleData = async (data) => {
   
    if (data.length <= 0){return SetData(data)}
    return NavigatorNext(data)
  }
  const handleError = (data) => {
     if (data.status === 'error') {
        SetStatus(data.status)
        setError(data.data)
        return 
     }
     return handleData(data.data)
  }
  const getLineLiff = async ()=>{
    await  liff.init({liffId:_liff.line_liff.line_login})
    .catch((e)=>{
      console.log(e.message)
      return Setmsg('ล็อคอิน Line ล้มเหลว โปรดปิดแอพแล้วทำการเปิดใหม่อีกครั้ง')
    })
    if (liff.isLoggedIn()) {
  
      let getProfile = await liff.getProfile()
      let email_line = await liff.getDecodedIDToken().email
      let line_data =  await handleLine(getProfile, email_line)
     
      getLine(line_data);
      console.log('check Authen line login success')
      return getData(getProfile,email_line);
      
    }else{
      liff.login({redirectUri: config.uri.web})
    }
  }
  const handleLine = async (line,email_line) =>{
    let data = []
    line.email = email_line
    if (line !== undefined || line === '') { 
      data.push({line: line}) 
    }
    return data
  }
  const NavigatorNext = async  (data) => {
      try {
        const jsonValue = JSON.stringify(data[0].role)
        const department =  data[0].scs_department_id
        const permission = data[0].is_admin
        const id_owner = data[0].id
        await AsyncStorage.setItem('role_menu', jsonValue)
        await AsyncStorage.setItem('department_id',department)
        await AsyncStorage.setItem('permission',permission)
        await AsyncStorage.setItem('id_owner',id_owner)
        return navigation.navigate('home',{screen: 'home',params:{data: data}})
      } catch (e) {
        console.log(e.message)
        
      }
    
    
  }
  if (loading){
    return (
      <SafeAreaView style={styles.container}>
        <Text>กำลังตรวจสอบ Login </Text>
        <ActivityIndicator size={'large'} color={'green'} />
     </SafeAreaView>
    )
  }
  if (status === 'error'){
    return (
      <SafeAreaView style={styles.container}>
      <Text>{error}</Text>
      </SafeAreaView>
    )
  }
  if (Data.length <= 0){
    return (
      <SafeAreaView >
           <CreateStaff line={Line} {...props} />
      </SafeAreaView>
    ) 
  }
  return null
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header :{
    height: '40%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',

  },

});
